<!-- eslint-disable no-unused-vars -->
/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div v-if="isLoadingFetching === true">
    <h1>LOADING</h1>
  </div>

  <div v-else>
    <!-- MODALS FREE MOBILE -->
    <div
      class="modal modal-content-creator fade"
      id="modalFree"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered .modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Free</h5>
            <button
              type="button"
              class="btn-close"
              @click="close_modal_free()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="main-body-free">
              <div class="box-img-card">
                <img src="../assets/Mobile/PricingFree.svg" alt="" />
              </div>
              <div class="list-card">
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Unlimited 3D
                  Assets
                </p>
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Basic Interaction
                </p>
                <p><img src="../assets/checklist.svg" alt="" /> Scene Max 3</p>
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Max 2 Classroom
                  Active
                </p>
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Limited
                  Educational Content
                </p>
                <div
                  class="btn-free"
                  @click="close_modal_free()"
                  data-bs-toggle="modal"
                  data-bs-target="#modalContentCreator"
                >
                  Try For Free
                </div>
              </div>
              <div class="card-title">
                <p class="top">Starter</p>
                <p class="bottom">Free</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal-backdrop fade show"
      id="backdrop"
      style="display: none"
    ></div>
    <!-- MODALS FREE MOBILE -->
    <!-- MODALS PAID MOBILE -->
    <div
      class="modal modal-content-creator fade"
      id="modalPaid"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered .modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Individual License
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="close_modal_paid()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="main-body-free">
              <div class="box-img-card">
                <img src="../assets/Mobile/PricingPremium.svg" alt="" />
              </div>
              <div class="list-card">
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Unlimited 3D
                  Assets
                </p>
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Basic Interaction
                </p>
                <p><img src="../assets/checklist.svg" alt="" /> Scene Max 3</p>
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Max 2 Classroom
                  Active
                </p>
                <p>
                  <img src="../assets/checklist.svg" alt="" /> Limited
                  Educational Content
                </p>
                <div
                  class="btn-free"
                  @click="close_modal_paid()"
                  data-bs-toggle="modal"
                  data-bs-target="#modalPremiumLicense"
                >
                  Purchase
                </div>
              </div>
              <div class="card-title">
                <p class="top">Individual License</p>
                <p class="bottom">IDR 59.999</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal-backdrop fade show"
      id="backdrop"
      style="display: none"
    ></div>
    <!-- MODALS PAID MOBILE -->
    <!-- MODALS CONTENT CREATOR -->
    <div
      class="modal modal-content-creator fade"
      id="modalContentCreator"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered .modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Download Center</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- <Datepicker v-model="date"  /> -->
            <div class="main-body-content-creator">
              <div class="viewer-card">
                <div class="title-card">
                  <p>MilleaLab</p>
                  <p>Creator</p>
                </div>
                <div class="box-img-card">
                  <div
                    id="carouselCreator"
                    class="carousel slide carousel-creator"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselCreator"
                        v-for="(items, id) in allArrayCreator"
                        :key="id"
                        :data-bs-slide-to="id"
                        :class="id === 0 ? 'active' : ''"
                        aria-current="true"
                        :aria-label="`slide ${id}`"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div
                        class="carousel-item"
                        v-for="(items, id) in allArrayCreator"
                        :key="id"
                        :class="id === 0 ? 'active' : ''"
                      >
                        <img
                          :src="`${items.img}`"
                          class="d-block w-100"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselCreator"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselCreator"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <div class="list-card">
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Processor: setara intel i3/i5/i7 atau lebih
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Sistem Operasi: Windows 8/10 - 64 bit
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    RAM: minimal 2GB atau lebih
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Ruang Penyimpanan: minimal 2GB sampai 5GB
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Kartu Grafis: internal atau External Graphic Card
                  </p>
                </div>
                <div class="box-btn-download">
                  <a
                    href="https://bit.ly/millealabinstaller_1_3_6_4"
                    target="_blank"
                    class="btn-download"
                  >
                    DOWNLOAD
                  </a>
                </div>
              </div>
              <div class="viewer-card">
                <div class="title-card">
                  <p>MilleaLab</p>
                  <p>Viewer</p>
                </div>
                <div class="box-img-card">
                  <div
                    id="carouselViewer"
                    class="carousel slide carousel-creator"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselViewer"
                        v-for="(items, id) in allArrayViewer"
                        :key="id"
                        :data-bs-slide-to="id"
                        :class="id === 0 ? 'active' : ''"
                        aria-current="true"
                        :aria-label="`slide ${id}`"
                      ></button>
                    </div>
                    <div class="carousel-inner">
                      <div
                        class="carousel-item"
                        v-for="(items, id) in allArrayViewer"
                        :key="id"
                        :class="id === 0 ? 'active' : ''"
                      >
                        <img
                          :src="`${items.img}`"
                          class="d-block w-100"
                          alt="..."
                        />
                      </div>
                    </div>
                    <button
                      class="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselViewer"
                      data-bs-slide="prev"
                    >
                      <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                      class="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselViewer"
                      data-bs-slide="next"
                    >
                      <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
                <div class="list-card">
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Processor: setara Hexa-Core 4x 1.4 GHz atau lebih
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Sistem Operasi: minimal Android Lollipop
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    RAM: minimal 2GB atau lebih
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Ruang Penyimpanan: minimal 500mb atau lebih
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Kartu Grafis:minimal Adreno 510 atau lebih
                  </p>
                  <p>
                    <img src="../assets/checklist.svg" alt="" />
                    Sensor: gyroscope atau accelerometer
                  </p>
                </div>
                <div class="box-btn-download">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.shintavr.mindvoke"
                    target="_blank"
                    class="btn-download"
                  >
                    DOWNLOAD
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
              </div> -->
        </div>
      </div>
    </div>
    <!-- MODALS CONTENT CREATOR -->

    <!-- MODALS PREMIUM LICENSE -->
    <div
      class="modal modal-content-creator fade"
      id="modalPremiumLicense"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      b b
      <div class="modal-dialog modal-dialog-centered .modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title title-premium" id="exampleModalLabel">
              Individual License
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- <Datepicker v-model="date"  /> -->
            <div class="main-body-premium-license">
              <div class="status-license">
                <div class="license-top">
                  <div
                    class="box-bulat"
                    :class="{ 'success-active': stepLicense === 1 }"
                  >
                    1
                  </div>
                  <div class="box-garis"></div>
                  <div
                    class="box-bulat"
                    :class="{ 'success-active': stepLicense === 2 }"
                  >
                    2
                  </div>
                  <div class="box-garis"></div>
                  <div
                    class="box-bulat"
                    :class="{ 'success-active': stepLicense === 3 }"
                  >
                    3
                  </div>
                </div>
                <div class="license-bot">
                  <div class="box-bulat">
                    <p>Profile</p>
                  </div>
                  <div class="box-garis"></div>
                  <div class="box-bulat">
                    <p>Detail Purchase</p>
                  </div>
                  <div class="box-garis"></div>
                  <div class="box-bulat">
                    <p>Billing</p>
                  </div>
                </div>
              </div>
              <div class="main-status-license" v-if="stepLicense === 1">
                <div class="header-main-title">
                  <p>Profile</p>
                </div>
                <div class="header-main-input">
                  <div class="box-top-input">
                    <div class="box-input-full">
                      <p>Full Name</p>
                      <input
                        type="text"
                        v-model="customerName"
                        @change="onFirstName"
                        autofocus
                      />
                    </div>
                    <!-- <div class="box-input" >
                                    <p>Last Name</p>
                                    <input type="text"  v-model="lastName" @change="onLastName" autofocus>
                                </div> -->
                    <div class="box-input">
                      <p>Email</p>
                      <input
                        type="email"
                        v-model="email"
                        @change="onEmail"
                        autofocus
                      />
                    </div>
                  </div>
                  <div class="box-top-input">
                    <div class="box-input">
                      <p>Phone Number</p>
                      <div class="input-card">
                        <div class="number-country">
                          <p>+62</p>
                        </div>
                        <input
                          type="text"
                          v-model="phoneNumber"
                          @change="onPhoneNumber"
                          autofocus
                        />
                      </div>
                    </div>
                    <div class="box-input">
                      <p>Referral Code</p>
                      <div class="input-card-right">
                        <input
                          type="text"
                          v-model="referralCode"
                          @change="onReferralCode"
                          @paste="onPaste"
                          autofocus
                        />
                        <div class="number-country" @click="pasteFunc">
                          <p>Paste</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="box-top-input">
                            </div> -->
                  <div class="box-btn-next">
                    <div class="btn-next" @click="nextStepLicense(2)">Next</div>
                  </div>
                </div>
              </div>
              <div class="main-status-license" v-if="stepLicense === 2">
                <div class="header-main-title">
                  <p>Detail Purchase</p>
                </div>
                <div class="header-main-input-detail">
                  <div class="box-detail-left">
                    <div class="detail-left-card">
                      <div class="small-detail-left">
                        <div class="detail-purchase-card">
                          <p>Name</p>
                          <p>{{ customerName }}</p>
                        </div>
                        <div class="detail-purchase-card">
                          <p>Phone Number</p>
                          <p>{{ phoneNumber }}</p>
                        </div>
                      </div>
                      <div class="small-detail-right">
                        <div class="detail-purchase-card">
                          <p>Email</p>
                          <p>{{ email }}</p>
                        </div>
                        <div class="detail-purchase-card">
                          <p>Referral Code</p>
                          <p>{{ referralCode }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box-detail-right">
                    <div class="individual-input-2">
                      <div class="individual-input-2-sm">
                        <p>Individual License</p>
                        <div class="box-for-plus-minus">
                          <div class="card-plus-minus">
                            <div
                              class="box-minus"
                              @click="kurangQty('License')"
                            >
                              <img src="../assets/minus.svg" alt="" />
                            </div>
                            <input
                              type="number"
                              class="box-result"
                              v-model="totalQtyProductLicense"
                              @change="onQtyLicense()"
                            />
                            <div
                              class="box-minus"
                              @click="tambahQty('License')"
                            >
                              <img src="../assets/plus.svg" alt="" />
                            </div>
                          </div>
                          <p>/ Month</p>
                        </div>
                      </div>
                      <p id="price_license">
                        IDR {{ totalHargaProductLicense }}
                      </p>
                    </div>
                    <div class="individual-input-2">
                      <div
                        class="individual-input-2-sm"
                        v-if="useReward.length > 0"
                      >
                        <p>Reward</p>
                        <div class="box-input-reward">
                          <select
                            name="gender"
                            id="gender"
                            v-model="useReward"
                            @change="onChooseReward()"
                          >
                            <option selected value="">Your Reward</option>
                            <template
                              v-for="(items, id) in userReward.value"
                              :key="id"
                            >
                              <option
                                :value="items.name"
                                v-if="items.quota > 0"
                              >
                                {{ items.name }}
                              </option>
                            </template>
                          </select>
                        </div>
                      </div>
                      <p id="price_license">{{ rewardDetail }}</p>
                    </div>
                    <div class="card-tax">
                      <p>Tax</p>
                      <p>IDR {{ total_tax }}</p>
                    </div>
                    <div class="total-price-detail">
                      <p>Total</p>
                      <p>IDR {{ totalFinalHarga }}</p>
                    </div>

                    <div class="box-btn-next">
                      <div class="btn-cancel" @click="backStepLicense(1)">
                        Back
                      </div>
                      <div
                        class="btn-next"
                        @click="nextStepLicense()"
                        data-bs-dismiss="modal"
                      >
                        Next
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="main-status-license" v-if="stepLicense === 3">
                <div class="header-main-billing">
                  <div class="billing-top">
                    <img src="../assets/logo.svg" alt="" />
                    <div class="box-timer">
                      <Countdown
                        showLabels="false"
                        :showDays="false"
                        :showHours="false"
                        :deadline="countdown1minute"
                        class="countdowntime"
                        mainFlipBackgroundColor="#004F98"
                        secondFlipBackgroundColor="#004F98"
                        mainColor="white"
                        secondFlipColor="#ffffff"
                      />
                    </div>
                  </div>
                  <div class="billing-mid">
                    <div class="bill-mid-left">
                      <div class="payment-method-left">
                        <p>Payment Method</p>
                        <div class="box-list-card-payment">
                          <Carousel
                            :settings="settings"
                            :breakpoints="breakpoints"
                          >
                            <Slide v-for="slide in 10" :key="slide">
                              <!-- <div class="carousel__item">{{ slide }}</div> -->
                              <div class="card-payment">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexRadioDefault1"
                                  >
                                    <img src="../assets/Alfamart.svg" alt="" />
                                  </label>
                                </div>
                              </div>
                            </Slide>
                            <template #addons>
                              <Navigation />
                            </template>
                          </Carousel>
                        </div>
                      </div>
                      <div class="payment-information-left">
                        <p>Payment Information</p>
                        <div class="box-input">
                          <p>Name on Card</p>
                          <input type="text" />
                        </div>
                        <div class="box-input">
                          <p>Referral Code</p>
                          <div class="input-card-right">
                            <input type="text" />
                            <div class="number-country">
                              <img src="../assets/visa.svg" alt="" />
                              <img src="../assets/mastercard.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div class="box-cvv">
                          <div class="box-input">
                            <p>CVV Number</p>
                            <input type="text" />
                          </div>
                          <div class="box-input">
                            <p>Expired Date</p>
                            <input type="text" />
                          </div>
                        </div>

                        <!-- <div class="box-input">
                                              <p>Email</p>
                                              <input type="text">
                                          </div> -->
                      </div>
                    </div>
                    <div class="bill-mid-right">
                      <div class="card-your-order">
                        <p>Your Order</p>
                        <div class="card-list-detail">
                          <div class="list-detail-item">
                            <div class="detail-item-left">
                              <p>Individual License</p>
                              <p>Tara Ingin makan pecel lele</p>
                            </div>
                            <div class="detail-item-right">
                              <p>X 1</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="total-price-order">
                        <p>Total</p>
                        <p>$ 4999</p>
                      </div>
                    </div>
                  </div>
                  <div class="billing-bot">
                    <div class="box-btn-next">
                      <div class="btn-next" @click="nextStepLicense(2)">
                        PROCEED TO PAYMENT
                      </div>
                      <div class="btn-cancel" @click="nextStepLicense(2)">
                        <img src="../assets/arrowleft.svg" alt="" />
                        <p>Return to Detail Purchase</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODALS PREMIUM LICENSE -->

    <!-- MODALS ADD A REVIEW -->
    <div
      class="modal modal-content-review fade"
      id="modalAddReview"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered .modal-lg">
        <div class="modal-content modal-content-review">
          <div class="new-body-review">
            <div class="modal-header">
              <div class="box-user-img">
                <div class="card-img-review">
                  <img src="../assets/Header/testing_pp.jpeg" alt="" />
                </div>
              </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="main-body-write-review">
                <div class="card-name-input-write">
                  <p>Name</p>
                  <input type="text" v-model="customerName" disabled />
                </div>
                <div class="card-name-input-write">
                  <p>Review <span>(max. 100 characters)</span></p>
                  <textarea
                    type="text"
                    class="area-review"
                    maxlength="100"
                    v-model="newReview"
                    @change="onAddReview"
                    autofocus
                  >
                  </textarea>
                </div>
                <div class="box-btn-review">
                  <div
                    class="btn-review"
                    @click="onSimpanReview"
                    data-bs-dismiss="modal"
                  >
                    SEND
                  </div>
                </div>

                <!-- <input type="text" >

                      <div class="btn-simpan-review" > 
                        simpan
                      </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODALS ADD A REVIEW -->

    <div class="container-home">
      <SideHeader />
      <div class="container-right">
        <div class="main-container-right">
          <TopHeaderLogin />

          <div class="hello-box" v-if="isLoginGlobal === true">
            <h1>Hello, {{ customerName }}</h1>
            <!-- <p>Hello World , world Hello, dlrow olleh, h e l l o w o r l d !</p> -->
          </div>
          <div class="hello-box" v-if="isLoginGlobal === false">
            <h1>Welcome</h1>
            <!-- <p>Hello World , world Hello, dlrow olleh, h e l l o w o r l d !</p> -->
          </div>
          <div class="slider-box">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <!-- <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div> -->
              <div class="carousel-inner">
                <!-- <a :href="`/news/${dataNews.params}`" class="carousel-item" :class="id === 0 ? 'active':''" v-for="(dataNews,id) in newsContent" :key="dataNews.id" >
                  <img :src="`${dataNews.img}`" class="d-block w-100" alt="...">
                </a> -->
                <a
                  class="carousel-item position-relative"
                  :class="id === 0 ? 'active' : ''"
                  v-for="(dataNews, id) in newsContent"
                  :key="dataNews.id"
                >
                  <img
                    :src="`${dataNews.img}`"
                    class="d-block w-100"
                    alt="..."
                  />
                  <a class="right-btn" :href="dataNews.link" target="_blank">
                    <strong>Lihat Guideline</strong>
                  </a>
                </a>
              </div>
              <!-- <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button> -->
            </div>
          </div>
          <div class="mid-main-box">
            <div class="main-pricing">
              <div class="pricing-title-box">
                <p>Pricing</p>
              </div>
              <div class="card-pricing-box">
                <div class="card-pricing-free">
                  <div class="starter-pack">
                    <p>Starter</p>
                    <p>FREE</p>
                  </div>
                  <div class="list-function">
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Unlimited
                      3D Assets
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Basic
                      Interaction
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Scene Max
                      3
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Max 2
                      Classroom Active
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Limited
                      Educational Content
                    </p>
                  </div>
                  <div class="btn-purchase-box">
                    <button
                      type="button"
                      class="btn btn-purchase"
                      data-bs-toggle="modal"
                      data-bs-target="#modalContentCreator"
                    >
                      <p>Let's try</p>
                    </button>
                  </div>
                </div>
                <div
                  class="card-pricing-free-mobile"
                  @click="open_modal_free()"
                >
                  <div class="starter-pack">
                    <p>Starter</p>
                    <p>FREE</p>
                  </div>
                  <div class="list-function">
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Unlimited
                      3D Assets
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Basic
                      Interaction
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Scene Max
                      3
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Max 2
                      Classroom Active
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Limited
                      Educational Content
                    </p>
                  </div>
                  <div class="btn-purchase-box">
                    <button type="button" class="btn btn-purchase">
                      <p>Lets try</p>
                    </button>
                  </div>
                </div>
                <div class="card-pricing-premium">
                  <div class="starter-pack">
                    <p>Individual License</p>
                    <p><span>IDR</span> 59.999</p>
                  </div>
                  <div class="list-function">
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Unlimited
                      3D Asset
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Premium
                      Interaction
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Scene Max
                      25
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Max 2
                      Classroom Active
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Data
                      Dashboard Analytics
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Email
                      Support
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> No
                      Request Assets
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> No
                      Customize Template VR
                    </p>
                  </div>
                  <div class="btn-purchase-box">
                    <button
                      type="button"
                      class="btn btn-purchase"
                      @click="onPurchaseLicense()"
                      data-bs-toggle="modal"
                      data-bs-target="#modalPremiumLicense"
                      v-if="isLoginGlobal === true"
                    >
                      <p>Purchase</p>
                    </button>
                    <button
                      type="button"
                      class="btn btn-purchase"
                      @click="errorNotLogin"
                      v-if="isLoginGlobal === false"
                    >
                      <p>Purchase</p>
                    </button>
                  </div>
                </div>
                <div
                  class="card-pricing-premium-mobile"
                  @click="open_modal_paid()"
                  v-if="isLoginGlobal === true"
                >
                  <div class="starter-pack">
                    <p>Individual License</p>
                    <p><span>IDR</span> 59.999</p>
                  </div>
                  <div class="list-function">
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Unlimited
                      3D Asset
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Premium
                      Interaction
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Scene Max
                      25
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Max 2
                      Classroom Active
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Data
                      Dashboard Analytics
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Email
                      Support
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> No
                      Request Assets
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> No
                      Customize Template VR
                    </p>
                  </div>
                </div>
                <div
                  class="card-pricing-premium-mobile"
                  @click="errorNotLogin"
                  v-if="isLoginGlobal === false"
                >
                  <div class="starter-pack">
                    <p>Individual License</p>
                    <p><span>IDR</span> 59.999</p>
                  </div>
                  <div class="list-function">
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Unlimited
                      3D Asset
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Premium
                      Interaction
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Scene Max
                      25
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Max 2
                      Classroom Active
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Data
                      Dashboard Analytics
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> Email
                      Support
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> No
                      Request Assets
                    </p>
                    <p>
                      <img src="../assets/chevron-right.svg" alt="" /> No
                      Customize Template VR
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="main-review">
              <div class="review-title">
                <p>Reviews</p>
                <!-- <button type="button" class="btn  btn-purchase btn-write-review" v-if="isLoginGlobal === true" data-bs-toggle="modal" data-bs-target="#modalAddReview">
                          <p>WRITE A REVIEW</p>
                      </button>
                      <button type="button" class="btn  btn-purchase btn-write-review" v-if="isLoginGlobal === false" @click="errorNotLogin" >
                          <p>WRITE A REVIEW</p>
                      </button> -->
              </div>
              <div class="slider-review-box">
                <div
                  class="card-review"
                  v-for="items in allArrayReviews"
                  :key="items.id"
                >
                  <div class="review-name">
                    <div class="card-profile">
                      <div class="profile-review">
                        <!-- <img :src="`${items.img}`" alt=""> -->
                        <img
                          :src="`${items.profile_picture}`"
                          alt=""
                          v-if="items.profile_picture"
                        />
                        <img src="../assets/dp-otis-1.png" alt="" v-else />
                      </div>
                      <p>{{ items.name.split(" ")[0] }}</p>
                    </div>
                    <div class="updating-time-review">
                      <!-- <p>{{items.lastUpdate}}</p> -->
                    </div>
                  </div>
                  <div class="comment-review">
                    <p>{{ items.review }}</p>

                    <!-- <read-more more-str="more" :text="`${items.review}`" link="#"  :max-chars="30" class="read-more-review"></read-more>  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main-faq">
            <div class="faq-title-box">
              <p>FAQ</p>
            </div>
            <div class="box-header-faq">
              <FaqButton
                :buttonStatus="statusFaq"
                @changeDirection="changeDirectionFaq"
              />
            </div>
            <div class="accordion accordion-faq" id="accordionExample">
              <div
                class="accordion-item"
                v-for="(items, id) in allArrayFaq"
                :key="id"
              >
                <h2
                  class="accordion-header"
                  :id="`heading${id + 1}`"
                  v-if="items.status === statusFaq"
                >
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#collapse${id + 1}`"
                    aria-expanded="false"
                    :aria-controls="`collapse${id + 1}`"
                  >
                    <p>{{ items.id }}</p>
                    <p>{{ items.question }}</p>
                  </button>
                </h2>
                <div
                  :id="`collapse${id + 1}`"
                  class="accordion-collapse collapse"
                  v-if="items.status === statusFaq"
                  :aria-labelledby="`heading${id + 1}`"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    {{ items.answer }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideHeader from "@/components/Header/SideHeader.vue";
import TopHeaderLogin from "@/components/Header/TopHeaderLogin.vue";
import FaqButton from "@/components/Button/FaqButton.vue";
// eslint-disable-next-line no-unused-vars
import { ref, reactive, onMounted, watch } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { Countdown } from "vue3-flip-countdown";
import { createToast, clearToasts } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import Axios from "axios";
import { useStore } from "vuex";
import { useLoading } from "vue-loading-overlay";

// eslint-disable-next-line no-unused-vars

// import {doc,getDoc} from 'firebase/firestore'
// import {db} from '../../firebase-config'
export default {
  name: "HomeView",
  components: {
    SideHeader,
    TopHeaderLogin,
    Carousel,
    Navigation,
    Slide,
    Countdown,
    FaqButton,

    // VStepper
  },
  setup() {
    const $loading = useLoading();
    const fullPage = ref(true);
    const store = useStore();

    const onLoadingFunc = () => {
      const loader = $loading.show({
        // Optional parameters
      });
      // simulate AJAX
      setTimeout(() => {
        loader.hide();
      }, 5000);
    };
    const onCancel = () => console.log("User cancelled the loader.");

    let customerName = ref("");
    let isLoadingFetching = ref(true);

    let customerUid = ref("");
    let firstName = ref("");
    let lastName = ref("");
    let email = ref("");
    let phoneNumber = ref("");
    let referralCode = ref("");
    let profilePicture = ref("");
    let newReview = ref("");
    let nameReview = ref("Bayu Darmawan");
    let isLoading = ref(false);

    let isEmailCorrect = ref(false);
    let isFirstNameCorrect = ref(false);
    let isPhoneNumberCorrect = ref(false);
    let useReward = ref([]);
    // console.log(useReward.value, ' ini use Reward')
    let rewardDetail = ref("");

    let totalQtyProductLicense = ref(1);
    let totalHargaProductLicense = ref(59999);
    let total_tax = ref(
      Math.round(
        totalHargaProductLicense.value * totalQtyProductLicense.value * 0.11
      )
    );

    let hargaProductSatuanLicense = ref(59999);

    let totalQtyProductCardboard = ref(0);
    let totalHargaProductCardboard = ref(4999);
    let hargaProductSatuanCardboard = ref(4999);

    let totalFinalHarga = ref(
      Math.round(totalHargaProductLicense.value + total_tax.value)
    );
    let userReward = [];

    // gambar untuk creator dan viewer
    let allArrayViewer = reactive([
      {
        img: [require("../assets/MilleaLabViewer/download-viewer-1.png.webp")],
      },
      {
        img: [require("../assets/MilleaLabViewer/download-viewer-2.jpg")],
      },
      {
        img: [require("../assets/MilleaLabViewer/download-viewer-3.jpg.webp")],
      },
      {
        img: [require("../assets/MilleaLabViewer/download-viewer-4.jpg.webp")],
      },
      {
        img: [require("../assets/MilleaLabViewer/download-viewer-5.jpg.webp")],
      },
    ]);
    let allArrayCreator = reactive([
      {
        img: [
          require("../assets/MilleaLabCreator/download-creator-1.png.webp"),
        ],
      },
      {
        img: [
          require("../assets/MilleaLabCreator/download-creator-2.png.webp"),
        ],
      },
      {
        img: [require("../assets/MilleaLabCreator/download-creator-3.png")],
      },
      {
        img: [
          require("../assets/MilleaLabCreator/download-creator-4.png.webp"),
        ],
      },
      {
        img: [
          require("../assets/MilleaLabCreator/download-creator-5.png.webp"),
        ],
      },
    ]);
    // gambar untuk creator dan viewer

    let allArrayReviews = ref([]);
    let newsContent = ref([
      {
        id: 1,
        params: "upgradeBahagiamu",
        title:
          "Pembelajaran Blended Learning dengan Media Virtual Reality (VR)",
        content:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore id laboriosam quae possimus libero praesentium dolores dolorem quo neque recusandae quidem nihil maxime illo odio, deserunt minus iusto distinctio nulla. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod quibusdam, inventore quos debitis excepturi facere. Fugiat labore culpa a facilis qui aliquid et, quam, possimus itaque minima ullam tenetur aperiam!",
        img: [require("../assets/slider-home/bg-slide-4.png")],
        link: "https://drive.google.com/file/d/1oOvlYRW-HoN0-JCXAUANBHdXl6YxqD--/view",
      },
      //   {
      //     id:2,
      //     params:'upgradeBahagiaku',
      //     title:'Pembelajaran Blended Learning dengan Media Virtual Reality (VR)',
      //     content:'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore id laboriosam quae possimus libero praesentium dolores dolorem quo neque recusandae quidem nihil maxime illo odio, deserunt minus iusto distinctio nulla. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod quibusdam, inventore quos debitis excepturi facere. Fugiat labore culpa a facilis qui aliquid et, quam, possimus itaque minima ullam tenetur aperiam!',
      //     img:[require('../assets/slider-home/bg-slide-2.png')]
      // },
      //  {
      //     id:3,
      //     params:'upgradeBahagiakita',
      //     title:'Pembelajaran Blended Learning dengan Media Virtual Reality (VR)',
      //     content:'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore id laboriosam quae possimus libero praesentium dolores dolorem quo neque recusandae quidem nihil maxime illo odio, deserunt minus iusto distinctio nulla. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quod quibusdam, inventore quos debitis excepturi facere. Fugiat labore culpa a facilis qui aliquid et, quam, possimus itaque minima ullam tenetur aperiam!',
      //     img:[require('../assets/slider-home/bg-slide-3.png')]
      // }
    ]);

    let allArrayFaq = ref([]);

    let stepLicense = ref(1);
    let settings = ref({
      itemsToShow: 1,
      snapAlign: "center",
    });
    let statusFaq = ref("General");
    let msg = ref(
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam."
    );
    let breakpoints = ref({
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 5,
        snapAlign: "start",
      },
    });

    const onAddReview = () => {
      // console.log(newReview.value)
      if (newReview.value.length < 5) {
        createToast(
          {
            title: "Error",
            description: "Minimal 5 Characters",
          },
          {
            // timeout: 3000,
            type: "danger",
            transition: "bounce",
            position: "top-center",
          }
        );
      } else {
        // console.log('aman')
      }
    };

    const onPaste = (evt) => {
      console.log(evt);
      // console.log(evt.clipboardData.getData('text/plain'))
    };

    const pasteFunc = async () => {
      let testing = await navigator.clipboard.readText();
      // console.log(testing,' ini testing')
      referralCode.value = testing;
    };

    const onFirstName = () => {
      // console.log(firstName.value)
      if (firstName.value.length > 2) {
        isFirstNameCorrect.value = true;
      } else {
        isFirstNameCorrect.value = false;
        createToast(
          {
            title: "Error",
            description: "Nama Minimal 3 Character",
          },
          {
            // timeout: 3000,
            type: "danger",
            transition: "bounce",
            position: "top-center",
          }
        );
      }
    };
    const onLastName = () => {
      // console.log(lastName.value)
    };
    const onEmail = () => {
      // console.log(email.value)
      var checking_email = validateEmail(email.value);
      if (checking_email) {
        // console.log('correct email')
        isEmailCorrect.value = true;
      } else {
        isEmailCorrect.value = false;
        createToast(
          {
            title: "Error",
            description: "ada kesalahan pada Format Email",
          },
          {
            // timeout: 3000,
            type: "danger",
            transition: "bounce",
            position: "top-center",
          }
        );
      }
    };

    const onPurchaseLicense = () => {
      if (phoneNumber.value !== "") {
        onPhoneNumber();
      }
    };

    const onPhoneNumber = () => {
      // console.log(phoneNumber.value)
      let phoneNumbereInString = phoneNumber.value.toString();
      if (phoneNumbereInString.length > 9 && phoneNumbereInString.length < 15) {
        // console.log('number correct')
        isPhoneNumberCorrect.value = true;
        let split_number = phoneNumbereInString.split("");
        let number_62 = split_number[0] + split_number[1] + split_number[2];
        let number_plus62 = split_number[0] + split_number[1] + split_number[2];
        let number_628 = split_number[0] + split_number[1] + split_number[2];
        let number_0 = split_number[0];
        if (number_plus62 === "+62") {
          split_number.splice(0, 3);
          phoneNumber.value = split_number.join("");
        } else if (number_62 === "62") {
          split_number.splice(0, 2);
          phoneNumber.value = split_number.join("");
        } else if (number_628 === "628") {
          split_number.splioce(0, 2);
          phoneNumber.value = split_number.join("");
        } else if (split_number[0] === "") {
          split_number.splice(0, 1);
          phoneNumber.value = split_number.join("");
        } else if (number_0 === "0") {
          split_number.splice(0, 1);
          phoneNumber.value = split_number.join("");
        } else {
          // console.log('masuk ke else')
        }
      } else if (phoneNumbereInString === "") {
        isPhoneNumberCorrect.value = false;
      } else {
        createToast(
          {
            title: "Error",
            description: "phone Number minimal 10 digit",
          },
          {
            // timeout: 3000,
            type: "danger",
            transition: "bounce",
            position: "top-center",
          }
        );
        isPhoneNumberCorrect.value = false;
      }
    };

    const onReferralCode = () => {
      // console.log(referralCode.value)
      // referralCode.value =
    };

    const tambahQty = (status) => {
      if (status === "License") {
        totalQtyProductLicense.value += 1;
        totalHargaProductLicense.value =
          totalQtyProductLicense.value * hargaProductSatuanLicense.value;
        let find_tax = totalHargaProductLicense.value * 0.11;
        // console.log(total_tax)
        total_tax.value = Math.round(find_tax);
        totalFinalHarga.value = Math.round(
          totalHargaProductLicense.value + find_tax
        );
      } else if (status === "Cardboard") {
        totalQtyProductCardboard.value += 1;
        totalHargaProductCardboard.value =
          totalQtyProductCardboard.value * hargaProductSatuanCardboard.value;
        totalFinalHarga.value =
          totalHargaProductLicense.value + totalHargaProductCardboard.value;
      }
    };

    const kurangQty = (status) => {
      if (status === "License") {
        if (totalQtyProductLicense.value > 1) {
          totalQtyProductLicense.value -= 1;
          totalHargaProductLicense.value =
            totalQtyProductLicense.value * hargaProductSatuanLicense.value;
          let find_tax = totalHargaProductLicense.value * 0.11;
          total_tax.value = Math.round(find_tax);
          totalFinalHarga.value = Math.round(
            totalHargaProductLicense.value + find_tax
          );

          // totalFinalHarga.value = totalHargaProductLicense.value + totalHargaProductCardboard.value
        }
      } else if (status === "Cardboard") {
        if (totalQtyProductCardboard.value > 0) {
          totalQtyProductCardboard.value -= 1;
          totalHargaProductCardboard.value =
            totalQtyProductCardboard.value * hargaProductSatuanCardboard.value;
          totalFinalHarga.value =
            totalHargaProductLicense.value + totalHargaProductCardboard.value;
        }
      }
    };

    const getTimeForCountdown = () => {
      var currentTime = new Date();
      var Time = currentTime.setTime(currentTime.getTime() + 1500 * 60);
      var datetesting = new Date(Time);
      // Hours part from the timestamp
      var hours = datetesting.getHours();
      // Minutes part from the timestamp
      var minutes = "0" + datetesting.getMinutes();
      // Seconds part from the timestamp
      var seconds = "0" + datetesting.getSeconds();
      // Will display time in 10:30:23 format
      var formattedTime =
        hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
      // console.log(formattedTime,' 26');

      var todayDate = new Date().toISOString().slice(0, 10);
      // console.log(todayDate,' 29');

      let finalResult = `${todayDate} ${formattedTime} `;

      return finalResult;
    };
    let countdown1minute = ref(getTimeForCountdown());
    const nextStepLicense = (page) => {
      // console.log(isEmailCorrect.value)
      // console.log(isFirstNameCorrect.value)

      if (stepLicense.value === 1) {
        if (
          isFirstNameCorrect.value &&
          isPhoneNumberCorrect.value &&
          isEmailCorrect.value
        ) {
          stepLicense.value = page;

          // console.log(isFirstNameCorrect,isPhoneNumberCorrect,isEmailCorrect)
        } else {
          createToast(
            {
              title: "Error",
              description: "Ada pengisian yang salah",
            },
            {
              // timeout: 3000,
              type: "danger",
              transition: "bounce",
              position: "top-center",
            }
          );
        }
      } else if (stepLicense.value === 2) {
        createToast(
          {
            title: "Mohon Menunggu",
            description: "dalam proses pembuatan invoice",
          },
          {
            timeout: -1,
            type: "info",
            transition: "bounce",
            position: "top-center",
          }
        );
        let reward_type = "";
        if (useReward.value === "Bronze") {
          reward_type = "reward-1";
        } else if (useReward.value === "Silver") {
          reward_type = "reward-2";
        } else if (useReward.value === "Gold") {
          reward_type = "reward-3";
        } else if (useReward.value === "Platinum") {
          reward_type = "reward-4";
        }

        var data = JSON.stringify({
          name: `${customerName.value} `,
          email: `${email.value}`,
          phone: `${phoneNumber.value}`,
          amount: totalFinalHarga.value,
          refcode: referralCode.value,
          items: {
            individual_license: totalQtyProductLicense.value,
          },
          reward_type: reward_type,
        });

        var config = {
          method: "post",
          url: "https://us-central1-millea-lab.cloudfunctions.net/web/api/createInvoice",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        console.log(data);
        Axios(config)
          .then(function (response) {
            // console.log(JSON.stringify(response.data));
            let allResponse = response.data;
            let linkPayment = allResponse.invoice_url;
            // console.log(linkPayment)
            // console.log(response.data)
            window.open(linkPayment, "_blank");
            stepLicense.value = 1;
            clearToasts();

            if (reward_type !== "") {
              Axios.post(
                `https://us-central1-millea-lab.cloudfunctions.net/web/api/useLoyaltyReward`,
                {
                  uid: customerUid.value,
                  reward_type: reward_type,
                  // eslint-disable-next-line no-unused-vars
                }
              )
                .then((res) => {
                  // console.log(reward_type)
                  // console.log(res.data)
                })
                .catch((err) => {
                  console.log(err``);
                });
            } else {
              // console.log('masuk ke else berarti dia gak pake reward')
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            // console.log(error);
            createToast(
              {
                title: "Error",
                description: "Silahkan Ulangi dalam beberapa saat",
              },
              {
                // timeout: 3000,
                type: "info",
                transition: "bounce",
                position: "top-center",
              }
            );
            clearToasts();
          });
      }
    };

    const backStepLicense = (page) => {
      stepLicense.value = page;
    };
    let isDisplayPayment = ref(
      document.getElementsByClassName("modal-content-creator")
    );

    function validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    function validatePassword(password) {
      const re = /^(?=.{7,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/;
      return re.test(String(password));
    }

    const onQtyLicense = () => {
      // console.log('jalan', totalQtyProductLicense.value)
      if (totalQtyProductLicense.value > 0) {
        totalHargaProductLicense.value =
          totalQtyProductLicense.value * hargaProductSatuanLicense.value;
        let total_tax = totalHargaProductLicense.value * 0.11;

        totalFinalHarga.value = Math.round(
          totalHargaProductLicense.value + total_tax
        );
        // totalFinalHarga.value = totalHargaProductLicense.value
      } else {
        totalQtyProductLicense.value = 1;
      }
    };

    const changeDirectionFaq = (response) => {
      // console.log(response.status)
      statusFaq.value = response.status;
      // console.log(statusFaq.value)
    };
    const onChooseReward = () => {
      if (useReward.value === "Bronze") {
        rewardDetail.value = "Discount 10%";
        let discount = totalHargaProductLicense.value * 0.1;
        let find_tax =
          Math.round(totalHargaProductLicense.value - discount) * 0.11;
        // console.log(discount)
        total_tax.value = Math.round(find_tax);
        totalFinalHarga.value = Math.round(
          totalHargaProductLicense.value - discount + find_tax
        );
      } else if (useReward.value === "Silver") {
        rewardDetail.value = "Discount 20%";
        let discount = totalHargaProductLicense.value * 0.2;
        // console.log(discount)
        let find_tax =
          Math.round(totalHargaProductLicense.value - discount) * 0.11;
        total_tax.value = Math.round(find_tax);
        totalFinalHarga.value = Math.round(
          totalHargaProductLicense.value + find_tax - discount
        );
      } else if (useReward.value === "Gold") {
        rewardDetail.value = "Discount 30%";
        let discount = totalHargaProductLicense.value * 0.3;
        // console.log(discount)
        let find_tax =
          Math.round(totalHargaProductLicense.value - discount) * 0.11;
        total_tax.value = Math.round(find_tax);
        totalFinalHarga.value = Math.round(
          totalHargaProductLicense.value + find_tax - discount
        );
      } else if (useReward.value === "Platinum") {
        rewardDetail.value = "Discount 50%";

        let discount = totalHargaProductLicense.value * 0.5;
        let price_after_discount = Math.round(
          totalHargaProductLicense.value - discount
        );
        let find_tax = Math.round(price_after_discount * 0.11);
        total_tax.value = Math.round(find_tax);
        totalFinalHarga.value = Math.round(
          totalHargaProductLicense.value + find_tax - discount
        );
      } else {
        rewardDetail.value = "";
        let find_tax = Math.round(totalHargaProductLicense.value * 0.11);
        total_tax.value = find_tax;
        totalFinalHarga.value = totalHargaProductLicense.value + find_tax;
      }
    };

    const errorNotLogin = () => {
      createToast(
        {
          title: "Error",
          description: "Login Terlebih Dahulu",
        },
        {
          // timeout: 3000,
          type: "danger",
          transition: "bounce",
          position: "top-center",
        }
      );
    };

    const open_modal_free = () => {
      document.getElementById("backdrop").style.display = "block";
      document.getElementById("modalFree").style.display = "block";
      document.getElementById("modalFree").className += "show";
    };
    const close_modal_free = () => {
      document.getElementById("backdrop").style.display = "none";
      document.getElementById("modalFree").style.display = "none";
      document.getElementById("modalFree").className += document
        .getElementById("modalFree")
        .className.replace("show", "");
    };
    const open_modal_paid = () => {
      document.getElementById("backdrop").style.display = "block";
      document.getElementById("modalPaid").style.display = "block";
      document.getElementById("modalPaid").className += "show";
    };
    const close_modal_paid = () => {
      document.getElementById("backdrop").style.display = "none";
      document.getElementById("modalPaid").style.display = "none";
      document.getElementById("modalPaid").className += document
        .getElementById("modalPaid")
        .className.replace("show", "");
    };

    watch(
      async () => store.getters.getAllReview,
      function () {
        allArrayReviews.value = store.getters.getAllReview;
      }
    );
    watch(
      async () => store.getters.getUserReward,
      function () {
        userReward.value = store.getters.getUserReward;
        // console.log(userReward.value)
      }
    );
    watch(
      async () => store.getters.getAllArrayFaq,
      function () {
        allArrayFaq.value = store.getters.getAllArrayFaq;
        // console.log(allArrayFaq.value)
      }
    );
    // testing vuex

    let isLoginGlobal = ref(false);

    const fetchingLogin = async () => {
      const loader = $loading.show({
        // Optional parameters
      });
      store.dispatch("getValueReview"); // ngejalanin function fetching value review
      store.dispatch("getValueReward"); // get all userReward
      isLoginGlobal.value = store.getters.getIsLogin;
      allArrayReviews.value = store.getters.getAllReview;
      isLoading.value = store.getters.getIsLoading;
      allArrayFaq.value = store.getters.getAllArrayFaq;
      // console.log(allArrayFaq.value)

      let dataCustomer = store.getters.getCustomerDetail;
      if (isLoginGlobal.value) {
        // customerName.value = store.getters.getCustomerName
        customerName.value = dataCustomer.name;
        firstName.value = dataCustomer.firstName;
        lastName.value = dataCustomer.lastName;
        email.value = dataCustomer.email;
        phoneNumber.value = dataCustomer.phone;
        // referralCode.value = dataCustomer.referral_code
        customerUid.value = dataCustomer.id;
        isEmailCorrect.value = true;
        isFirstNameCorrect.value = true;
        isPhoneNumberCorrect.value = true;
        isLoadingFetching.value = false;
        loader.hide();
      } else {
        isEmailCorrect.value = false;
        isFirstNameCorrect.value = false;
        isPhoneNumberCorrect.value = false;
        // console.log('masuk ke else')
        isLoadingFetching.value = false;
        loader.hide();
      }

      // console.log(customerName.value,'ini customer name')
      // console.log(isLoginGlobal.value,' value login')

      // DownloadFile('Sample.pdf')
      // await beforeDownload()
    };

    onMounted(() => {
      fetchingLogin();
    });
    const log = (params, id) => {
      // console.log('log jalan')
      console.log(params, id);
    };

    // testing vuex
    return {
      isLoadingFetching,
      stepLicense,
      nextStepLicense,
      settings,
      breakpoints,
      getTimeForCountdown,
      countdown1minute,
      isDisplayPayment,
      msg,
      statusFaq,
      changeDirectionFaq,
      allArrayFaq,
      tambahQty,
      kurangQty,
      totalQtyProductLicense,
      totalHargaProductLicense,
      hargaProductSatuanLicense,
      allArrayViewer,
      allArrayCreator,
      totalQtyProductCardboard,
      totalHargaProductCardboard,
      hargaProductSatuanCardboard,
      firstName,
      lastName,
      email,
      phoneNumber,
      referralCode,
      validateEmail,
      validatePassword,
      onFirstName,
      onLastName,
      onEmail,
      onReferralCode,
      onPhoneNumber,
      isEmailCorrect,
      isFirstNameCorrect,
      isPhoneNumberCorrect,
      totalFinalHarga,
      Axios,
      backStepLicense,
      onPaste,
      pasteFunc,
      newReview,
      onAddReview,
      isLoginGlobal,
      nameReview,
      newsContent,
      errorNotLogin,
      customerName,
      onCancel,
      fullPage,
      onLoadingFunc,
      profilePicture,
      allArrayReviews,
      isLoading,
      userReward,
      useReward,
      onQtyLicense,
      onChooseReward,
      rewardDetail,
      log,
      customerUid,
      onPurchaseLicense,
      total_tax,
      open_modal_free,
      close_modal_free,
      open_modal_paid,
      close_modal_paid,
      // onNameReview
    };
  },
};
</script>

<style lang="scss">
@import "../Styles/Home.scss";
@import "../Styles/Mobile/Home.scss";
</style>
