<template>
    <div class="container-home-referral">
        <SideHeader/>
        <div class="container-right-referral">
            <div class="main-container-right-referral">
                <TopHeaderLogin/>
                <div class="referral-header-box">
                    <h1>Referral Code</h1>
                    <div class="code-box">
                        <input class="code" id="myText" type="text" v-model="referralCode" disabled>
                        <div class="btn-copy" @click="handleClick()">COPY</div>
                    </div>
                    <!-- <div class="code-box">
                        <p>ABCDEFGHI1234</p>
                        <div class="btn-copy">COPY</div>
                    </div> -->
                </div>
                <div class="referral-table-box">
                    <div class="table-header">
                        <h1>Referral User</h1>
                    </div>
                    <div class="table-body">
                        <div class="table-details">
                            <table>
                                <tr class="except">
                                    <th class="no"><p>No</p></th>
                                    <th class="date"><p>Date</p></th>
                                    <th class="name"><p>Name</p></th>
                                    <th class="email"><p>Email</p></th>
                                </tr>
                                <tr v-for="(dataRef,id) in dataTableRefCode" :key="dataRef.id">
                                    <td>{{id+1}}</td>
                                    <td>{{dataRef.date}}</td>
                                    <td>{{dataRef.name}}</td>
                                    <td>{{dataRef.email}}</td>
                                </tr>
                            </table>
                            <!-- <div v-else class="history-unknown">
                                <img src="../assets/noHistory.png" alt="">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-header">
                        <h1>Kewajiban</h1>
                    </div>
                    <div class="info-table">
                        <p>1. Anda wajib follow instagram @millealab dan bergabung di group Facebook “Millealab VR Educator Indonesia”.</p>
                        <p>2. Anda wajib memberikan review di website Millealab mengenai kepuasan penggunaan Millealab.</p>
                        <p>3. Anda dapat mengajak orang lain untuk menggunakan kode referral Anda dan menginformasikan mengenai praktik baik penggunaan Millealab pada kegiatan belajar mengajar.</p>
                        <p>4. Anda dilarang untuk menggunakan spam atau cara curang lainnya untuk mempromosikan kode referral Anda.</p>
                        <p>5. Anda dilarang menampilkan/ memposisikan/ mengatasnamakan diri sebagai Millealab dalam promosi apapun.</p>
                        <p>6. Anda dilarang mempromosikan Millealab dengan informasi yang tidak relevan atau tidak disediakan oleh Millealab.</p>
                    </div>
                </div>
                <div class="info-box">
                    <div class="info-header">
                        <h1>Syarat dan Ketentuan</h1>
                    </div>
                    <div class="info-table">
                        <p>1. Kode referral hanya bisa didapatkan ketika membeli lisensi individu Millealab.</p>
                        <p>2. Kode referral hanya dapat digunakan 1 (satu) kali oleh pengguna baru.</p>
                        <p>3. Kode referral hanya dapat digunakan 1 (satu) kali oleh penerima kode.</p>
                        <p>4. Kode referral dapat digunakan penerima kode lainnya dan terakumulasi dalam bentuk point reward di setiap pembelian lisensi individu.</p>
                        <p>5. Point reward dari kode referral, baru dapat terhitung ketika pengguna baru sudah melakukan pembelian lisensi individu Millealab.</p>
                        <p>6. Syarat dan ketentuan dapat berubah sewaktu-waktu tanpa pemberitahuan.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideHeader from '@/components/Header/SideHeader.vue'
import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'
import {ref,onMounted} from 'vue'
import {useStore} from 'vuex'
import { createToast } from 'mosha-vue-toastify';
import Axios from 'axios'
export default {
  name: 'RefferalView',
  components: {
    SideHeader,
    TopHeaderLogin
  },
  setup(){

      const store = useStore()
      let referralCode = ref('')
      let dataTableRefCode = ref([
        //   {
        //       id:1,
        //       date:'3 Maret 2020',
        //       name:'Asep',
        //       email:'asep@gmail.com'
        //   },
        //   {
        //       id:2,
        //       date:'5 April 2020',
        //       name:'Budi',
        //       email:'budi@gmail.com'
        //   },
        //   {
        //       id:3,
        //       date:'7 Desember 2020',
        //       name:'Ca',
        //       email:'ca@gmail.com'
        //   }
        //   ,
        //   {
        //       id:4,
        //       date:'7 Desember 2020',
        //       name:'Ca',
        //       email:'ca@gmail.com'
        //   }
        //   ,
        //   {
        //       id:5,
        //       date:'7 Desember 2020',
        //       name:'Ca',
        //       email:'ca@gmail.com'
        //   }
        //   ,
        //   {
        //       id:6,
        //       date:'7 Desember 2020',
        //       name:'Ca',
        //       email:'ca@gmail.com'
        //   }
        //     ,
        //   {
        //       id:7,
        //       date:'7 Desember 2020',
        //       name:'Ca',
        //       email:'ca@gmail.com'
        //   }
        //     ,
        //   {
        //       id:8,
        //       date:'7 Desember 2020',
        //       name:'Ca',
        //       email:'ca@gmail.com'
        //   }
        //     ,
        //   {
        //       id:9,
        //       date:'7 Desember 2020',
        //       name:'Ca',
        //       email:'ca@gmail.com'
        //   }
      ])
      let dataCustomer = ref()
      const handleClick=()=>{
        var input = document.getElementById("myText").value;
        navigator.clipboard.writeText(input);
        createToast({
            title: 'Success',
            description: `${referralCode.value} copied `
            },
            {
            // timeout: 3000,
            type: 'success',
            transition: 'bounce',
            position:'top-center'
        })
      }

      const fetchingLogin=async()=>{
            let dataCustomerFromVuex = store.getters.getCustomerDetail
            dataCustomer.value = dataCustomerFromVuex
            referralCode.value = dataCustomerFromVuex.referral_code ===  null  || dataCustomerFromVuex.referral_code === undefined ?  'Unknown' : dataCustomerFromVuex.referral_code 
               console.log(referralCode.value)
               if(referralCode.value === null ){
                   console.log('masuk ke if')
               }else{
                   console.log('masuk ke else')
               }

            let dataCust = await store.getters.getCustomerDetail
            dataCustomer.value = dataCust
            console.log(dataCust.name)
            // console.log(dataCustomer)
            // get referral user
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getReferralHistory`,{
                uid:dataCust.id
            })
            .then((res)=>{
                // console.log(res.data)
                dataTableRefCode.value = res.data
            }).catch((err)=>{
                console.log(err)
            })
         
      }

    onMounted(()=>{
          fetchingLogin()
      })
      return{
          handleClick,
          referralCode,
          dataTableRefCode,
          dataCustomer
      }
  }
}
</script>

<style lang="scss">
    @import '../Styles/RefferalCode.scss';
    @import '../Styles/Mobile/RefferalCode.scss';
</style>