<template>
    <div class="container-topHeaderLogin">
        <div class="searching-box">
            <div class="searching-container">
                <img src="../../assets/Header/magnifier.svg" alt="">
                <input type="text" class="input-searching" placeholder="Search Anything">
            </div>
        </div>
        <div class="profile-box">
            <div class="dropdown" v-if="isLoginGlobal && isNotificationReady">
                <input type="checkbox" id="my-dropdown" value="" name="my-checkbox">
                <label for="my-dropdown"
                    data-toggle="dropdown"
                    @click="deleteNotif()"
                    
                    >
                    <div class = "number" v-if="state.isNotification && newAllNotification.length > 0" >
                        <i class="fa-solid fa-exclamation"></i>
                    </div>
                    <div  v-if="state.isNotification === false ">

                    </div>
                    <img src="../../assets/Header/bell.svg" alt="" class="icon-header-right"> 
                
                </label>
                <ul>
                    <li v-for="(items,id) in newAllNotification" :key="id" :load="log(items)">
                        <a href="#">
                            <p>
                               {{items.description}}
                            </p>
                            <div class="btn-close" @click="deleteNotifSatuan(items.id)">
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
              <div class="dropdown" v-if="isLoginGlobal && isNotificationReady === false">
                <input type="checkbox" id="my-dropdown" value="" name="my-checkbox">
                <label for="my-dropdown"
                    data-toggle="dropdown"
                    @click="deleteNotif()">
                    <div class = "number" v-if="state.isNotification" >
                        <i class="fa-solid fa-exclamation"></i>
                    </div>
                    <div  v-if="state.isNotification === false ">

                    </div>
                    <img src="../../assets/Header/bell.svg" alt="" class="icon-header-right"> 
                
                </label>
                <!-- <ul>
                    <li v-for="(items,id) in newAllNotification" :key="id" :load="log(items)">
                        <a href="#">
                            <p>
                               {{items.description}}
                            </p>
                            <div class="btn-close" @click="deleteNotifSatuan(items.id)">
                            </div>
                        </a>
                    </li>
                </ul> -->
            </div>
            
            
            <div class="box-for-profile">
                <a href="/profile" class="round-profile" @click="testFunc" v-if="isLoginGlobal === true">
                    <img  v-bind:src="`${profilePicture}`" alt="" v-if="profilePicture !== ''">
                    <img src="../../assets/dp-otis-1.png" alt="" v-else>
                </a>
                
                <a href="/login" class="btn-login-profile" @click="testFunc" v-if="isLoginGlobal === false">
                   <img src="../../assets/Header/log-in.svg" alt="">
                    LOGIN
                </a>    
            </div>
            <!-- <div @click="testFunc()">
                testing button
            </div> -->
        </div>
    </div>
</template>


<script>
// eslint-disable-next-line no-unused-vars
import {ref,reactive,onMounted,watch,computed} from 'vue'
import {useStore} from 'vuex'
import Axios from 'axios'


export default {
    name:'TopHeaderLogin',
    components:{
        
    },
    setup(){

        const store = useStore()
        let isLoginGlobal = ref(false)
        let isNotificationReady = ref(false)
        let allNotification = reactive([
            {
                notification:'Ini Fake'
            },
        ])
        let newAllNotification = ref([

        ])
        

        const state = reactive({isNotification:false})
        let profilePicture = ref([require("../../assets/Header/testing_pp.jpeg")])
        // let profile_number_id = ref(0)

        const testFunc=()=>{
            // allNotification.push({
            //     notification:'new Update'
            // })
            store.dispatch('getPushValueNotification',{
                notification:'ini testing dari vuex push'
            })
            
            state.isNotification = true
            // console.log(state.isNotification)
        }
        

        const deleteNotif=()=>{
            
            state.isNotification = false
        }
        const deleteNotifSatuan=(id)=>{
            // console.log(id)
            
            // allNotification.splice(id,1)
            console.log(id)
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/deleteNotification`,{
                "notification_id":id
            }).then((res)=>{
                // console.log(res.data)
                let allMessage = res.data.message
                let splitMessage = allMessage.split(' ')
                // console.log(splitMessage)
                if(splitMessage[0] === "Successfully"){
                    // console.log('berhasil delete')
                    var filter = newAllNotification.value.findIndex((val)=>{
                        // console.log(val)
                        return val.id === id 
                    })
                    // console.log(filter)
                    if(filter !== -1 ){
                        // let splice = newAllNotification.value.splice(1,filter)
                        // newAllNotification.value = splice 
                        newAllNotification.value.splice(filter,1)
                        
                        
                        console.log('masuk ke if splice',filter)
                    }else {
                        console.log('masuk ke else gaada yg di splice')
                    }
                    console.log(newAllNotification.value)
                    store.dispatch('getValueAllNotification')
                }
            }).catch((err)=>{
                console.log(err)
            })
            // console.log(allNotification)
        }
         watch(async()=>store.getters.getAllNotification, function() {
            // console.log('value changes detected');
            let allNotificationFromGetters = store.getters.getAllNotification
            newAllNotification.value = allNotificationFromGetters
            isNotificationReady.value = true
        });

        const fetchingLogin=async()=>{
            
            isLoginGlobal.value = store.getters.getIsLogin
            // eslint-disable-next-line no-unused-vars
            let dataCustomer = store.getters.getCustomerDetail
            // console.log(dataCustomer)
            profilePicture.value = dataCustomer.profilePicture
            // profile_number_id.value = dataCustomer.profile_number
            // console.log(profile_number_id.value)
            
            
            // state.isNotification = true
            newAllNotification.value = store.getters.getAllNotification
            await store.dispatch('getValueAllNotification')
            if(newAllNotification.value !== null ){
                // console.log('new all notification is undefined')
                isNotificationReady.value = true
                state.isNotification = true
            }else {
                isNotificationReady.value = false
                state.isNotification = false
                // console.log('new all notification ada isinya', newAllNotification.value)
            }

        }

        onMounted(()=>{
            fetchingLogin()
        })

        // eslint-disable-next-line no-unused-vars
        const log =(params)=>{
            // log(params)
        }

        return {
            isLoginGlobal,
            allNotification,
            
            deleteNotif,
            testFunc,
            state,
            deleteNotifSatuan,
            profilePicture,
            newAllNotification,
            log,
            // profile_number_id,
            isNotificationReady
        }
    }
}

</script>

<style lang="scss">
    @import '../Styles/TopheaderLogin.scss';
</style>