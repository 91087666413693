<template>
    <div class="container-home-profile">
        <SideHeader/>
        <div class="container-right-profile">
            <div class="main-container-right-profile">
                <div class="profile-padding">
                    <TopHeaderLogin/>
                </div>
                <div class="box-hello-profile">'
                    <p>Hello {{detailProfile.name}}</p>
                    <p>This is your profile page. You can see the progress you've made with <br> your work and manage your projects or assigned tasks </p>
                </div>
                <div class="box-main-profile">
                    <div class="main-profile-left">
                        <div class="box-stats-profile">
                            <div class="poin-left-profile">
                                <div class="coin-card">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        Points
                                    </p>
                                    <!-- <p>Tara Ingin Makan pecel ayam buvesti</p> -->
                                </div>
                                <p>{{detailProfile.point}}</p>
                            </div>
                            <div class="poin-right-profile">
                                <div class="coin-card">
                                    <p>
                                        <img src="../assets/PoinReward/medalLevel.svg" alt="">
                                        Level
                                    </p>
                                    <!-- <p>Tara Ingin Makan pecel ayam buvesti</p> -->
                                </div>
                                <p>{{detailProfile.level}}</p>
                            </div>
                        </div>
                        <div class="box-field-profile">
                            <div class="box-field-1">
                                <p>Edit Profile</p>
                                <div class="btn-save-profile" @click="simpanProfileDetail()">
                                    Simpan
                                </div>
                            </div>
                            <div class="box-field-2">
                                <p>USER INFORMATION</p>
                                <div class="box-detail-information">
                                    <div class="box-input-help">
                                        <div class="input-card-100" >
                                            <p>Full Name</p>
                                            <div class="box-input-profile" :class="[(isFullNameCorrect === true ? 'correct-input' : detailProfile.name !== '' && detailProfile.name === '' ? '' :  'wrong-input' )]">
                                                <input type="text" class="input-100" v-model="detailProfile.name" @change="onFirstName" autofocus>
                                                <span v-if="isFullNameCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isFullNameCorrect === false">Minimum 3 Character</p>
                                            </Transition>                        
                                        </div>
                                        
                                    </div>
                                    <div class="box-input-help">

                                        <div class="input-card-50">
                                            <p>Email Address</p>
                                            <div class="box-input-profile" :class="'correct-input'">
                                                <input type="text" class="input-50" v-model="detailProfile.email" @change="onEmail" disabled>
                                                <span>
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            
                                        </div>
                                        <div class="input-card-50">
                                            <p>Phone Number</p>
                                            <div class="box-input-profile" :class="[(isPhoneCorrect === true ? 'correct-input' : detailProfile.phoneNumber !== '' && detailProfile.phoneNumber === '' ? '' :  'wrong-input' )]">
                                                <div class="phone">
                                                    <select name="" id="" disabled="disabled">
                                                    <option value="+62">+62</option>
                                                    </select>
                                                    <input type="text" v-model="detailProfile.phoneNumber" class="input-50" onkeyup="this.value=this.value.replace(/[^\d]/,'')" @change="onPhoneNumber" autofocus>
                                                </div>
                                                <span v-if="isPhoneCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isPhoneCorrect === false" >Minimum 10 Character</p>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div class="line-box">

                                </div>
                            </div>
                            <div class="box-field-3">
                                <p>CONTACT INFORMATION</p>
                                <div class="box-detail-information">
                                    <div class="box-input-address">
                                        <div class="input-card-100">
                                            <!-- <p>Address</p>
                                            <input type="text" class="input-message"  v-model="detailProfile.address" @change="onAddress" autofocus> -->

                                            <p>Address</p>
                                            <div class="box-input-profile" :class="[(isAddressCorrect === true ? 'correct-input' : detailProfile.address !== '' && detailProfile.address === ''  ? '' :  'wrong-input' )]">
                                                <input type="text" class="input-100" v-model="detailProfile.address" @change="onAddress" autofocus>
                                                <span v-if="isAddressCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isAddressCorrect === false">Minimum 10 Character</p>
                                            </Transition> 
                                        </div>
                                        <div class="input-card-50">
                                            <p>ZipCode</p>
                                            <div class="box-input-profile" :class="[(isZipCorrect === true ? 'correct-input' : detailProfile.zipCode !== ''  && detailProfile.zipCode === '' ? '' :  'wrong-input' )]">
                                                <input type="text" class="input-50" v-model="detailProfile.zipCode" @change="onZipCode" autofocus>
                                                <span v-if="isZipCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isZipCorrect === false">Zipcode harus 5 digit</p>
                                            </Transition> 
                                        </div>
                                    </div>
                                    <div class="box-input-help">
                                        <div class="input-card-50">
                                            <p>Company</p>
                                            <div class="box-input-profile" :class="[(isCompanyCorrect === true ? 'correct-input' : detailProfile.company !== '' && detailProfile.company === '' ? '' :  'wrong-input' )]">
                                                <input type="text" class="input-50" v-model="detailProfile.company" @change="onCompany" autofocus>
                                                <span v-if="isCompanyCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isCompanyCorrect === false">Company minimal 3 character</p>
                                            </Transition>
                                        </div>
                                        <div class="input-card-50">
                                            <p>Job Title</p>
                                            <div class="box-input-profile" :class="[(isJobTitleCorrect === true ? 'correct-input' : detailProfile.jobTitle !== '' && detailProfile.jobTitle === '' ? '' :  'wrong-input' )]">
                                                <input type="text" class="input-50" v-model="detailProfile.jobTitle" @change="onJobTitle" autofocus>
                                                <span v-if="isJobTitleCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isJobTitleCorrect === false">Job title minimal 2 character</p>
                                            </Transition>
                                        </div>
                                    </div>
                                    <div class="box-input-help">
                                        <div class="input-card-50">
                                            <p>Place of Birth</p>
                                            <div class="box-input-profile" :class="[(isPoBCorrect === true ? 'correct-input' : detailProfile.placeOfBirth !== '' && detailProfile.placeOfBirth === '' ? '' :  'wrong-input' )]">
                                                <input type="text" class="input-50" v-model="detailProfile.placeOfBirth" @change="onPob" autofocus>
                                                <span v-if="isPoBCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isPoBCorrect === false">PoB minimal 3 character</p>
                                            </Transition>
                                            <!-- <p>Place Of Birth</p>
                                            <input type="text" class="input-50" v-model="detailProfile.placeOfBirth" @change="onPob" autofocus> -->
                                        </div>
                                        <div class="input-card-50">
                                            <p>Date of Birth</p>
                                            <div class="box-input-profile" :class="[(isDoBCorrect === true ? 'correct-input' : detailProfile.dateOfBirth !== '' && detailProfile.dateOfBirth === '' ? '' :  'wrong-input' )]">
                                                <input type="date" class="input-50" v-model="detailProfile.dateOfBirth" @change="onDob"  max='2012-12-30'>
                                                <span v-if="isDoBCorrect === true">
                                                    <i class="fa-solid fa-circle-check"></i>
                                                </span>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isDoBCorrect === false">DoB harus diisi</p>
                                            </Transition>
                                        </div>
                                    </div>
                                    <div class="box-input-help">
                                        <div class="input-card-3">
                                            <p>Gender</p>
                                            <div class="box-input-profile" :class="[(isGenderCorrect === true ? 'correct-input' : detailProfile.gender === '' && detailProfile.gender === '' ? '' :  'wrong-input' )]">
                                                <select name="gender" id="gender" class="genderCustomer"  v-model="detailProfile.gender" @change="onGender">
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                            <Transition>
                                                <p class="error-notification" v-if="isGenderCorrect === false">Gender harus diisi</p>
                                            </Transition>
                                        </div>
                                    </div>
                                    <div class="checklist">
                                        <input class="checkbox" type="checkbox" v-model="checkedTnC" @change="onCheckedTnC()">
                                        <!-- <span class="checkmark"></span> -->
                                        <p>I read and agree to <a href="https://www.millealab.com/tnc" target="_blank">Terms & Condition</a> </p>
                                    </div>
                                    <div class="checklist">
                                        <input class="checkbox" type="checkbox" v-model="checkedPromotion" @change="onCheckedTnC()">
                                        <!-- <span class="checkmark"></span> -->
                                        <p>Yes, I want to receive email promotion from MilleaLab</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-profile-right">
                        <div class="profile-right-top">
                            <div class="bg-profile-right">
                                <div class="profile-round">
                                    <img  v-bind:src="`${detailProfile.profilePicture}`" alt="" v-if="detailProfile.profilePicture !== ''">
                                    <img src="../assets/dp-otis-1.png" alt="" v-else>
                                </div>
                            </div>
                            <!-- <div class="box-btn-profile">
                                <div class="btn-left">
                                    Connect
                                </div>
                                <div class="btn-right">
                                    Message
                                </div>
                            </div> -->
                            <div class="box-status-profile">
                                <div class="card-status">
                                    <p>{{detailProfile.point}}</p>
                                    <p>Point</p>
                                </div>
                                <div class="card-status">
                                    <p>{{detailProfile.level}}</p>
                                    <p>Level</p>
                                </div>
                                <div class="card-status">
                                    <p>{{detailProfile.totalReferral}}</p>
                                    <p>Referral</p>
                                </div>
                            </div>
                            <div class="box-detail-customer">
                                <p>{{detailProfile.name}}, <span>{{age}}</span></p>
                                <p>{{detailProfile.placeOfBirth}},Indonesia</p>
                                <p>{{detailProfile.company}}</p>
                            </div>

                        </div>
                        <div class="profile-right-bottom">
                            <p>Progress Track</p>
                            <div class="btn-line">
                            </div>
                            <div class="progress-card">
                                <p>Individual License</p>
                                <p>{{detailProfile.individualLicense}} <span>Month</span></p>
                            </div>
                            <!-- <div class="progress-card">
                                <p>VR CardBoard</p>
                                <p>{{detailProfile.vrCardboard}} <span>Pieces</span></p>
                            </div> -->
                        </div>

                    </div>
                </div>  
            </div>
        </div>
    </div>

</template>

<script>
import SideHeader from '@/components/Header/SideHeader.vue'
import {ref,onMounted} from 'vue'
import { createToast } from 'mosha-vue-toastify';
// import {useRouter} from 'vue-router'
// import Axios from 'axios'

import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'
import {useStore} from 'vuex'
import Axios from 'axios'
import {useLoading} from 'vue-loading-overlay'
export default {
    name:'ProfileView',
    components:{
        SideHeader,
        TopHeaderLogin
    },
    setup(){
        // const route = useRouter()
        const $loading = useLoading()
        const store = useStore()
        let params = ref('')

        let firstName = ref('')
        let lastName = ref('')
        let email = ref('')
        let phoneNumber = ref('')
        let age = ref(0)
        let referralCode = ref('')
        let address = ref('')
        let zipcode = ref('')
        let gender = ref('')
        let checkedTnC = ref('')
        let checkedPromotion = ref('')
        let detailProfile = ref ({
            name:'',
            firstName:'',
            lastName:'',
            email:'',
            phoneNumber:'+',
            referralCode:'',
            point:0,
            totalReferral:'',
            address:'',
            zipCode:'',
            gender:'',
            company:'',
            jobTitle:'',
            placeOfBirth:'',
            dateOfBirth:'',
            individualLicense:0,
            vrCardboard:'',
            level:'Bronze',
            profilePicture:[require("../assets/Header/testing_pp.jpeg")]
        })
        let isFullNameCorrect = ref(false)
        let isPhoneCorrect = ref(false)
        let isAddressCorrect = ref(false)
        let isZipCorrect = ref(false)
        let isCompanyCorrect = ref(false)
        let isJobTitleCorrect = ref(false)
        let isPoBCorrect = ref(false)
        let isDoBCorrect = ref(false)
        let isGenderCorrect = ref(false)
        // let profile_number_id = ref(0)


        const fetchingLogin=()=>{
            // console.log('fetching profile view')
            let dataCustomer = store.getters.getCustomerDetail 
            // console.log(dataCustomer)
            // console.log(dataCustomer.phone)
            if(dataCustomer.phone === undefined){
                // console.log('masuk ke if phone nnumber is undefined')
                detailProfile.value.phoneNumber = ''
            }else {
                detailProfile.value.phoneNumber =`${dataCustomer.phone}` 
                onPhoneNumber()
            }
            // detailProfile.value.firstName = dataCustomer.firstName
            // detailProfile.value.lastName = dataCustomer.lastName
            detailProfile.value.name = dataCustomer.name,
            // detailProfile.value.phoneNumber =`${dataCustomer.phoneNumber}` 
            detailProfile.value.email = dataCustomer.email
            detailProfile.value.referralCode = dataCustomer.referral_code
            detailProfile.value.address = dataCustomer.address
            detailProfile.value.zipCode = dataCustomer.zipCode
            detailProfile.value.gender = dataCustomer.gender
            detailProfile.value.company = dataCustomer.company
            detailProfile.value.jobTitle = dataCustomer.jobTitle
            detailProfile.value.placeOfBirth = dataCustomer.placeOfBirth
            detailProfile.value.dateOfBirth = dataCustomer.dateOfBirth
            detailProfile.value.individualLicense = dataCustomer.individualLicense
            detailProfile.value.vrCardboard = dataCustomer.vrCardboard
            detailProfile.value.level = dataCustomer.level
            detailProfile.value.totalReferral = dataCustomer.totalReferral
            detailProfile.value.point = dataCustomer.points
            detailProfile.value.profilePicture = dataCustomer.profilePicture 
            detailProfile.value.individualLicense = dataCustomer.individualLicense
            // console.log(detailProfile.value.dateOfBirth, detailProfile.value.dateOfBirth.split('-'))

            if(detailProfile.value.dateOfBirth){
                let yearCustomer = parseInt(detailProfile.value.dateOfBirth.split('-')[0])
                
                let year = new Date().getFullYear();
                let countAge = year -  yearCustomer
                age.value = countAge
                // console.log(countAge)
                // console.log(yearCustomer)
            }else {
                age.value = ''
            }
            // console.log(detailProfile.value.individualLicense)
            onFirstName()
            onEmail()
            onAddress()
            onZipCode()
            onCompany()
            onJobTitle()
            onPob()
            onDob()
            onGender()
        }
        onMounted(()=>{
            fetchingLogin()
        })


        function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
        }
        const onFirstName=()=>{
            // console.log(detailProfile.value.firstName)

            if(detailProfile.value.name !== undefined ){
                if(detailProfile.value.name.length >3){
                    // console.log('input name bener')
                    isFullNameCorrect.value = true
                }else {
                    // console.log('alert inout name harus lkebih dari 3')
                    isFullNameCorrect.value = false
                }
            }else {
                // console.log('alert inout name harus lkebih dari 3')
                isFullNameCorrect.value = false
            }
        }
         const onLastName=()=>{
            // console.log(detailProfile.value.lastName)
            if(detailProfile.value.lastName.length){
                // console.log('input last name bener')
            }else {
                // console.log('input alert harus lebih 3 character')
            }
        }

        const onEmail=()=>{
            // console.log(detailProfile.value.email)
            let checking_email = validateEmail(detailProfile.value.email)
              if(checking_email){
                // console.log('input last name bener')
            }else {
                // console.log('input alert harus lebih 3 character')
            }
        }
        const onPhoneNumber=()=>{
            // console.log(detailProfile.value.phoneNumber)
            if(detailProfile.value.phoneNumber.length >9 && detailProfile.value.phoneNumber.length < 15){
                // console.log('input phone number benar')
                isPhoneCorrect.value = true
                // console.log(detailProfile.value.phoneNumber)
                let split_number = detailProfile.value.phoneNumber.split('')
                // console.log(split_number)
                let number_62 = split_number[0] + split_number[1] + split_number[2]
                let number_plus62 = split_number[0] + split_number[1] + split_number[2]
                let number_628 = split_number[0] + split_number[1] + split_number[2]
                let number_0 = split_number[0]
                if(number_plus62 === '+62'){
                    // console.log('nomor awalnya 62')
                    split_number.splice(0,3)
                    detailProfile.value.phoneNumber = split_number.join('')
                }else if(number_62 === '62'){
                    split_number.splice(0,2)
                    detailProfile.value.phoneNumber = split_number.join('')
                }else if (number_628 === '628'){
                    split_number.splice(0,2)
                    detailProfile.value.phoneNumber = split_number.join('')
                }
                else if (split_number[0] === '0'){
                    // console.log('nomor awalnya 0 / +')
                    split_number.splice(0,1)
                    detailProfile.value.phoneNumber = split_number.join('')
                }else if(number_0 === '0'){
                    split_number.splice(0,1)
                    detailProfile.value.phoneNumber = split_number.join('')
                }else {
                    // console.log('masuk ke else')
                }
            }else if (detailProfile.value.phoneNumber === null || detailProfile.value.phoneNumber === 'null'){
                detailProfile.value.phoneNumber = ''
            }
            else {
                // console.log(detailProfile.value.phoneNumber, detailProfile.value.phoneNumber === 'null')
                // console.log('input phone number harus lebih dari 9')
                isPhoneCorrect.value=false
                //  createToast({
                //         title: 'error',
                //         description: 'Phone Number minimum 10 Character'
                //         },
                //         {
                //         // timeout: 3000,
                //         type: 'danger',
                //         transition: 'bounce',
                //         position:'top-center'
                //     })
            }
        }
        const onAddress=()=>{
            // console.log(detailProfile.value.address)
            if(detailProfile.value.address !== undefined){
                if(detailProfile.value.address.length > 10){
                    // console.log('input address benar')
                    isAddressCorrect.value = true
                }else {
                    // console.log('alamat terlalu pendek')
                    isAddressCorrect.value = false
            }
            }else {
                isAddressCorrect.value = false
            }
        }
        const onZipCode=()=>{
            // console.log(detailProfile.value.zipCode)
            if(detailProfile.value.zipCode !== undefined){
                if(detailProfile.value.zipCode.length === 5){
                    // console.log('input zipcode benar')
                    isZipCorrect.value = true
                }else {
                    // console.log('zipcode harus 5 digit')
                    isZipCorrect.value = false
                }
            }else {
                
                isZipCorrect.value = false
            }
        }
        const onCompany=()=>{

            if(detailProfile.value.company !== undefined ){
                if(detailProfile.value.company.length > 3){
                    // console.log('company benar')
                    isCompanyCorrect.value = true
                }else {
                    // console.log('company minimal 3 character')
                    isCompanyCorrect.value = false
                }
            }else {
                    isCompanyCorrect.value = false
            }
            // console.log(detailProfile.value.company)
        }
        const onJobTitle=()=>{
            if(detailProfile.value.jobTitle !== undefined){
                if(detailProfile.value.jobTitle.length > 2){
                    // console.log('job title benar')
                    isJobTitleCorrect.value = true
                }else {
                    // console.log('job title minimal 2 character')
                    isJobTitleCorrect.value = false
                }
            }else {
                // console.log('job title minimal 2 character')
                isJobTitleCorrect.value = false
            }
            // console.log(detailProfile.value.jobTitle)
        }

        const onPob=()=>{
            if(detailProfile.value.placeOfBirth !== undefined){
                if(detailProfile.value.placeOfBirth.length > 3){
                    // console.log('pob benar')
                    isPoBCorrect.value = true
                }else {
                    // console.log('pob minimal 3 character')
                    isPoBCorrect.value = false
                }
            }else {
                    // console.log('pob minimal 3 character')
                    isPoBCorrect.value = false
                }
            // console.log(detailProfile.value.placeOfBirth)
        }
        const onDob=()=>{
            if(detailProfile.value.dateOfBirth != null){
                // console.log('dob benar')
                isDoBCorrect.value = true
            }else {
                // console.log('dob harus di isi')
                isDoBCorrect.value = false
            }
            // console.log(detailProfile.value.dateOfBirth)
        }
        const onGender=()=>{
            if(detailProfile.value.gender != null){
                isGenderCorrect.value = true
            }else {
                isGenderCorrect.value = false
            }
        }
        const onCheckedTnC=()=>{
            console.log(checkedTnC.value)
        }

        const simpanProfileDetail=()=>{
            // console.log(detailProfile.value,' final detail profile')
             const loader = $loading.show({
                // Optional parameters
            });
            // let data = true
            if(isFullNameCorrect.value && isPhoneCorrect.value && isAddressCorrect.value && isZipCorrect.value && isCompanyCorrect.value && isJobTitleCorrect.value && isPoBCorrect.value && isDoBCorrect.value && isGenderCorrect.value && checkedTnC.value){
                let uid = localStorage.getItem('idStorage')
                var dataToSend = JSON.stringify({
                "uid": uid,
                "name":detailProfile.value.name,
                "first_name": detailProfile.value.firstName,
                "last_name": detailProfile.value.lastName,
                "email": detailProfile.value.email,
                "phone": `+62${detailProfile.value.phoneNumber}`,
                "pob": detailProfile.value.placeOfBirth,
                "dob": detailProfile.value.dateOfBirth,
                "gender": detailProfile.value.gender,
                "company": detailProfile.value.company,
                "job": detailProfile.value.jobTitle,
                "address": detailProfile.value.address,
                "zip":detailProfile.value.zipCode,
                "tnc": true,
                "receive_email": true
                });
                // console.log(dataToSend)

                var config = {
                method: 'post',
                url: 'https://us-central1-millea-lab.cloudfunctions.net/web/api/updateUserData',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : dataToSend
                };
                console.log(dataToSend)

                Axios(config)
                // eslint-disable-next-line no-unused-vars
                .then(function (response) {
                // console.log(response.data)
                loader.hide()
                    createToast({
                        title: 'Success',
                        description: 'Berhasil Menyimpan'
                        },
                        {
                        // timeout: 3000,
                        type: 'success',
                        transition: 'bounce',
                        position:'top-center'
                    })
                })
                .catch(function (error) {
                console.log(error.message);
                loader.hide()
                    createToast({
                        title: 'Error',
                        description: 'Email or phone number already exists. Please use another email.'
                        },
                        {
                        // timeout: 3000,
                        type: 'danger',
                        transition: 'bounce',
                        position:'top-center'
                    })
               
                });

            }else {
                loader.hide()
                createToast({
                  title: 'Error',
                  description: 'Gagal Menyimpan, ulangi lagi beberapa saat'
                  },
                  {
                  // timeout: 3000,
                  type: 'danger',
                  transition: 'bounce',
                  position:'top-center'
                })
            }
            

        }

        return {
           params,
           firstName,
           lastName,
           email,
           phoneNumber,
           referralCode,
           address,
           zipcode,
           gender,
           detailProfile,
           checkedTnC,
           checkedPromotion,
           fetchingLogin,
           onFirstName,
           onLastName,
           onEmail,
           onPhoneNumber,
           onAddress,
           onZipCode,
           onCompany,
           onJobTitle,
           onPob,
           onDob,
           onGender,
           onCheckedTnC,
           simpanProfileDetail,
           validateEmail,
           isFullNameCorrect,
           isPhoneCorrect,
           isAddressCorrect,
           isZipCorrect,
           isCompanyCorrect,
           isJobTitleCorrect,
           isPoBCorrect,
           isDoBCorrect,
           isGenderCorrect,
           age
        //    profile_number_id
        }
    }
}
</script>


<style lang="scss">
    @import '../Styles/Profile.scss';
    @import '../Styles/Mobile/Profile.scss';
</style>
