// import { async } from '@firebase/util'
import Axios from 'axios'
// import {useStore} from 'vuex'
import Auth from './auth'
const state = {
    allArrayFaq:[
          
          {
            id:'01',
            status:'General',
            question:'Apa itu MilleaLab Individual License? ',
            answer:'Merupakan lisensi premium MilleaLab creator yang dapat menggunakan akun pribadi dan tidak terhubung oleh institusi manapun.'
          },
          {
            id:'02',
            status:'General',
            question:'Bagaimana cara mendaftarkan akun MilleaLab Individual License',
            answer:'Klik Registrasi, kemudian isi data diri anda, atau register dengan menggunakan akun google anda. '
          },
          {
            id:'03',
            status:'General',
            question:'Bagaimana cara login ke Website MilleaLab Individual License ?',
            answer:'Jika sudah registrasi silahkan masukan email dan password anda, namun apabila anda registrasi menggunakan akun google anda, maka dapat langsung masuk ke dalam Website MilleaLab Individual License.'
          },
          {
            id:'04',
            status:'General',
            question:'Apakah saya dapat menggunakan alamat email apapun untuk login ke dalam Website MilleaLab Individual License ?',
            answer:'Sangat bisa,selama akun tersebut sudah atau akan digunakan di MilleaLab creator.'
          },
           {
            id:'05',
            status:'General',
            question:'Apakah akun MilleaLab saya akan terupgrade secara otomatis,setelah membeli Individual License di website ini ?',
            answer:'Untuk memastikan license yang di beli itu dapat digunakan di MilleaLab pastikan login dengan akun yang di gunakan di MilleaLab creator.'
    
          },
          {
            id:'06',
            status:'General',
            question:'Apa itu Referral Code ?',
            answer:'Merupakan kode unik yang dimiliki oleh setiap akun dimana setiap pembelian yang menggunakan referral code tersebut akan mendapatkan penambahan point bagi pemilik kode tersebut.'
            
          },
           {
            id:'07',
            status:'General',
            question:'Bagaimana cara mendapatkan Referral Code  ?',
            answer:'Klik menu Referral Code, kemudian pada pojok kanan atas terdapat kode unik yang dapat disalin dan disebarkan untuk digunakan oleh pengguna lain.'
            
          },
            {
            id:'08',
            status:'General',
            question:'Bagaimana cara memberikan ulasan pada website ini ? ',
            answer:'Anda dapat memberikan ulasan tentang Website MilleaLab Individual License ini pada menu utama, kemudian klik review.'
            
          },
            {
            id:'09',
            status:'General',
            question:'Mengapa saya tidak dapat memberikan ulasan ?',
            answer:'Anda dapat memberikan ulasan setelah anda melakukan pembelian MilleaLab Individual License. '
            
          },
          {
            id:'01',
            status:'MilleaLabCreator',
            question:'Mengapa Saya Tidak Dapat Terkoneksi dengan MilleaLab atau Mendapatkan Keterangan "Server Terputus" ?',
            answer:'1.Periksa kembali koneksi internet Anda dengan membuka situs kesayangan Anda. Apakah internet yang digunakan sudah stabil? Anda juga dapat test speed koneksi Anda dengan mengakses speedtest yang ada di browser Anda. \n 2Jika internet stabil tetapi masih belum terkoneksi, silahkan menonaktifkan antivirus atau vpn Anda. \n 3.Jika langkah-langkah di atas belum berhasil, dimohon untuk mengganti jaringan internet Anda dengan jaringan lainnya.'
          },
          {
            id:'02',
            status:'MilleaLabCreator',
            question:'Mengapa saya tidak dapat login di MilleaLab Creator?',
            answer:'1.Periksa kembali koneksi internet Anda dengan membuka situs kesayangan Anda. Apakah internet sudah stabil? \n 2.Gunakan browser lain (Chrome atau Firefox). Beberapa web browser mungkin tidak kompatibel di komputer Anda, silakan mencoba browser lain. Kami merekomendasikan Chrome atau Firefox. \n 3. Apabila langkah-langkah di atas tidak berhasil, silakan restart komputer Anda atau coba gunakan koneksi internet lain.'
          },
          {
            id:'03',
            status:'MilleaLabCreator',
            question:'Apa yang dapat saya lakukan jika saat membuka MilleaLab Creator muncul pesan "Error get user data"?',
            answer:'1.Jika mendapat pesan "Error get user data", silakan mengganti provider internet, lalu tutup dan buka kembali aplikasi MilleaLab Creator. \n 2.Lakukan logout akun, kemudian login kembali dengan akun yang sama.'
          },
           {
            id:'04',
            status:'MilleaLabCreator',
            question:'Beberapa Interaction Merupakan Fitur Premium dari MilleaLab. Apabila Anda adalah Pengguna Akun Premium, Namun Interaction Anda terkunci, Silakan Ikuti Langkah-langkah Berikut Ini:',
            answer:'1.Klik MyAccount > Nama Anda. Pastikan di bagian license Anda adalah “Premium” \n 2.Apabila License Anda tertulis Free seperti di bawah ini, namun Anda merupakan pengguna akun premium, silakan melakukan proses Relogin (Logout kemudian Login kembali) untuk memastikan koneksi Anda berhasil. \n 3.Apabila langkah-langkah di atas tidak berhasil, silakan kontak Tim MilleaLab untuk bantuan lebih lanjut.'
          },
          {
            id:'05',
            status:'MilleaLabCreator',
            question:'Apa yang Dimaksud dengan Peletakan Stand Point Sesuai Eye Level? ',
            answer:'Peletakan sesuai eye level adalah sebuah rekomendasi untuk menempatkan objek-objek VR (termasuk interaksi) yang dapat disesuaikan dengan posisi mata player (pengguna) sehingga meminimalisir player untuk melihat terlalu ke atas atau ke bawah, dan juga menambah nilai imersif ke player.'
          },
          {
            id:'06',
            status:'MilleaLabCreator',
            question:'Apakah saya dapat menambahkan audio record ke dalam MilleaLab Creator?',
            answer:'1.Sangat bisa. MilleaLab Creator memiliki fitur untuk memasukan audio record baik dengan merekam suara secara langsung dari MilleaLab Creator ataupun dengan menguploadnya dari file yang telah Anda siapkan. \n 2.MilleaLab Creator memiliki fitur untuk memasukan file audio record yang telah kita buat dengan maksimal ukuran file nya adalah 5MB dan pastikan file berbentuk mp3.'
          },
          {
            id:'07',
            status:'MilleaLabCreator',
            question:'Mengapa saya tidak dapat memasukan file gambar pada Custom Image?',
            answer:'Pastikan file gambar berbentuk .jpg atau .png dengan ukuran maksimal file 2 MB per gambar.'
          },
          {
            id:'08',
            status:'MilleaLabCreator',
            question:'Mengapa video Youtube dan Youtube360 tidak dapat diputar?',
            answer:'1.Pastikan URL yang disalin benar. Format URL biasanya berupa [https://www.youtube.com/watch?v=] + [kode video]. Contoh : https://www.youtube.com/watch?v=vh-rdXvt0Dw \n 2. Periksa koneksi internet. Apabila terdapat pesan error pada aplikasi, silakan perhatikan pesan tersebut untuk menemukan masalahnya.'
          },
          {
            id:'09',
            status:'MilleaLabCreator',
            question:'Apakah Saya Dapat Mengupload Video yang Telah Saya Buat ke dalam MilleaLab Creator?',
            answer:'Bisa. Anda dapat memanfaatkan fitur unggah video dengan file berukuran maksimal 50 MB per video.'
          },
          {
            id:'10',
            status:'MilleaLabCreator',
            question:'Apa yang harus saya lakukan jika aset 3D, BGM, dan Skybox yang diinginkan tidak terdapat di MilleaLab Creator?',
            answer:'Anda dapat merequest 3D Asset, BGM, dan Skybox ke tim MilleaLab dengan menghubungi nomor yang tertera di website.'
          },
          {
            id:'11',
            status:'MilleaLabCreator',
            question:'Apakah saya dapat mengunggah aset 3D, BGM, dan Skybox yang telah saya buat ke MilleaLab Creator?',
            answer:'Sangat bisa, MilleaLab Creator memiliki fitur submission untuk mengupload 3D Asset, BGM, dan Skybox yang terdapat rincian kriteria dan stAndarisasi yang telah ditentukan oleh MilleaLab.'
          },
          {
            id:'12',
            status:'MilleaLabCreator',
            question:'Apakah saya dapat memodifikasi template scene yang sudah tersedia di MilleaLab Creator?',
            answer:'Bisa. Anda dapat memodifikasi template scene yang sudah disediakan di MilleaLab sesuai dengan kebutuhan Anda.'
          },
          {
            id:'13',
            status:'MilleaLabCreator',
            question:'Mengapa saya tidak dapat menyimpan scene yang telah dibuat?',
            answer:'1.Periksa koneksi internet, apakah koneksi internet Anda sedang aktif dan stabil?. \n 2. Jika Anda menggunakan antivirus, coba untuk menonaktifkan antivirus Anda terlebih dahulu, atau pastikan koneksi menuju MilleaLab tidak diblokir oleh antivirus Anda. \n 3.Apabila langkah-langkah di atas tidak berhasil, silakan kontak Tim MilleaLab untuk bantuan lebih lanjut.'
          },
          {
            id:'14',
            status:'MilleaLabCreator',
            question:'Apa yang harus saya lakukan jika koneksi saya terputus sebelum/saat menyimpan scene?',
            answer:'1.Sebelum Anda membuat scene, periksa kembali koneksi internet Anda dengan membuka situs kesayangan Anda. Apakah internet yang digunakan sudah stabil? Anda juga dapat mengetes speed koneksi Anda dengan mengakses speed test yang ada di browser Anda. \n 2.Jika internet sudah dipastikan aman, silahkan logout akun Anda, kemudian login kembali. \n 3.Jika kondisi internet terkendala jaringan, silahkan menggunakan internet lain. \n 4.Pastikan fitur Autosave di MilleaLab Creator sudah diaktifkan untuk menghindari kendala-kendala yang muncul sehingga scene dapat tersimpan secara otomatis. \n 5. Jika langkah-langkah berikut masih belum berhasil, segera kontak tim MilleaLab untuk bantuan lebih lanjut.'
          },
          {
            id:'15',
            status:'MilleaLabCreator',
            question:'Mengapa saya tidak dapat mengirim scene ke pengguna lain?',
            answer:'1.Pastikan Anda sudah menyimpan dan sedang membuka scene yang ingin Anda kirim. (Silakan cek pesan error pada aplikasi MilleaLab untuk detail lebih lanjut). \n 2.Pastikan alamat email yang Anda input benar dan tidak ada kesalahan ketik/typo.'
          },
          {
            id:'16',
            status:'MilleaLabCreator',
            question:'Apa yang Saya Lakukan Jika Kelas yang Telah Saya Buat Sudah Disable/Tidak Aktif?',
            answer:'Kelas yang Disable/tidak aktif, dapat Anda aktifkan kembali dengan cara: Scene-Kelas Saya (my classroom) - dibagian bawah ada tulisan Enable/Aktifkan - kemudian ceklis.'
          },
          {
            id:'17',
            status:'MilleaLabCreator',
            question:'Apa yang Saya Lakukan Jika Peserta Didik Saya Sudah Melampaui Kapasitas Kelas?',
            answer:'Anda dapat menonaktifkan kelas yang sudah diakses peserta didik secara full. Kemudian silakan mengaktifkan kelas lain untuk diakses peserta didik lainnya dengan scene yang sama.'
          },
          {
            id:'01',
            status:'MilleaLabViewer',
            question:'Apa yang Harus Dilakukan Jika Mengalami Stuck Checking Last Session di MilleaLab Viewer?',
            answer:'Hapus data aplikasi MilleaLab setelah update di Google Play Store.'
          },
          
            {
            id:'02',
            status:'MilleaLabViewer',
            question:'Apa yang harus dilakukan jika membuka scene muncul pesan "Download problems occured"?',
            answer:'Periksa koneksi internet Anda, apakah aktif dan stabil?'
          },
            {
            id:'03',
            status:'MilleaLabViewer',
            question:'Mengapa saya tidak dapat login ke dalam MilleaLab Viewer ?',
            answer:'Periksa kembali koneksi internet Anda dengan membuka situs kesayangan Anda, apakah internet sudah stabil?'
          },
           {
            id:'04',
            status:'MilleaLabViewer',
            question:'Mengapa saya tidak dapat bergabung ke Classroom?',
            answer:'Periksa kembali kode classroom yang didapatkan di aplikasi MilleaLab Creator, pastikan tidak ada kesalahan ketik/typo.'
          },
           {
            id:'05',
            status:'MilleaLabViewer',
            question:'Apakah MilleaLab Viewer memiliki mode offline? Bagaimana cara mengaktifkannya?',
            answer:'Ada. Mode offline dapat Anda akses dengan logout terlebih dahulu, kemudian pastikan tidak terhubung jaringan apapun pada smartphone Anda. Buka MilleaLab Viewer, kemudian pilih mode offline.'
          },
           {
            id:'06',
            status:'MilleaLabViewer',
            question:'Mengapa saya tidak dapat membuka Scene di Viewer pada saat mode offline?',
            answer:'Pastikan Anda telah mengakses scene tersebut pada saat terhubung jaringan internet karena sistem ini adalah semi offline sehingga Anda perlu mengakses secara online terlebih dahulu kemudian dapat diakses secara offline.'
          },
           {
            id:'07',
            status:'MilleaLabViewer',
            question:'Bagaimana Saya Mengakses Konten yang Telah DIbuat Jika Saya Tidak Memiliki Kacamata VR/VR Cardboard? ',
            answer:'MilleaLab Viewer memiliki Mode VR dan Mode Non-VR (Mode 360 dan Non-Gyro). Anda dapat mengakses konten yang telah dibuat dengan Mode Non-VR (Mode 360 dan Non-Gyro) jika tidak memiliki kacamata VR/VR Cardboard.'
          },
           {
            id:'8',
            status:'MilleaLabViewer',
            question:'Mengapa Saya Tidak Dapat Membuka Scene di Mode VR ?',
            answer:'1. Pastikan smartphone yang Anda miliki sudah memiliki sensor gyroscope. \n  2. Jika smartphone Anda sudah dilengkapi dengan sensor gyroscope, kemudian lakukan testing dengan cara mengunduh Google Cardboard untuk memastikan apakah sensor gyroscope yang Anda miliki di smartphone Anda berfungsi dengan baik. \n  3. Jika sensor gyroscope pada smartphone Anda memiliki masalah, silakan menggunakan mode non-gyro.'
          },
           {
            id:'9',
            status:'MilleaLabViewer',
            question:'Mengapa kelas yang saya buat tidak muncul?',
            answer:'Pastikan kode kelas unik yang ada di MilleaLab Creator sudah diinput dengan benar tanpa adanya kesalahan ketik/typo, dan pastikan email yang digunakan untuk mengakses di MilleaLab Viewer berbeda dengan email untuk mengakses MilleaLab Creator.'
          },
           {
            id:'10',
            status:'MilleaLabViewer',
            question:'Bagaimana cara menghubungkan Bluetooth Controller di MilleaLab Viewer?',
            answer:'Aktifkan Bluetooth Controller Anda->Kemudian buka menu setting pada aplikasi MilleaLab Viewer di Smartphone Anda->kemudian klik  Bluetooth-> lalu hubungkan Bluetooth Controller Anda dengan Smartphone Anda '
          },
          {
            id:'11',
            status:'MilleaLabViewer',
            question:'Mengapa kuis yang saya buat tidak keluar?',
            answer:'1. Pastikan kondisi internet Anda tidak terputus pada saat membuka scene di MilleaLab Viewer. \n 2. Pastikan kuis sudah diatur di MilleaLab Creator, baik dari soal dan dari pengaturan nilainya.'
          },
          {
            id:'12',
            status:'MilleaLabViewer',
            question:'Bagaimana cara keluar dari scene yang saya lihat di MilleaLab Viewer?',
            answer:'Arahkan ke arah lantai pada scene yang Anda lihat, kemudian terlihat tombol menu di bawah kemudian. Klik tombol menu tersebut, kemudian pilih back menu untuk kembali ke menu MilleaLab Viewer atau pilih Exit Viewer untuk keluar dari Aplikasi MilleaLab Viewer'
          },
          {
            id:'01',
            status:'Pricing',
            question:'Apakah MilleaLab tersedia secara gratis? ',
            answer:'MilleaLab Creator tersedia secara free, tetapi fitur interaksi premium terkunci. Anda dapat berlangganan premium MilleaLab untuk mengakses fitur interaksi premium.'
          },
           {
            id:'02',
            status:'Pricing',
            question:'Bagaimana cara membeli MilleaLab Individual License  ? ',
            answer:'Klik tombol purchase pada menu utama, kemudian isi data diri beserta Referral Code(Jika mempunyai Referral Code), Kemudian klik next lalu anda akan diminta untuk menyelesaikan pembayaran sesuai dengan metode pembayaran yang anda inginkan.'
          },
           {
            id:'03',
            status:'Pricing',
            question:'Apakah dapat membeli License tanpa menggunakan Referral Code',
            answer:'Sangat bisa, memasukan referral code merupakan opsional, anda dapat melihat kewajiban serta syarat & ketentuan Referral Code'
          },
           {
            id:'04',
            status:'Pricing',
            question:'Apakah dapat menggunakan referral code sendiri untuk setiap pembelian license pribadi ?',
            answer:'Referral Code hanya dapat digunakan oleh akun lain anda dapat melihat kewajiban serta syarat & ketentuan Referral Code'
          },
          {
            id:'05',
            status:'Pricing',
            question:'Bagaimana cara mengecek pembelian berhasil? ',
            answer:'Anda dapat mengecek pada notifikasi dan history pembelian.'
          },
          {
            id:'01',
            status:'PoinReward',
            question:'Apa itu Level pada MilleaLab Individual License ?',
            answer:'Level merupakan sebuah pencapaian dari setiap pengguna berdasarkan hasil point yang telah di kumpulkan, dan akan mendapatkan benefit sesuai dengan level yang sudah di tentukan.'
          },
           {
            id:'02',
            status:'PoinReward',
            question:'Apa keuntungan mendapatkan point di MilleaLab Individual License ?',
            answer:'Anda akan mendapat benefit yang tersedia berupa voucher diskon ketika pembelian MilleaLab Individual License seusai dengan level masing-masing akun.'
          },
          {
            id:'03',
            status:'PoinReward',
            question:'Bagaimana cara mendapatkan Point tersebut ?',
            answer:'Setiap melakukan pembelian Individual License MilleaLab  anda akan mendapatkan point tersebut, selain itu anda juga akan mendapatkan point jika pengguna lain melakukan pembelian Individual License Millealab dengan menggunakan Referral Code yang anda milikki, dan anda juga akan mendapatkan point tambahan ketika anda memberikan ulasan terhadap Website Millealab Individual License.'
          },
          {
            id:'04',
            status:'PoinReward',
            question:'Bagaimana cara saya supaya mendapatkan voucher diskon?',
            answer:'Setelah anda berhasil mengumpulkan poin sesuai capaian level nya, anda dapat menukarkan point anda sesuai dengan jumlah point yang anda miliki'
          },
          {
            id:'05',
            status:'PoinReward',
            question:'Bagaimana cara Reedem Point yang anda miliki ?',
            answer:'Pilih menu point dan reward, lalu scroll ke bawah mencari menur Loyalti Program, kemudian silahkan pilih redeem point yang sesuai dengan point yang anda miliki'
          },
            {
            id:'06',
            status:'PoinReward',
            question:'Bagaimana cara menggunakan voucher diskon yang telah di dapatkan dari hasil Reedem point ?',
            answer:'Lakukan purchase MilleaLab Individual License, lalu pilih voucher yang sudah anda miliki untuk mendapatkan diskon dari hasil point yang telah kamu reedem.'
          },
     
    ],
    allInvoice:[],
    allHistoryPoint:[],
    allAvailableReward:[],

}

const mutations = {
    setAllInvoice(state,{name,value}){
      state[name] = value
    },
    setAllHistoryPoint(state,{name,value}){
      state[name] = value
    },
    setAllAvailableReward(state,{name,value}){
      state[name] = value
    }
}

const actions ={
    
    getValueAllInvoice:({commit})=>{
      // commit('setValueLoading',{name:'isLoading',value:true})
      // const store = useStore()
      // let dataCust = await store.getters.getCustomerDetail
      // console.log(dataCust)
       Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getAllInvoices`,{
        email:Auth.state.user.email
        })
        .then((res)=>{
            // console.log(res.data)
            let reverse = res.data.reverse()
            commit('setAllInvoice',{name:'allInvoice',value:reverse})
            // allPayment.value = reverse
            return true
        }).catch((err)=>{
            console.log(err)
            return false
        }) 
    },
    getValueAllHistoryPoint:({commit})=>{
      Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getPointHistory`,{
          uid:Auth.state.user.id
      })
      .then((res)=>{
        // console.log(res.data)
          let reverse = res.data.reverse()
          // log(reverse)
          // allTask.value = reverse
          commit('setAllHistoryPoint',{name:'allHistoryPoint',value:reverse})
          return true
      }).catch((err)=>{
          console.log(err)
          return false
      })
    },
    getValueAvailableReward:({commit})=>{
       Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserAvailableRewards`,{
        uid:Auth.state.user.id
    })
    .then((res)=>{
        // console.log(res.data)
        commit('setAllAvailableReward',{name:'allAvailableReward',value:res.data})
        return true
    }).catch((err)=>{
        console.log(err)
        return false
    })
    }

}

const getters = {
    
    getAllArrayFaq:(state)=>{
        return state.allArrayFaq
    },
    getAllInvoice:(state)=>{
      return state.allInvoice
    },
    getAllHistoryPoint:(state)=>{
      return state.allHistoryPoint
    },
    getAllAvailableReward:(state)=>{
      return state.allAvailableReward
    }
}

const namespaced = false

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
}