<template>
    <div class="container-home-failed">
        <SideHeader/>
        <div class="container-right-failed">
            <div class="main-container-right-failed">
                <!-- <TopHeaderLogin/> -->
                <div class="payment-success-page">
                    <!-- <i class="fa-solid fa-xmark-large icon-x" ></i> -->
                    <i class="fa-solid fa-x icon-x"></i>
                    <p>Oh no, your payment failed</p>
                    <p>Your Transaction has failed due to some payment expired. Please try again</p>
                    <div class="box-btn-success">
                        <a href="/" class="btn-back-home">
                            Home
                        </a>
                        <a href="/history" class="btn-history">
                            View Order
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SideHeader from '@/components/Header/SideHeader.vue'
import {ref,onMounted} from 'vue'
import {useRouter} from 'vue-router'
import Axios from 'axios'

// import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'

export default {
    name:'FailedPaymentView',
    components:{
        SideHeader,
        // TopHeaderLogin
    },
    setup(){
        const route = useRouter()
        let params = ref('')

        const fetchingData=()=>{
            let findParams = route.currentRoute.value.params.invoice
            params.value = findParams
            // console.log(params.value, 'ini params page')
            var data = JSON.stringify({
            "invoice_id": findParams
            });
            var config = {
            method: 'post',
            url: 'https://us-central1-millea-lab.cloudfunctions.net/web/api/getInvoice',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };
        Axios(config).then((res)=>{
            // console.log(res.data)
            if(res.data.status === 'EXPIRED'){
                // console.log('expired')
                
            }else {
                // console.log('data bukan expired. kembali ke home seharusnyaroute.')
            }
            
        }).catch((err)=>{
            console.log(err)
            if(err){
                route.push('/')
            }
        })

        }
        onMounted(()=>{
            fetchingData()
        })

        return {
           params
        }
    }
}
</script>


<style lang="scss">
    @import '../Styles/failed.scss'
</style>
