<template>
   <!-- MODALS ADD A Download Invoice -->
     <div class="modal  modal-downlad-invoice fade" id="modalDownloadInvoice" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" @click.self="closeModal()" >
        <div class="modal-dialog modal-dialog-centered .modal-lg">
            <div class="modal-content modal-content-download">
                <div class="modal-header">
                    <div class="box-left-header-inv">
                        <div class="card-img">
                            <img src="../assets/logo-millealab.png" alt="">
                        </div>
                        <p>+62 853-2000-2987</p>
                        <p>contact@millealab.com</p>
                        <p>www.millealab.com</p>
                    </div>
                    <div class="box-center-header-inv">
                        <p>INVOICE</p>
                        <p>{{dataDownload.external_id}}</p>
                    </div>
                    <div class="box-right-header-inv">
                   
                        <p>Jl. Kemang Barat 11ABC, <br> Mampang Prapatan,<br> Jakarta Selatan,<br> Indonesia 12730</p>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="box-top-body-inv">
                        <div class="body-left-inv">
                            <p>
                                Billed to,<br>
                                <span>
                                    {{dataDownload.customer_name}}
                                </span><br>
                                <span v-if="dataDownload.customer_phone !== 'null'">
                                    ({{dataDownload.customer_phone}})
                                </span><br>
                                <span>
                                    {{dataDownload.customer_email}}
                                </span>
                            </p>
                            <!-- <p>{{dataDownload.items}}d</p> -->
                        </div>
                        <div class="body-right-inv">
                            <div class="body-right-top-inv">
                                <p>Invoice Number</p>
                                <p>{{dataDownload.id}}</p>
                            </div>
                            <div class="body-right-bot-inv">
                                <div class="card-right-bot">
                                    <div class="card-item-bot">
                                        <p>Referral Code</p>
                                        <p v-if="dataDownload.referral_code !== null">{{referral_code}}</p>
                                        <p v-else>-</p>
                                    </div>
                                     <div class="card-item-bot">
                                        <p>Invoice Date</p>
                                        <p>{{dataDownload.created_at}}</p>        
                                    </div>
                                </div>
                                <div class="card-right-bot">
                                     <div class="card-item-bot-2">
                                      
                                         <p>Invoice of(IDR)</p>
                                        <p id="invoice_idr">{{dataDownload.currency}} {{dataDownload.subtotal}}</p>   
                                    </div>
                                     <div class="card-item-bot-2">
                                        <p>Payment Date</p>
                                        <p>{{dataDownload.paid_at}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="product-card">
                        <table id="id_product">
                            <tr>
                                <th>ITEM DETAILS</th>
                                <th>QTY</th>
                                <th>RATE</th>
                                <th>AMOUNT</th>
                            </tr>
                            <tr>
                                <td>
                                    {{dataDownload.items}}
                                    <!-- <p>Item Description</p>                                 -->
                                </td>
                                <td>{{dataDownload.total_month}}</td>
                                <td> {{dataDownload.currency}} {{dataDownload.base_price}}</td>
                                <td> {{dataDownload.currency}} {{dataDownload.total_price}}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="detail-price-card">
                        <div class="detail-left-card">
                            <p>Payment Details</p>
                            <p>Payment Method:</p>
                            <p>{{dataDownload.payment_channel}}</p>
                            <p>Merchant Name:</p>
                            <p>PT. CITRA WARHANA TEKNOLOGI</p>
                        </div>
                        <div class="detail-right-card">
                            <div class="card-detail-price">
                                <p>Total</p>
                                <p>{{dataDownload.currency}} {{dataDownload.total_price}}</p>
                            </div>
                            <div class="card-detail-price">
                                <p>Voucher({{dataDownload.level}} {{dataDownload.discount}})</p>
                                <p>{{dataDownload.currency}} {{dataDownload.discount_price}}</p>
                            </div>
                            <div class="card-detail-price-border">
                                <p>Tax(11%)</p>
                                <p>{{dataDownload.currency}} {{dataDownload.vat_price}}</p>
                            </div>
                              <div class="card-detail-price">
                                <p>Subtotal</p>
                                <p>{{dataDownload.currency}} {{dataDownload.actual_price}}</p>
                            </div>
                             <div class="card-detail-price">
                                <p>Rounding off</p>
                                <p>{{dataDownload.currency}} {{dataDownload.rounded_off}}</p>
                            </div>
                            <div class="card-total-price">
                                <p id="grand_total">Grand Total</p>
                                <p id="grand_total">{{dataDownload.currency}} {{dataDownload.subtotal}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="footer-invoice">
                        
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <!-- MODALS ADD A Download Invoice -->
    <!-- Modals Add Review -->
    <div class="modal  modal-content-review fade" id="modalAddReview" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
        <div class="modal-dialog modal-dialog-centered .modal-lg">
            <div class="modal-content modal-content-review">
                <div class="modal-header">
                    <div class="box-user-img">
                        <div class="card-img-review">
                            <img :src="`${dataCustomer.profilePicture}`" alt="" :load="log(dataDownload.profilePicture)">
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="main-body-write-review">
                        <div class="card-name-input-write">
                            <p>Name</p>
                            <input type="text" v-model="customerName" disabled>
                        </div>
                        <div class="card-name-input-write">
                            <p>Review <span>(max. 100 characters)</span> </p>
                            <textarea type="text" class="area-review" maxlength="100"  v-model="newReview" @change="onAddReview" autofocus> </textarea>
                        </div>
                        <div class="box-btn-review">
                            <div class="btn-review" @click="onSimpanReview" data-bs-dismiss="modal">
                                SEND
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="new-body-review">
                </div> -->
            </div>
        </div>
    </div>
    <!-- Modals Add Review -->
    <div class="modal-backdrop fade show" id="backdrop"  style="display: none;" ></div>

    <div class="container-home-history">
        <SideHeader/>
        <div class="container-right-history">
            <div class="main-container-right-history">
                <TopHeaderLogin/>
                 <div class="box-stats-history">
                    <div class="poin-left-profile">
                        <div class="coin-card">
                            <p>
                                <img src="../assets/PoinReward/coinStar.svg" alt="">
                                Points
                            </p>
                            <!-- <p>Tara Ingin Makan pecel ayam buvesti</p> -->
                        </div>
                        <p>{{dataCustomer.points}}</p>
                    </div>
                    <div class="poin-right-profile">
                        <div class="coin-card">
                            <p>
                                <img src="../assets/PoinReward/medalLevel.svg" alt="">
                                Level
                            </p>
                            <!-- <p>Tara Ingin Makan pecel ayam buvesti</p> -->
                        </div>
                        <p>{{dataCustomer.level}}</p>
                    </div>
                </div>
                <div class="history-table-box">
                    <div class="table-header">
                        <h1>Task History</h1>
                    </div>
                    <div class="table-body">
                        <div class="table-details">
                            <div class="box-loading-bs" v-if="isLoadingTask === true && allTask.length === 0">
                                <div class="spinner-border text-primary " role="status" >
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <table v-else-if="allTask.length > 0 && isLoadingPayment === false" >
                                <tr class="except">
                                    <th class="no"><p>No</p></th>
                                    <th class="date"><p>Task</p></th>
                                    <th class="name"><p>Point</p></th>
                                    <th class="email"><p>Date</p></th>
                                </tr>
                                <tr v-for="(dataRef,id) in allTask" :key="dataRef.id" >
                                    <td>{{id+1}}</td>
                                    <td>{{dataRef.task}}</td>
                                    <td>{{dataRef.progress}}</td>
                                    <td>{{dataRef.date}}</td>
                                </tr>
                            </table>
                            <div v-else class="history-unknown">
                                <img src="../assets/noReward.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-table-box">
                    <div class="table-header">
                        <h1>Payment History</h1>
                    </div>
                    <div class="table-body">
                        <div class="table-details">
                            <div class="box-loading-bs" v-if="isLoadingPayment === true && allPayment.length === 0">
                                <div class="spinner-border text-primary " role="status" >
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <table v-else-if="allPayment.length > 0 && isLoadingPayment === false" >
                                <tr class="except">
                                    <th class="no"><p>No</p></th>
                                    <th class="payment"><p>Payment</p></th>
                                    <th class="status"><p>Status</p></th> 
                                    <th class="option"><p>Date</p></th>
                                    <th class="amount"><p>Amount</p></th>
                                    <th class="status"><p>Review</p></th>
                                    <th class="status"><p>Invoice</p></th>
                                    <!-- <th class="option">Transaksi</th> -->
                                </tr>
                                <tr v-for="(dataRef,id) in allPayment" :key="dataRef.id" @click="onSetSelected(dataRef)"  >
                                    <td>{{id+1}}</td> 
                                    <td>{{dataRef.description}} {{dataRef.external_id}}</td>
                                    <td>
                                        <div class="btn-status-history" v-if="dataRef.status === 'PAID'  || dataRef.status === 'SETTLED'">
                                            <!-- <i class="fa-solid fa-check"></i> -->
                                            PAID
                                        </div>
                                            <!-- PENDING -->
                                         <a :href="dataRef.url" class="btn-status-history" target="_blank" v-if="dataRef.status === 'PENDING' ">
                                            PAY NOW
                                        </a>
                                        <div class="btn-status-history" v-if="dataRef.status === 'EXPIRED' ">
                                            <!-- <i class="fa-solid fa-xmark"></i> -->
                                            EXPIRED
                                        </div>
                                    </td>
                                    <td>{{dataRef.created}}</td>
                                    <td v-if="dataRef.amount !== undefined">{{dataRef.amount}}</td>
                                    <td v-else>Unknown</td>
                                     <td>
                                        
                                        <div class="btn-download-history" data-bs-toggle="modal" data-bs-target="#modalAddReview" @click="setReviewId(dataRef.external_id)" v-if="dataRef.has_reviewed === 'Unreviewed' && dataRef.status === 'PAID' ||dataRef.has_reviewed === 'Unreviewed' && dataRef.status === 'SETTLED' ">
                                            Review
                                        </div>
                                        <p v-else-if="dataRef.has_reviewed == 'Approved' && dataRef.status == 'PAID' || dataRef.has_reviewed == 'Approved' && dataRef.status == 'SETTLED' ">Approved</p> 
                                        <p v-else-if="dataRef.has_reviewed == 'Rejected' && dataRef.status == 'PAID' ||  dataRef.has_reviewed == 'Rejected' && dataRef.status == 'SETTLED' "> Rejected</p>
                                        <p v-else-if="dataRef.has_reviewed == 'Unreviewed' && dataRef.status =='PENDING' ">-</p> 
                                        <p v-else-if="dataRef.has_reviewed == 'Unreviewed' && dataRef.status =='EXPIRED' ">-</p>
                                        <p v-else-if="dataRef.has_reviewed == 'Pending' && dataRef.status =='PAID' || dataRef.has_reviewed == 'Pending' &&  dataRef.status === 'SETTLED' ">Pending Review</p> 
                                        <p v-else-if="dataRef.has_reviewed == 'Rejected' && dataRef.status =='PENDING' ">Rejected</p> 
                                        <!-- <p v-else-if="dataRef.has_reviewed == 'Unreviewed' && dataRef.status =='PAID' || dataRef.status === 'SETTLED' "></p>  -->
                                        <!-- <p v-if="dataRef.has_reviewed == 'Pending' && dataRef.status == 'PENDING'">Pending</p>  -->
                                        <!-- <p v-else>else</p> -->
                                    </td>
                                    <td>
                                        <!-- <div class="btn-download-history" @click="onDownloadPdf(dataRef.external_id)" >
                                            Download
                                        </div> -->
                                        <!-- data-bs-toggle="modal" data-bs-target="#modalDownloadInvoice" -->
                                        <div type="button" v-if="dataRef.status =='PAID' || dataRef.status == 'SETTLED' && isLoadingDownload === false" class="btn-download-history" @click="changeIdActive(dataRef.external_id)">
                                            <p>Download</p>
                                        </div>
                                        <div type="button" v-if="dataRef.status =='PAID' || dataRef.status == 'SETTLED' && isLoadingDownload === true" class="btn-download-history" @click="changeIdActive(dataRef.external_id)" >
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </td>
                                    <!-- <td><a  target="_blank">Detail Transaksi</a></td> -->
                                </tr>
                            </table>
                            <div v-else class="history-unknown">
                                <img src="../assets/noReward.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="history-table-box">
                    <div class="table-header">
                        <h1>Available Rewards</h1>
                    </div>
                    <div class="table-body">
                        <div class="table-details">
                            <div class="box-loading-bs" v-if="isLoadingReward === true && availableReward.length === 0">
                                <div class="spinner-border text-primary " role="status" >
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <table v-else-if="availableReward.length > 0 && isLoadingReward === false">
                                <tr class="except">
                                    <th class="no"><p>No</p></th>
                                    <th class="detail"><p>Detail Reward</p></th>
                                    <th class="discount"><p>Discount</p></th>
                                    <th class="quota"><p>Quota</p></th>
                                </tr>
                                <tr v-for="(dataRef,id) in availableReward" :key="dataRef.id" >
                                    <td>{{id+1}}</td>
                                    <td>{{dataRef.name}}</td>
                                    <td>{{dataRef.desc}}</td>
                                    <td>{{dataRef.quota}}</td>
                                </tr>
                            </table>
                            <div v-else class="history-unknown">
                                <img src="../assets/noReward.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import SideHeader from '@/components/Header/SideHeader.vue'
import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'
import {ref,onMounted,watch,onBeforeMount} from 'vue'
import {useStore} from 'vuex'
import Axios from 'axios'
import { jsPDF } from "jspdf";

import html2canvas from 'html2canvas'
import { createToast } from 'mosha-vue-toastify';
export default {
    name:'HistoryView',
    components:{
        SideHeader,
        TopHeaderLogin
    },
    setup(){
    const store = useStore()
    let allPayment = ref(store.getters.getAllInvoice)
    let allTask = ref(store.getters.getAllHistoryPoint)
    let availableReward = ref(store.getters.getAllAvailableReward)
    let isLoadingPayment = ref(true)
    let isLoadingTask = ref(true)
    let isLoadingReward = ref(true)
    let dataCustomer = ref([])
    let selectedItem = ref([])
    let active_id_invoice = ref('')
    let newReview = ref('')
    let customerName = ref('')
    let external_id = ref('') 
    let isLoadingDownload = ref(false)

    let dataDownload = ref({
            amount:10000,
            customer:{
                given_names:'bayu darmawan',
                email:'darmawanbayu1@gmail.com',
                mobile_number:'087785192296'
            },
            id:'1231233213',
            payment_channel:'indo',
            created:'1231313',
            paid_at:'1231321321'
        }
    
    )
    let total_month_invoice = ref(0)
        
    // let allArrayReview = reactive ([

    // ])

    const changeIdActive=(external_id)=>{
        isLoadingDownload.value = true
        active_id_invoice.value = external_id
         Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getInvoice`,{
            "invoice_id":external_id
        }).then((res)=>{
            // console.log(res.data)
            dataDownload.value = res.data
            // setTimeout(()=>{
                // console.log('397 jalan')
                document.getElementById("backdrop").style.display = "block"
                document.getElementById("modalDownloadInvoice").style.display = "block"
                document.getElementById("modalDownloadInvoice").className += "show"
                onDownloadPdf(external_id)

            // },2000)

        }).catch((err)=>{
            console.log(err)
            isLoadingDownload.value = false
               createToast({
                    title: 'Error',
                    description: 'Refresh your browser, and try again'
                    },
                    {
                    // timeout: 3000,
                    type: 'danger',
                    transition: 'bounce',
                    position:'top-center'
                })
        })
    }
    const onDownloadPdf=()=>{
        // console.log(external_id,'jalan')
        // Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getInvoice`,{
        //     "invoice_id":external_id
        // }).then((res)=>{
        //     console.log(res.data)
        //     dataDownload.value = res.data
        // }).catch((err)=>{
        //     console.log(err)
        // })
            window.html2canvas = html2canvas
            var doc = new jsPDF();
            doc.html(document.querySelector('.modal-downlad-invoice'),{
                callback:function(pdf){
                    pdf.save('Invoice Individual License.pdf')
                },
                x:10,
                y:10,
                width:180,
                autoPaging:true,
                windowWidth:750
            })
            isLoadingDownload.value = false
        
    }
    function closeModal() {
        // console.log('close modal jalanlet ')
        document.getElementById("backdrop").style.display = "none"
        document.getElementById("modalDownloadInvoice").style.display = "none"
        document.getElementById("modalDownloadInvoice").className += document.getElementById("modalDownloadInvoice").className.replace("show", "")
    }
    // Get the modal
    var modal = document.getElementById('modalDownloadInvoice');
    
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        closeModal()
      }
    }

    const onSimpanReview=()=>{


        if(newReview.value.length > 5){
            // let objToPush= {
            // img:[require("../assets/Header/testing_pp.jpeg")],
            // nama:`${customerName.value}`,
            // lastUpdate : '1 yrs Ago',
            // review:newReview.value
            // }
            // console.log(objToPush)
            // // allArrayReview.push(objToPush)
            // alert('jalan')
            
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/submitReview`,{
                "uid":dataCustomer.value.id,
                "name":dataCustomer.value.name,
                "review":newReview.value,
                "invoice_id":external_id.value
            // eslint-disable-next-line no-unused-vars
            }).then((res)=>{
                createToast({
                    title: 'Success',
                    description: 'Review Berhasil!'
                    },
                    {
                    // timeout: 3000,
                    type: 'success',
                    transition: 'bounce',
                    position:'top-center'
                })
                window.location.reload()
              
            }).catch((err)=>{
                console.log(err.message)
            })
            // console.log(dataCustomer.value)
        } 
    }

    watch(async()=>store.getters.getAllInvoice, function() {
        allPayment.value = store.getters.getAllInvoice
        isLoadingPayment.value = false
    });

    watch(async()=>store.getters.getAllInvoice, function() {
        allPayment.value = store.getters.getAllInvoice
        isLoadingPayment.value = false
    });
    watch(async()=>store.getters.getAllHistoryPoint, function() {
        allTask.value = store.getters.getAllHistoryPoint
        isLoadingTask.value = false
    });
    watch(async()=>store.getters.getAllAvailableReward, function() {
        availableReward.value = store.getters.getAllAvailableReward
        isLoadingReward.value = false
    });

    const fetchingLogin=async()=>{
        // store.dispatch('getValueLogin')
        
        let dataCust = await store.getters.getCustomerDetail
        // console.log(dataCust)
        dataCustomer.value = dataCust
        customerName.value = dataCust.name
        allPayment.value = await store.getters.getAllInvoice
        allTask.value = await store.getters.getAllHistoryPoint
        availableReward.value =await store.getters.getAllAvailableReward
        // isLoadingPayment.value = false
        // store.dispatch('getValueLoading',{value:false})
      

    }

    const setReviewId=(params)=>{
       
        external_id.value = params
    }

    const onSetSelected=(value)=>{
        selectedItem.value = value
    }
    onMounted(()=>{
        // console.log('on mounted jala 416')
        // fetchingLogin()
    })
    onBeforeMount(()=>{
        // console.log('before mounted jalan')
        // store.dispatch('getValueLoading',{value:true})
        fetchingLogin()
    })


    const log=(params)=>{
        console.log(params)
        // console.log(dataCustomer.value)
    }
        return {
           allTask,
           availableReward,
           allPayment,
           fetchingLogin,
           selectedItem,
           onSetSelected,
           onDownloadPdf,
           dataCustomer,
           onSimpanReview,
           newReview,
           customerName,
           setReviewId,
           external_id,
           active_id_invoice,
           changeIdActive,
           dataDownload,
           total_month_invoice,
           isLoadingPayment,
           isLoadingReward,
           isLoadingTask,
           log,
           closeModal,
           isLoadingDownload

        } 
    }
}
</script>


<style lang="scss">
    @import '../Styles/History.scss';
    @import '../Styles/Mobile/History.scss';
</style>
