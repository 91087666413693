import { createStore } from 'vuex'
import Auth from './auth'
import Fetching from './fetching'


export default createStore({
  modules:{
    Auth,
    Fetching
  }
})
// export default createStore({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })
