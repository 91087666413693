// import Axios form 'axios'
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
// import AuthDataService from '../Services/auth.services'
// import {auth,provider} from '../../firebase-config'
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase-config";
import Axios from "axios";
// import { setPersistence } from 'firebase/auth';
// import Axios from 'axios'

const state = {
  count: 0,
  allProduct: [],
  allProducts: [],
  isLogin: false,
  customerName: "",
  user: {
    name: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    referral_code: "",
    points: 0,
    totalReferral: 0,
    address: "",
    zipCode: "",
    gender: "",
    company: "",
    jobTitle: "",
    placeOfBirth: "",
    dateOfBirth: "",
    individualLicense: 0,
    vrCardboard: 0,
    level: "",
    referralHistory: [],
    profilePicture: "",
  },
  allNotification: null,
  allReviews: [],
  userReward: [],
  isLoading: true,
};

const mutations = {
  setValueLogin(state, { name, value }) {
    // state[name] = value
    state[name] = value;
    // console.log(state[name])
  },
  setValueLogout(state, { name, value }) {
    state[name] = value;
  },
  setValueAllNotification(state, { name, value }) {
    state[name] = value;
  },
  setPushNotification(state, { name, value }) {
    state[name].push(value);
  },
  setValueReview(state, { name, value }) {
    state[name] = value;
    // console.log(value)
  },
  setValueLoading(state, { name, value }) {
    state[name] = value;
  },
  setValueReward(state, { name, value }) {
    state[name] = value;
  },
};

const actions = {
  getValueLoginWithGoogle: ({ commit }, valueParams) => {
    commit("setValueLogin", { name: "isLogin", value: true });
    commit("setValueLogin", { name: "user", value: valueParams });
    // console.log(valueParams,' ini value params')
  },
  newGetValue: ({ commit }) => {
    // console.log(commit)
  },
  getValueLogin: async ({ commit }) => {
    let idStorage = localStorage.getItem("idStorage");
    // console.log('get value login auth js jalan', idStorage)
    if (idStorage !== null && idStorage !== undefined) {
      //  kalau ID Storage ada dan ternyata di Firebase databenar maka auto login
      // commit('setValueLoading',{name:'isLoading',value:true})
      let collectionID = "users";
      let docRef = doc(db, collectionID, idStorage);
      let docSnapshot = await getDoc(docRef);
      let user = docSnapshot.data();
      //    console.log(user)
      // eslint-disable-next-line no-unused-vars
      let response = await Promise.all([
        // get User Data
        Axios.post(
          `https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserData`,
          {
            uid: idStorage,
          }
        )
          .then((res) => {
            // console.log(res.data)
            // console.log(user)
            let current_level = "";
            let current_point = user.point;
            if (current_point < 100) {
              current_level = "-";
            } else if (current_point >= 100 && current_point < 200) {
              current_level = "Bronze";
            } else if (current_point >= 200 && current_point < 999) {
              current_level = "Silver";
            } else if (current_point >= 1000 && current_point < 1999) {
              current_level = "Gold";
            } else if (current_point >= 2000) {
              current_level = "Platinum";
            }
            let userFromAPI = res.data[0];
            if (user !== undefined) {
              if (user.userDetails !== undefined) {
                let sendToFirebase = {
                  id: user.id,
                  name: user.name,
                  email: user.email,
                  phone: user.phone,
                  // created_at: Timestamp.fromDate(new Date(user.metadata.creationTime)),
                  // last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
                  referral_code: userFromAPI.referral_code,
                  points: user.point,
                  totalReferral:
                    user.referral_history !== undefined
                      ? user.referral_history.length
                      : 0,
                  address: user.userDetails.address,
                  zipCode: user.userDetails.zip_code,
                  gender: user.userDetails.gender,
                  company: user.userDetails.company,
                  jobTitle: user.userDetails.job_title,
                  placeOfBirth: user.userDetails.place_of_birth,
                  dateOfBirth: user.userDetails.date_of_birth,
                  individualLicense: userFromAPI.total_month,
                  vrCardboard: 0,
                  level: current_level,
                  referralHistory: user.referral_history,
                  profilePicture:
                    user.profile_picture !== undefined
                      ? user.profile_picture
                      : "",
                };
                // console.log(sendToFirebase)

                commit("setValueLogin", { name: "isLogin", value: true });
                commit("setValueLogin", {
                  name: "user",
                  value: sendToFirebase,
                });
                // commit('setValueLoading',{name:'isLoading',value:false})
              } else {
                // console.log('masuk ke else user details !== undefined')
                let sendToFirebase = {
                  id: user.id,
                  name: user.name,
                  email: user.email,
                  phone: user.phone,
                  referral_code: userFromAPI.referral_code,
                  points: user.point,
                  totalReferral:
                    user.referral_history !== undefined
                      ? user.referral_history.length
                      : 0,
                  address: user.address,
                  zipCode: user.userDetails.zip_code,
                  gender: user.gender,
                  company: user.company,
                  jobTitle: user.job_title,
                  placeOfBirth: user.place_of_birth,
                  dateOfBirth: user.date_of_birth,
                  individualLicense: userFromAPI.total_month,
                  vrCardboard: 0,
                  level: current_level,
                  referralHistory: user.referral_history,
                };
                // console.log(sendToFirebase)

                commit("setValueLogin", { name: "isLogin", value: true });
                commit("setValueLogin", {
                  name: "user",
                  value: sendToFirebase,
                });
                // commit('setValueLoading',{name:'isLoading',value:false})
              }

              return true;
            } else {
              // console.log('masuk ke else berarti id storage ngasal')
              localStorage.removeItem("idStorage");
              // commit('setValueLoading',{name:'isLoading',value:false})

              return false;
            }
          })
          .catch((err) => {
            console.log(err);
          }),
      ]);
    } else {
      // gagal login, id storage tidak ada atau eamng blm login
      // console.log('masuk ke else 157 auth js')

      commit("setValueLogin", { name: "isLogin", value: false });
      // commit('setValueLoading',{name:'isLoading',value:false})
    }
  },
  getValueLogout: ({ commit }) => {
    localStorage.removeItem("idStorage");
    commit("setValueLogout", { name: "isLogin", value: false });
    createToast(
      {
        title: "Success",
        description: "Berhasil Logout",
      },
      {
        timeout: 2000,
        type: "success",
        transition: "bounce",
        position: "top-center",
      }
    );
  },
  getValueAllNotification: async ({ commit }) => {
    let idStorage = localStorage.getItem("idStorage");
    // get all Notification
    return Axios.post(
      `https://us-central1-millea-lab.cloudfunctions.net/web/api/getNotificationList`,
      {
        uid: idStorage,
      }
    )
      .then((res) => {
        let allNotification = res.data;
        commit("setValueAllNotification", {
          name: "allNotification",
          value: allNotification,
        });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  },
  getPushValueNotification: ({ commit }, valueParams) => {
    commit("setPushNotification", {
      name: "allNotification",
      value: valueParams,
    });
  },
  getValueLoading: ({ commit }, valueParams) => {
    // console.log(valueParams)
    // commit('setValueLogin',{name:'isLogin',value:false})
    commit("setValueLoading", { name: "isLoading", value: valueParams.value });
    return true;
  },
  getValueIsLogin: ({ commit }, valueParams) => {
    commit("setValueLogin", { name: "isLogin", value: valueParams.value });
  },
  getValueReview: async ({ commit }) => {
    return Axios.post(
      `https://us-central1-millea-lab.cloudfunctions.net/web/api/getallReviews`
    )
      .then((res) => {
        let allReview = res.data;
        // console.log(allReview)
        commit("setValueReview", { name: "allReviews", value: allReview });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  },
  getValueReward: ({ commit }) => {
    let idStorage = localStorage.getItem("idStorage");
    Axios.post(
      `https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserAvailableRewards`,
      {
        uid: idStorage,
      }
    )
      .then((res) => {
        commit("setValueReward", { name: "userReward", value: res.data });
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
};

const getters = {
  getIsLogin: (state) => {
    // console.log('get is login jalan')
    return state.isLogin;
  },
  getCustomerName: (state) => {
    return state.customerName;
  },
  getCustomerDetail: (state) => {
    return state.user;
  },
  getAllNotification: (state) => {
    return state.allNotification;
  },
  getAllReview: (state) => {
    return state.allReviews;
  },
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getUserReward: (state) => {
    return state.userReward;
  },
};

const namespaced = false;

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
