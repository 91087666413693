import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RegisterView from '../views/RegisterView.vue'
import LoginView from '../views/LoginView.vue'
import ForgotView from '../views/ForgotView.vue'
import CheckView from '../views/CheckMailView.vue'
import NewPasswordView from '../views/NewPasswordView.vue'
import SucceedView from '../views/SucceedView.vue'
import PoinRewardView from '../views/PoinReward.vue'
import NewsView from '../views/NewsView.vue'
import ReferralView from '../views/ReferralCode.vue'
import HelpView from '../views/HelpView.vue'
import store from '../store/index'
import HistoryView from '../views/HistoryView.vue'
import SuccessPayment from '../views/SuccessPayment.vue'
import FailedPayment from '../views/FailedPayment.vue'
import ProfileView from '../views/ProfileView.vue'
import { getAuth, onAuthStateChanged} from 'firebase/auth'
// import {useLoading} from 'vue-loading-overlay'
// console.log(store
//   )

// const $loading = useLoading()

  const checkUserLogin =async (to,from,next)=>{

    // let idStorage  = localStorage.getItem('idStorage')
    const auth = getAuth()
    onAuthStateChanged(auth,async(user)=>{
      if(user){
        await store.dispatch('getValueLoading',{value:true}) // start loading page      
        await store.dispatch('getValueLogin')
        if(to.path === '/history'){

            // await store.dispatch('getValueLoading',{value:true}) // loading page is true, fetching all invoice etc
            await store.dispatch('getValueAllInvoice'),
            await store.dispatch('getValueAllHistoryPoint'),
            await store.dispatch('getValueAvailableReward')
            
            next()
          }else {
            next()
          }      
          await store.dispatch('getValueLoading',{value:false}) // stop loading page
      }else {
        // console.log('masuk ke else karna gaada id storage ')
        await store.dispatch('getValueLoading',{value:false}) // stop loading
        await store.dispatch('getValueisLogin',{value:false}) // change isLogin into false. logout page
        if(to.path === '/poin-reward'){
          // console.log('masuk ke if')
          // next({name:'home'})
          window.location.href="/"
          next()
          // router.push({ name: 'poin-reward', query: { destination: to.fullPath } })
        }else if (to.path === '/checkMail'){
          window.location.href="/"
          next()
        }else if (to.path === '/newPassword'){
          window.location.href="/"
          next()
        // }else if (to.path === '/referral'){
        //   window.location.href="/"
        //   next()
        }else if (to.path === '/history'){
          window.location.href="/"
          next()
        }else if (to.path === '/paid'){
          window.location.href="/"
          next()
        }else if (to.path === '/succeed'){
          window.location.href="/"
          next()
        }else if (to.path === '/profile'){
          window.location.href="/"
          next()
        }
        else {
          next()
        }
      }
    })
    
  }

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
      // beforeEnter (to, from, next) {
      //   checkUserAuth(to, from, next)
      // }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
      // beforeEnter (to, from, next) {
      //   checkUserAuth(to, from, next)
      // }
  },
  {
    
    path: '/poin-reward',
    name: 'poin-reward',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:PoinRewardView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: ForgotView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/checkMail',
    name: 'checkMail',
    component: CheckView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/newPassword',
    name: 'newPassword',
    component: NewPasswordView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/succeed',
    name: 'succeed',
    component: SucceedView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/referral',
    name: 'referral',
    component: ReferralView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  {
    path: '/news/:title',
    name: 'news',
    component: NewsView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
    // params: true
  },
  {
    path: '/paid/:invoice',
    name: 'paid',
    component: SuccessPayment,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
    // params: true
  },
  {
    path: '/failed/:invoice',
    name: 'failed',
    component: FailedPayment,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
    // params: true
  },
  {
    path:'/help',
    name:'help',
    component:HelpView,
    beforeEnter(to,from,next){
      checkUserLogin(to,from,next)
    }
  },
  // { path: '*', redirect: '/' }
]

// eslint-disable-next-line no-unused-vars


export const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router


