<template>
    <div class="container-check">
        <div class="box-picture">
            <!-- <img src="../assets/RegistLogin/CheckMail.svg" alt=""> -->
        </div>
        <div class="box-check">
            <div class="check-logo">
                
                <a href="/"><img src="../assets/RegistLogin/logo.svg" alt=""></a>
            </div>
            <div class="check-header">
                <div class="header-picture">
                    <img src="../assets/RegistLogin/otis.svg" alt="">   
                </div>
                <h1>Check your mail</h1>
                <p>We have sent a password recover instruction to your email.</p>
            </div>
            <div class="btn-check-box">
                <a href="mailto:" class="btn-check-blue">
                    <p>OPEN MAIL APP</p>
                </a>
                <a class="btn-check-white" href="/">
                    <p>I'll confirm later</p>
                </a>
            </div>
            <div class="resend-check-box" @click="resendConfirmation()">
                <p>Did not receive the email?
                    <a href="">Resend</a>
                     <!-- <a href="http://localhost:8080/newPassword">Resend</a> -->
                </p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'CheckMail',
  components: {
  },
  setup(){
      const resendConfirmation =()=>{
          alert('resend email done')
      }

      return {
          resendConfirmation
      }
  }
}
</script>

<style lang="scss">
    @import '../Styles/CheckMail.scss';
    @import '../Styles/Mobile/CheckMail.scss';
</style>