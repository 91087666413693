<template>
<!-- MODALS CONTENT CREATOR -->
  <div class="modal  modal-content-creator fade" id="modalContentCreator" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered .modal-lg	">
          <div class="modal-content ">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Download Center</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- <Datepicker v-model="date"  /> -->
                <div class="main-body-content-creator">
                    <div class="creator-card">
                        <div class="title-card">
                            <p>MilleaLab</p>
                            <p>Creator</p>
                        </div>
                        <div class="box-img-card">
                            <img src="../assets/Header/testing_pp.jpeg" alt="">
                        </div>
                        <div class="list-card">
                            <p>
                              <img src="../assets/checklist.svg" alt="">
                              Processor: setara intel i3/i5/i7 atau lebih
                            </p>
                            <p>
                              <img src="../assets/checklist.svg" alt="">
                              Sistem Operasi: Windows 8/10 - 64 bit
                            </p>
                            <p>
                              <img src="../assets/checklist.svg" alt="">
                              RAM: minimal 2GB atau lebih
                            </p>
                            <p>
                              <img src="../assets/checklist.svg" alt="">
                              Ruang Penyimpanan: minimal 2GB sampai 5GB
                            </p>
                            <p>
                              <img src="../assets/checklist.svg" alt="">
                              Kartu Grafis: internal atau External Graphic Card
                            </p>
                        </div>
                        <div class="box-btn-download">
                            <div class="btn-download">
                              DOWNLOAD
                            </div>    
                        </div>
                    </div>
                    <div class="creator-card">
                        <div class="title-card">
                              <p>MilleaLab</p>
                              <p>Viewer</p>
                          </div>
                          <div class="box-img-card">
                              <img src="../assets/Header/testing_pp.jpeg" alt="">
                          </div>
                          <div class="list-card">
                              <p>
                                <img src="../assets/checklist.svg" alt="">
                                Processor: setara  Hexa-Core 4x 1.4 GHz atau lebih
                              </p>
                              <p>
                                <img src="../assets/checklist.svg" alt="">
                                Sistem Operasi: minimal Android Lollipop
                              </p>
                              <p>
                                <img src="../assets/checklist.svg" alt="">
                                RAM: minimal 2GB atau lebih
                              </p>
                              <p>
                                <img src="../assets/checklist.svg" alt="">
                                Ruang Penyimpanan: minimal 500mb atau lebih
                              </p>
                              <p>
                                <img src="../assets/checklist.svg" alt="">
                                Kartu Grafis:minimal Adreno 510 atau lebih
                              </p>
                              <p>
                                <img src="../assets/checklist.svg" alt="">
                                Sensor: gyroscope atau accelerometer
                              </p>
                          </div>
                          <div class="box-btn-download">
                              <div class="btn-download">
                                DOWNLOAD
                              </div>    
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
          </div>
      </div>
  </div>
  <!-- MODALS CONTENT CREATOR -->

  <!-- MODALS PREMIUM LICENSE -->
  <div class="modal  modal-content-creator fade" id="modalPremiumLicense" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered .modal-lg">
          <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title title-premium" id="exampleModalLabel">Individual License</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- <Datepicker v-model="date"  /> -->
                <div class="main-body-premium-license">
                    <div class="status-license">
                        <div class="license-top">
                            <div class="box-bulat" :class="{'success-active' : stepLicense === 1}">
                                1
                            </div>
                            <div class="box-garis">

                            </div>
                            <div class="box-bulat" :class="{'success-active' : stepLicense === 2}">
                                2
                            </div>
                            <div class="box-garis">
                              
                            </div>
                            <div class="box-bulat" :class="{'success-active' : stepLicense === 3}">
                                3
                            </div>
                        </div>
                        <div class="license-bot">
                            <div class="box-bulat ">
                                <p>Profile</p>
                            </div>
                            <div class="box-garis">

                            </div>
                            <div class="box-bulat">
                                <p>Detail Purchase</p>
                            </div>
                            <div class="box-garis">
                              
                            </div>
                            <div class="box-bulat">
                                <p>Billing</p>
                            </div>
                        </div>
                    </div>
                    <div class="main-status-license" v-if="stepLicense === 1">
                        <div class="header-main-title">
                            <p>Profile</p>
                        </div>
                        <div class="header-main-input">
                          <div class="box-top-input">
                              <div class="box-input">
                                  <p>First Name</p>
                                  <input type="text">
                              </div>
                              <div class="box-input">
                                  <p>Last Name</p>
                                  <input type="text">
                              </div>
                          </div>
                          <div class="box-top-input">
                              <div class="box-input">
                                  <p>Email</p>
                                  <input type="text">
                              </div>
                              <div class="box-input">
                                  <p>Phone Number</p>
                                  <div class="input-card">
                                      <div class="number-country">
                                        <p>+62</p>
                                      </div>
                                      <input type="text">
                                  </div>
                              </div>
                          </div>
                          <div class="box-top-input">
                               <div class="box-input">
                                  <p>Referral Code</p>
                                  <div class="input-card-right">
                                      <input type="text">
                                      <div class="number-country">
                                        <p>Paste</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="box-btn-next">
                              <div class="btn-next" @click="nextStepLicense(2)">
                                Next
                              </div>
                          </div>
                            
                        </div>
                    </div>
                    <div class="main-status-license" v-if="stepLicense === 2">
                        <div class="header-main-title">
                            <p>Detail Purchase</p>
                        </div>
                        <div class="header-main-input-detail">
                          <div class="box-detail-left-poin-reward">
                              <div class="detail-left-card">
                                  <div class="small-detail-left-poin-reward">
                                      <div class="detail-purchase-card">
                                          <p>Name</p>
                                          <p>Tara Rahmadilla Adhi</p>
                                      </div>
                                      <div class="detail-purchase-card">
                                          <p>Phone Number</p>
                                          <p>+6287785192296</p>
                                      </div>
                                  </div>
                                  <div class="small-detail-right-poin-reward">
                                     <div class="detail-purchase-card">
                                          <p>Email</p>
                                          <p>tara.rahmadilla@gmail.com</p>
                                      </div>
                                      <div class="detail-purchase-card">
                                          <p>Referral Code</p>
                                          <p>T4R4L4p3R</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="box-detail-right">
                              <!-- <div class="individual-input">
                                  <p>Individual License</p>
                                  <p>{{totalQtyProduct}} Month</p>
                                  <p>$ {{hargaProductSatuan}}</p>
                              </div> -->
                              <div class="individual-input-2">
                                  <!-- <p>Option</p> -->
                                  <div class="individual-input-2-sm">
                                      <p>Individual License</p>
                                      <div class="card-plus-minus">
                                          <div class="box-minus" @click="kurangQty()">
                                              <img src="../assets/minus.svg" alt="">
                                          </div>
                                          <div class="box-result">
                                              <p id="total-product-qty">
                                                {{totalQtyProduct}}
                                              </p>
                                          </div>
                                          <div class="box-minus" @click="tambahQty()">
                                              <img src="../assets/plus.svg" alt="">
                                          </div>
                                      </div>
                                      <p>Month</p>
                                      <p>$ {{totalHargaProduct}}</p>
                                  </div>
                              </div>
                              <div class="individual-input-2">
                                  <p>Option</p>
                                  <div class="individual-input-2-sm">
                                      <p>VR Cardboard</p>
                                      <div class="card-plus-minus">
                                          <div class="box-minus" @click="kurangQty()">
                                              <img src="../assets/minus.svg" alt="">
                                          </div>
                                          <div class="box-result">
                                              <p id="total-product-qty">
                                                {{totalQtyProduct}}
                                              </p>
                                          </div>
                                          <div class="box-minus" @click="tambahQty()">
                                              <img src="../assets/plus.svg" alt="">
                                          </div>
                                      </div>
                                      <p>$ {{totalHargaProduct}}</p>
                                  </div>
                              </div>
                              <div class="total-price-detail">
                                  <p>Total</p>
                                  <p>${{totalHargaProduct}}</p>
                              </div>
                              
                              <div class="box-btn-next">
                                  <div class="btn-cancel" @click="nextStepLicense(1)">
                                    Back
                                  </div>
                                  <div class="btn-next" @click="nextStepLicense(3)">
                                    Next
                                  </div>
                              </div> 
                          </div>
                          
                            
                        </div>
                    </div>
                    <div class="main-status-license" v-if="stepLicense === 3">
                        <div class="header-main-billing">
                            <div class="billing-top">
                                <img src="../assets/logo.svg" alt="">
                                <div class="box-timer">
                                    <Countdown showLabels=false :showDays="false" :showHours="false"  :deadline="countdown1minute" class="countdowntime"
                                      mainFlipBackgroundColor="#004F98" secondFlipBackgroundColor='#004F98'
                                      mainColor="white" secondFlipColor="#ffffff"
                                    />
                                </div>
                            </div>
                            <div class="billing-mid">
                                <div class="bill-mid-left">
                                    <div class="payment-method-left">
                                        <p>Payment Method</p>
                                        <div class="box-list-card-payment">
                                            <Carousel :settings="settings" :breakpoints="breakpoints">
                                              <Slide v-for="slide in 10" :key="slide">
                                                <!-- <div class="carousel__item">{{ slide }}</div> -->
                                                <div class="card-payment">
                                                    <div class="form-check">
                                                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                                      <label class="form-check-label" for="flexRadioDefault1">
                                                        <img src="../assets/Alfamart.svg" alt="">
                                                      </label>
                                                    </div>
                                                </div>
                                              </Slide>
                                              <template #addons>
                                                <Navigation />
                                              </template>
                                            </Carousel>
                                        </div>
                                    </div>
                                    <div class="payment-information-left">
                                        <p>Payment Information</p>
                                        <div class="box-input">
                                            <p>Name on Card</p>
                                            <input type="text">
                                        </div>
                                        <div class="box-input">
                                            <p>Referral Code</p>
                                            <div class="input-card-right">
                                                <input type="text">
                                                <div class="number-country">
                                                    <img src="../assets/visa.svg" alt="">
                                                    <img src="../assets/mastercard.svg" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-cvv">
                                            <div class="box-input">
                                                <p>CVV Number</p>
                                                <input type="text">
                                            </div>
                                              <div class="box-input">
                                                <p>Expired Date</p>
                                                <input type="text">
                                            </div>

                                        </div>

                                          <!-- <div class="box-input">
                                            <p>Email</p>
                                            <input type="text">
                                        </div> -->

                                    </div>

                                </div>
                                <div class="bill-mid-right">
                                    <div class="card-your-order">
                                      <p>Your Order</p>
                                      <div class="card-list-detail">
                                          <div class="list-detail-item">
                                              <div class="detail-item-left">
                                                <p>Individual License</p>
                                                <!-- <p>Tara Ingin makan pecel lele</p> -->
                                              </div>
                                              <div class="detail-item-right">
                                                  <p>X 1</p>
                                              </div>
                                          </div>
                                      </div>

                                    </div>
                                    <div class="total-price-order">
                                        <p>Total</p>
                                        <p>$ 4999</p>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="billing-bot">
                               <div class="box-btn-next">
                                  <div class="btn-next" @click="nextStepLicense(2)">
                                    PROCEED TO PAYMENT
                                  </div>
                                  <div class="btn-cancel" @click="nextStepLicense(2)">
                                    <img src="../assets/arrowleft.svg" alt="">
                                    <p>Return to Detail Purchase</p>
                                  </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
  </div>
  <!-- MODALS PREMIUM LICENSE -->

  <div class="container-home">
      <SideHeader/>
    <div class="container-right">
      <div class="main-container-right">
            <TopHeaderLogin/>
            <div class="card-otis-kebahagiaan">
                <p>Wujudkan kebahagiaan belajar <br>
                dengan imajinasi tanpa batas
                </p>
            </div>
            <div class="card-point-level">
                <div class="poin-left">
                    <div class="coin-card">
                        <p>
                            <img src="../assets/PoinReward/coinStar.svg" alt="">
                            Points
                        </p>
                        <!-- <p class="hide">Tara Ingin Makan pecel ayam buvesti</p> -->
                        
                    </div>
                    <p class="coin">{{totalCoin}}</p>
                </div>
                <div class="poin-right">
                    <div class="coin-card">
                        <p>
                            <img src="../assets/PoinReward/medalLevel.svg" alt="">
                            Level
                        </p>
                        <!-- <p class="hide">Tara Ingin Makan pecel ayam buvesti</p> -->
                    </div>
                    <p>{{dataCustomer.level}}</p>
                </div>
            </div>
            
            <div class="milestone-task-card">
                <div class="milestone-card">
                    <p>Points Tracker</p>
                    <!-- <area-chart :data="{'January':0, 'February': 0,'March':0,'April':0,'May': 50, 'June': 15,'July':25,'August':35,'September': 40, 'October': 75,'November':10,'December':120}"
                        :colors="['#bccbdb', 'red']"
                    ></area-chart> -->
                    <column-chart  :data="dataChart"
                        :colors="[ '#004F98']"></column-chart>
                </div>
                <div class="task-card">
                    <div class="task-top">
                        <p>Tasks</p>
                    </div>
                    <div class="task-bottom">
                        <a  class="small-card-white-task" v-for="(items,id) in allTask" :key="id" :href="items.path">
                            <p>{{items.task}}</p>
                            <p>
                                {{items.poin}}
                                <img src="../assets/PoinReward/coinStar.svg" alt="">
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <div class="simple-loyalty-card">
                <p class="loyalty-header">Loyalti Program</p>
                <div class="box-card-simple">
                    <div class="small-loyalty-card-1" :class="[(bronzeClick === true ? 'long-loyalty-card-1': '')]"> 
                        <div class="small-card-simple" v-if="beforeOpenBronze === true && bronzeClaim !== true" @click="openLoyalty('Bronze')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        100
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Bronze</p>
                                <p>Voucher disc 10%</p>
                            </div>
                        </div>
                        <div class="small-card-simple-open-1" v-if="isOpenBronze === true && bronzeClaim !== true" @click="openLoyalty('Bronze')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        100
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Bronze</p>
                            </div>
                        </div>
                        <div class="long-card-simple" v-if="isLongBronze === true">
                            <div class="lcd-left">
                                <p>Bronze</p>
                                <p>Voucher Disc 10%</p>
                                <div class="coin-card-lcd">
                                    <img src="../assets/PoinReward/coinStar.svg" alt="">
                                    <p>100</p>
                                </div>

                            </div>
                            <div class="lcd-right">
                                <p>Are you sure you want to redeem?</p>
                                <div class="box-btn-lcd">
                                    <div class="btn-answer" @click="closeLoyalty('Bronze','No')">
                                        No
                                    </div>
                                    <div class="btn-answer" @click="closeLoyalty('Bronze','Yes')">
                                        Yes
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="long-card-successful" v-if="isBronzeSuccess === true">
                            <img src="../assets/successful.png" alt="">

                            <div class="successful-card">
                                <p>Congratulations!</p>
                                <p>Your redeem is succeed</p>
                                <div class="btn-okay-success" @click="closeCongrats('Bronze')">
                                    OK
                                </div>
                            </div>
                        </div>
                        <div class="claim-loyalty-1" v-if="bronzeClaim === true">
                            <div class="claim-card">
                                <img src="../assets/PoinReward/claimBronze.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="small-loyalty-card-1 " :class="(silverClick === true ? 'long-loyalty-card-2': '')"> 
                        <div class="small-card-simple-2" v-if="beforeOpenSilver === true && silverClaim !== true" @click="openLoyalty('Silver')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        200
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Silver</p>
                                <p>Voucher disc 20%</p>
                            </div>
                        </div>
                        <div class="small-card-simple-open-2" v-if="isOpenSilver === true && silverClaim !== true" @click="openLoyalty('Silver')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        200
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Silver</p>
                            </div>
                        </div>
                        <div class="long-card-simple"  v-if="isLongSilver === true">
                            <div class="lcd-left">
                                <p>Silver</p>
                                <p>Voucher Disc 20%</p>
                                <div class="coin-card-lcd">
                                    <img src="../assets/PoinReward/coinStar.svg" alt="">
                                    <p>200</p>
                                </div>

                            </div>
                            <div class="lcd-right">
                                <p>Are you sure you want to redeem?</p>

                                <div class="box-btn-lcd">
                                     <div class="btn-answer" @click="closeLoyalty('Silver','No')">
                                        No
                                    </div>
                                    <div class="btn-answer" @click="closeLoyalty('Silver','Yes')">
                                        Yes
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="long-card-successful" v-if="isSilverSuccess === true">
                            <img src="../assets/successful.png" alt="">

                            <div class="successful-card">
                                <p>Congratulations!</p>
                                <p>Your redeem is succeed</p>
                                <div class="btn-okay-success" @click="closeCongrats('Silver')">
                                    OK
                                </div>
                            </div>
                        </div>
                        <div class="claim-loyalty-2" v-if="silverClaim === true">
                            <div class="claim-card">
                                <img src="../assets/PoinReward/claimSilver.png" alt="">

                            </div>
                        </div>
                    </div>
                    <div class="small-loyalty-card-1 " :class="goldClick === true ? 'long-loyalty-card-3': '' "> 
                        <div class="small-card-simple-3" v-if="beforeOpenGold === true &&  goldClaim !== true" @click="openLoyalty('Gold')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        1000
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Gold</p>
                                <p>Voucher disc 30%</p>
                            </div>
                        
                        </div>
                        <div class="small-card-simple-open-3" v-if="isOpenGold === true && goldClaim !== true" @click="openLoyalty('Gold')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        1000
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Gold</p>
                            </div>
                        </div>
                        <div class="long-card-simple"  v-if="isLongGold=== true">
                            <div class="lcd-left">
                                <p>Gold</p>
                                <p>Voucher Disc 20%</p>
                                <div class="coin-card-lcd">
                                    <img src="../assets/PoinReward/coinStar.svg" alt="">
                                    <p>1000</p>
                                </div>
                            </div>
                            <div class="lcd-right">
                                <p>Are you sure you want to redeem?</p>

                                <div class="box-btn-lcd">
                                     <div class="btn-answer" @click="closeLoyalty('Gold','No')">
                                        No
                                    </div>
                                    <div class="btn-answer" @click="closeLoyalty('Gold','Yes')">
                                        Yes
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="long-card-successful" v-if="isGoldSuccess === true">
                            <img src="../assets/successful.png" alt="">

                            <div class="successful-card">
                                <p>Congratulations!</p>
                                <p>Your redeem is succeed</p>
                                <div class="btn-okay-success" @click="closeCongrats('Gold')">
                                    OK
                                </div>
                            </div>
                        </div>
                        <div class="claim-loyalty-3" v-if="goldClaim === true">
                            <div class="claim-card">
                                <img src="../assets/PoinReward/claimGold.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="small-loyalty-card-1" :class="platinumClick === true ? 'long-loyalty-card-4': '' " > 
                        <div class="small-card-simple-4" v-if="beforeOpenPlatinum === true && platinumClaim !== true" @click="openLoyalty('Platinum')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        2000
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Platinum</p>
                                <p>Voucher disc 50%</p>
                            </div>
                        </div>
                        <div class="small-card-simple-open-4" v-if="isOpenPlatinum === true && platinumClaim !== true"  @click="openLoyalty('Platinum')">
                            <div class="coin-price">
                                <div class="card-coin-price">
                                    <p>
                                        <img src="../assets/PoinReward/coinStar.svg" alt="">
                                        2000
                                    </p>
                                </div>
                            </div>
                            <div class="detail-price">
                                <p>Platinum</p>
                            </div>
                        </div>
                        <div class="long-card-simple"  v-if="isLongPlatinum === true">
                            <div class="lcd-left">
                                <p>Platinum</p>
                                <p>Voucher Disc 20%</p>
                                <div class="coin-card-lcd">
                                    <img src="../assets/PoinReward/coinStar.svg" alt="">
                                    <p>2000</p>
                                </div>

                            </div>
                            <div class="lcd-right">
                                <p>Are you sure you want to redeem?</p>

                                <div class="box-btn-lcd">
                                     <div class="btn-answer" @click="closeLoyalty('Platinum','No')">
                                        No
                                    </div>
                                    <div class="btn-answer" @click="closeLoyalty('Platinum','Yes')">
                                        Yes
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="long-card-successful" v-if="isPlatinumSuccess === true">
                            <img src="../assets/successful.png" alt="">

                            <div class="successful-card">
                                <p>Congratulations!</p>
                                <p>Your redeem is succeed</p>
                                <div class="btn-okay-success" @click="closeCongrats('Platinum')">
                                    OK
                                </div>
                            </div>
                        </div>
                        <div class="claim-loyalty-4" v-if="platinumClaim === true">
                            <div class="claim-card">
                                <img src="../assets/PoinReward/claimPlatinum.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </div>
  </div>
</template>

<script>

import SideHeader from '@/components/Header/SideHeader.vue'
import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'
import {ref,reactive,onMounted} from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import {Countdown} from 'vue3-flip-countdown'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
import {useStore} from 'vuex'
import Axios from 'axios'

export default {
  name: 'PoinReward',
  components: {
    SideHeader,
    TopHeaderLogin,
    Carousel,
    Navigation,
    Slide,
    Countdown,
    // VStepper
  },
  setup(){
    const store = useStore()
    let dataCustomer = ref([])
    let stepLicense = ref(1)

    let totalCoin = ref(0)

    // state awal ketika page baru dibuka semua bisa diklik
    let beforeOpenBronze = ref(true)
    let beforeOpenSilver = ref(true)
    let beforeOpenGold = ref(true)
    let beforeOpenPlatinum = ref(true)


    // state button check apakah ada card yg lagi open / ga
    let bronzeClick = ref(false)
    let silverClick = ref(false)
    let goldClick = ref(false)
    let platinumClick = ref(false)


// kalau setelah di click, berarti jadi panjang cardnya
    let isOpenBronze = ref(false)
    let isOpenSilver=ref(false)
    let isOpenGold = ref(false)
    let isOpenPlatinum = ref(false)

// kalau setelah di click, berarti jadi panjang cardnya

    let isLongBronze = ref(false)
    let isLongSilver = ref(false)
    let isLongGold = ref(false)
    let isLongPlatinum = ref(false)

    let isBronzeSuccess=ref(false)
    let isSilverSuccess=ref(false)
    let isGoldSuccess=ref(false)
    let isPlatinumSuccess=ref(false)

    // kalo udh true berarti udh di claim, gabisa di klik
    let bronzeClaim = ref(false)
    let silverClaim = ref(false)
    let goldClaim = ref(false)
    let platinumClaim = ref(false)
    let dataChart = ref({'January':0, 'February': 0,'March':0,'April':0,'May': 0, 'June': 0,'July':0,'August':0,'September': 0, 'October': 0,'November':0,'December':0})
    let allTask = reactive ([
        {
            task :'Every Purchase 1 month license',
            poin : 5,
            path : '/'
        },
        {
            task :'Review Millealab on Website',
            poin : 10,
            path : '/'
        },
        {
            task :'Referral Code',
            poin : 15,
            path : '/referral'
        },
    ])
    
    let allPointChart = ref([])

    const openLoyalty=(status)=>{
        console.log(status)
        if(status === 'Bronze'){
            bronzeClick.value = true
            silverClick.value = false
            goldClick.value = false
            platinumClick.value = false

            isOpenBronze.value = false
            isOpenSilver.value = true
            isOpenGold.value = true
            isOpenPlatinum.value = true

            isLongBronze.value = true
            isLongSilver.value = false
            isLongGold.value = false
            isLongPlatinum.value = false

            beforeOpenSilver.value = false
            beforeOpenGold.value = false
            beforeOpenPlatinum.value = false
            // beforeOpenSilver.value = false

            // isOpenBronze.value = true
        }else if (status === 'Silver'){

            // if(bronzeClaim.value !== true){
            //     console.log('if')

            //  }else {
                //  console.log('else 849')
                bronzeClick.value = false
                silverClick.value = true
                goldClick.value = false
                platinumClick.value = false
    
                isOpenBronze.value = true
                isOpenSilver.value = false
                isOpenGold.value = true
                isOpenPlatinum.value = true
    
                isLongSilver.value = true
                isLongBronze.value = false
                isLongGold.value = false
                isLongPlatinum.value = false

                beforeOpenBronze.value = false
                beforeOpenGold.value = false
                beforeOpenPlatinum.value = false

             
            


            // isBronzeSuccess.value = false
        }else if(status === 'Gold'){
             bronzeClick.value = false
            silverClick.value = false
            goldClick.value = true
            platinumClick.value = false

            isOpenBronze.value = true
            isOpenSilver.value = true
            isOpenGold.value = false
            isOpenPlatinum.value = true

            isLongGold.value = true
            isLongSilver.value = false
            isLongPlatinum.value = false
            isLongBronze.value = false

            beforeOpenSilver.value = false
            beforeOpenPlatinum.value = false
            beforeOpenBronze.value = false


        }else if ( status === 'Platinum'){
             bronzeClick.value = false
            silverClick.value = false
            goldClick.value = false
            platinumClick.value = true

            isOpenBronze.value = true
            isOpenSilver.value = true
            isOpenGold.value = true
            isOpenPlatinum.value = false

            isLongPlatinum.value = true
            isLongSilver.value = false
            isLongBronze.value = false
            isLongGold.value = false

            beforeOpenBronze.value = false
            beforeOpenGold.value = false
            beforeOpenSilver.value = false
            
        }

           if(isBronzeSuccess.value === true ){
                closeCongrats('Bronze')
            }else if(isSilverSuccess.value === true){
                closeCongrats('Silver')
            }else if(isGoldSuccess.value === true){
                closeCongrats('Gold')
            }else if (isPlatinumSuccess.value === true){
                closeCongrats('Platinum')
            }

    }

    const closeLoyalty=(params,status)=>{
        // console.log(status,params)
        // console.log(status) // status ini dipake untuk check dia berhasil beli / engga
        if(params === 'Bronze'){
            if(status === 'Yes'){
                if(totalCoin.value === 100 || totalCoin.value > 100){
                    claimLoyalty('reward-1')
                    totalCoin.value = totalCoin.value - 100
                    // console.log(totalCoin.value)
                        // silverClick.value = false
                        // goldClick.value = false
                        // platinumClick.value = false
            
                        // isLongBronze.value = false
                        // isBronzeSuccess.value = true
                    bronzeClick.value = false
                    silverClick.value = false
                    goldClick.value = false
                    platinumClick.value = false
                    
                    isOpenBronze.value = false
                    isOpenSilver.value = false
                    isOpenGold.value = false
                    isOpenPlatinum.value = false
    
                    isLongBronze.value = false
    
                    beforeOpenBronze.value = true
                    beforeOpenSilver.value = true
                    beforeOpenGold.value = true
                    beforeOpenPlatinum.value = true
                      createToast({
                            title: 'Error',
                            description: 'Your Bronze Package has been succesfully redeemed'
                            },
                            {
                            // timeout: 3000,
                            type: 'success',
                            transition: 'bounce',
                            position:'top-center'
                        })
                        
                }else {
                bronzeClick.value = false
                silverClick.value = false
                goldClick.value = false
                platinumClick.value = false
                
                isOpenBronze.value = false
                isOpenSilver.value = false
                isOpenGold.value = false
                isOpenPlatinum.value = false
    
                isLongBronze.value = false
    
                beforeOpenBronze.value = true
                beforeOpenSilver.value = true
                beforeOpenGold.value = true
                beforeOpenPlatinum.value = true
    
                createToast({
                        title: 'Error',
                        description: 'Coin Kurang'
                        },
                        {
                        // timeout: 3000,
                        type: 'danger',
                        transition: 'bounce',
                        position:'top-center'
                    })
                }
            }else{ // jawaban selain yes
                bronzeClick.value = false
                silverClick.value = false
                goldClick.value = false
                platinumClick.value = false
                
                isOpenBronze.value = false
                isOpenSilver.value = false
                isOpenGold.value = false
                isOpenPlatinum.value = false
    
                isLongBronze.value = false
    
                beforeOpenBronze.value = true
                beforeOpenSilver.value = true
                beforeOpenGold.value = true
                beforeOpenPlatinum.value = true
            }
        }else if (params === 'Silver'){
            if(status === 'Yes'){
                if(totalCoin.value === 200 || totalCoin.value > 200){
                        claimLoyalty('reward-2')
                    totalCoin.value = totalCoin.value - 200
                    // 
                    bronzeClick.value = false
                    silverClick.value = false
                    goldClick.value = false
                    platinumClick.value = false
                    
                    isOpenBronze.value = false
                    isOpenSilver.value = false
                    isOpenGold.value = false
                    isOpenPlatinum.value = false
    
                    isLongSilver.value = false
    
                    beforeOpenBronze.value = true
                    beforeOpenSilver.value = true
                    beforeOpenGold.value = true
                    beforeOpenPlatinum.value = true
                        createToast({
                        title: 'Error',
                        description: 'Your Silver Package has been succesfully redeemed'
                        },
                        {
                        // timeout: 3000,
                        type: 'success',
                        transition: 'bounce',
                        position:'top-center'
                    })
                }else {
                    // console.log('No')
                    bronzeClick.value = false
                    silverClick.value = false
                    goldClick.value = false
                    platinumClick.value = false
                    
                    isOpenBronze.value = false
                    isOpenSilver.value = false
                    isOpenGold.value = false
                    isOpenPlatinum.value = false
    
                    isLongSilver.value = false
    
                    beforeOpenBronze.value = true
                    beforeOpenSilver.value = true
                    beforeOpenGold.value = true
                    beforeOpenPlatinum.value = true
                        createToast({
                        title: 'Error',
                        description: 'Coin Kurang'
                        },
                        {
                        // timeout: 3000,
                        type: 'danger',
                        transition: 'bounce',
                        position:'top-center'
                    })
                }
            }else {
                 bronzeClick.value = false
                    silverClick.value = false
                    goldClick.value = false
                    platinumClick.value = false
                    
                    isOpenBronze.value = false
                    isOpenSilver.value = false
                    isOpenGold.value = false
                    isOpenPlatinum.value = false
    
                    isLongSilver.value = false
    
                    beforeOpenBronze.value = true
                    beforeOpenSilver.value = true
                    beforeOpenGold.value = true
                    beforeOpenPlatinum.value = true
            }
        }else if (params === 'Gold'){
            if(status === 'Yes'){
                if(totalCoin.value === 1000 || totalCoin.value > 1000){
                    
                    claimLoyalty('reward-3')
                    totalCoin.value = totalCoin.value - 1000
                    // bronzeClick.value = false
                    // silverClick.value = false
                    // platinumClick.value = false
    
                    // isLongGold.value = false
                    // isGoldSuccess.value = true
                    bronzeClick.value = false
                    silverClick.value = false
                    goldClick.value = false
                    platinumClick.value = false
                    
                    isOpenBronze.value = false
                    isOpenSilver.value = false
                    isOpenGold.value = false
                    isOpenPlatinum.value = false
                    
                    isLongGold.value = false
        
                    beforeOpenBronze.value = true
                    beforeOpenSilver.value = true
                    beforeOpenGold.value = true
                    beforeOpenPlatinum.value = true
                    createToast({
                        title: 'Success',
                        description: 'Your Gold Package has been succesfully redeemed'
                        },
                        {
                        // timeout: 3000,
                        type: 'success',
                        transition: 'bounce',
                        position:'top-center'
                    })
                }else {
                
                bronzeClick.value = false
                silverClick.value = false
                goldClick.value = false
                platinumClick.value = false
                
                isOpenBronze.value = false
                isOpenSilver.value = false
                isOpenGold.value = false
                isOpenPlatinum.value = false
                
                isLongGold.value = false
    
                beforeOpenBronze.value = true
                beforeOpenSilver.value = true
                beforeOpenGold.value = true
                beforeOpenPlatinum.value = true
    
                createToast({
                        title: 'Error',
                        description: 'Coin Kurang'
                        },
                        {
                        // timeout: 3000,
                        type: 'danger',
                        transition: 'bounce',
                        position:'top-center'
                    })
                }
            }else {
                bronzeClick.value = false
                silverClick.value = false
                goldClick.value = false
                platinumClick.value = false
                
                isOpenBronze.value = false
                isOpenSilver.value = false
                isOpenGold.value = false
                isOpenPlatinum.value = false
                
                isLongGold.value = false
    
                beforeOpenBronze.value = true
                beforeOpenSilver.value = true
                beforeOpenGold.value = true
                beforeOpenPlatinum.value = true
            }
        }else if (params === 'Platinum'){
            if(status === 'Yes'){
                if(totalCoin.value === 2000 || totalCoin.value > 2000){
                     claimLoyalty('reward-4')
                    totalCoin.value = totalCoin.value - 2000
                    // bronzeClick.value = false
                    // silverClick.value = false
                    // goldClick.value = false
    
                    // isLongPlatinum.value = false
                    // isPlatinumSuccess.value = true  
    
                    // kembali ke default
                       bronzeClick.value = false
                        silverClick.value = false
                        goldClick.value = false
                        platinumClick.value = false
                        
                        isOpenBronze.value = false
                        isOpenSilver.value = false
                        isOpenGold.value = false
                        isOpenPlatinum.value = false
    
    
                        isLongPlatinum.value = false
    
    
                        beforeOpenBronze.value = true
                        beforeOpenSilver.value = true
                        beforeOpenGold.value = true
                        beforeOpenPlatinum.value = true
                         createToast({
                            title: 'Error',
                            description: 'Your Platinum  Package has been succesfully redeemed'
                            },
                            {
                            // timeout: 3000,
                            type: 'success',
                            transition: 'bounce',
                            position:'top-center'
                        })
                    
                }else {
                    bronzeClick.value = false
                    silverClick.value = false
                    goldClick.value = false
                    platinumClick.value = false
                    
                    isOpenBronze.value = false
                    isOpenSilver.value = false
                    isOpenGold.value = false
                    isOpenPlatinum.value = false
    
    
                    isLongPlatinum.value = false
    
    
                    beforeOpenBronze.value = true
                    beforeOpenSilver.value = true
                    beforeOpenGold.value = true
                    beforeOpenPlatinum.value = true
        
                    createToast({
                            title: 'Error',
                            description: 'Coin Kurang'
                            },
                            {
                            // timeout: 3000,
                            type: 'danger',
                            transition: 'bounce',
                            position:'top-center'
                        })
                }
            }else {
                   bronzeClick.value = false
                    silverClick.value = false
                    goldClick.value = false
                    platinumClick.value = false
                    
                    isOpenBronze.value = false
                    isOpenSilver.value = false
                    isOpenGold.value = false
                    isOpenPlatinum.value = false
    
    
                    isLongPlatinum.value = false
    
    
                    beforeOpenBronze.value = true
                    beforeOpenSilver.value = true
                    beforeOpenGold.value = true
                    beforeOpenPlatinum.value = true
            }
           
        }
    }

    const closeCongrats=(params)=>{
        if(params === 'Bronze'){
            isLongBronze.value = false
            isBronzeSuccess.value = false
            bronzeClick.value = false
            isOpenBronze.value = false
            bronzeClaim.value = true


            beforeOpenBronze.value = false
            beforeOpenSilver.value = true
            beforeOpenGold.value = true
            beforeOpenPlatinum.value = true

            isOpenSilver.value = false
            isOpenGold.value = false
            isOpenPlatinum.value = false
        }else if (params === 'Silver'){
             
            isLongSilver.value = false
            isSilverSuccess.value = false
            silverClick.value = false
            silverClaim.value = true


            beforeOpenBronze.value = true
            beforeOpenSilver.value = true
            beforeOpenGold.value = true
            beforeOpenPlatinum.value = true

            isOpenBronze.value = false
            isOpenSilver.value = false
            isOpenGold.value = false
            isOpenPlatinum.value = false
            
        }else if (params === 'Gold'){
            
            
                isLongGold.value = false
                isGoldSuccess.value = false
                goldClick.value = false
                goldClaim.value = true


                beforeOpenBronze.value = true
                beforeOpenSilver.value = true
                beforeOpenGold.value = true
                beforeOpenPlatinum.value = true

                isOpenBronze.value = false
                isOpenSilver.value = false
                isOpenGold.value = false
                isOpenPlatinum.value = false

        }else if (params === 'Platinum'){
             
                isLongPlatinum.value = false
                isPlatinumSuccess.value = false
                platinumClick.value = false
                platinumClaim.value = true


                beforeOpenBronze.value = true
                beforeOpenSilver.value = true
                beforeOpenGold.value = true
                beforeOpenPlatinum.value = true

                isOpenBronze.value = false
                isOpenSilver.value = false
                isOpenGold.value = false
                isOpenPlatinum.value = false
            
        }
    }

    const claimLoyalty=(level)=>{
        // console.log(level)

        Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/claimLoyaltyReward`,{
            "uid":dataCustomer.value.id,
            "reward_type":level
        // eslint-disable-next-line no-unused-vars
        }).then((res)=>{
            // console.log(res.data)
            store.dispatch('getValueLogin')
             if(totalCoin.value < 100){
                dataCustomer.value.level = '-'
            }else if (totalCoin.value >= 100 && totalCoin.value < 200){
                dataCustomer.value.level = 'Bronze'
            }else if ( totalCoin.value >= 200 && totalCoin.value < 999){
                dataCustomer.value.level = 'Silver'
            }else if ( totalCoin.value >=1000 && totalCoin.value < 1999){
                dataCustomer.value.level = 'Gold'
            }else if(totalCoin.value >= 2000){
                dataCustomer.value.level = 'Platinum'
            }


            // fetchingLogin()
        }).catch((err)=>{
            console.log(err)
        })
        
    }
    const redeem=()=>{
        // console.log(status)
        bronzeClick.value=false
        isOpenBronze.value=false
        isOpenSilver.value = false
    }


    

    const fetchingPointChart=()=>{
        Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getTotalPointPerMonth`,{
            "uid":dataCustomer.value.id
        }).then((res)=>{
            const d = new Date();
            let year =  d.getFullYear();
            // let allPointChart = res.data.point_list[`${year}`]
            // let May = res.data.point_list[`${year}`][6]
            // console.log(May)
            // console.log(allPointChart)
            dataChart.value = {
            'January':res.data.point_list[`${year}`][0] !== undefined ?res.data.point_list[`${year}`][0] : 0,
            'February': res.data.point_list[`${year}`][1] !== undefined ?res.data.point_list[`${year}`][1] : 0,
            'March':res.data.point_list[`${year}`][2] !== undefined ?res.data.point_list[`${year}`][2] : 0,
            'April':res.data.point_list[`${year}`][3] !== undefined ?res.data.point_list[`${year}`][3] : 0,
            'May': res.data.point_list[`${year}`][4] !== undefined ?res.data.point_list[`${year}`][4] : 0,
            'June': res.data.point_list[`${year}`][5] !== undefined ?res.data.point_list[`${year}`][5] : 0,
            'July':res.data.point_list[`${year}`][6] !== undefined ?res.data.point_list[`${year}`][6] : 0,
            'August':res.data.point_list[`${year}`][7] !== undefined ?res.data.point_list[`${year}`][7] : 0,
            'September': res.data.point_list[`${year}`][8] !== undefined ?res.data.point_list[`${year}`][8] : 0, 
            'October': res.data.point_list[`${year}`][9] !== undefined ?res.data.point_list[`${year}`][9] : 0,
            'November':res.data.point_list[`${year}`][10] !== undefined ?res.data.point_list[`${year}`][10] : 0,
            'December':res.data.point_list[`${year}`][11] !== undefined ?res.data.point_list[`${year}`][11] : 0,
            }
          
          
            
        }).catch((err)=>{
            console.log(err)
        })
    }
    // FETCHING DATA 

    const fetchingLogin=async()=>{
        let dataCust = await store.getters.getCustomerDetail
        dataCustomer.value = dataCust
        totalCoin.value = dataCust.points
        // console.log(totalCoin)
        fetchingPointChart()
        
    }

    onMounted(()=>{
        fetchingLogin()
    })

    return {
        claimLoyalty,
        stepLicense,
        bronzeClick,
        isOpenBronze,
        openLoyalty,
        redeem,
        isOpenSilver,
        silverClick,
        platinumClick,
        goldClick,
        isOpenGold,
        isOpenPlatinum,
        closeLoyalty,
        isBronzeSuccess,
        isSilverSuccess,
        isGoldSuccess,
        isPlatinumSuccess,
        isLongBronze,
        isLongSilver,
        isLongGold,
        isLongPlatinum,
        beforeOpenBronze,
        beforeOpenSilver,
        beforeOpenGold,
        beforeOpenPlatinum,
        bronzeClaim,
        silverClaim,
        goldClaim,
        platinumClaim,
        closeCongrats,
        totalCoin,
        allTask,
        fetchingLogin,
        dataCustomer,
        allPointChart,
        dataChart,
        fetchingPointChart

    }
  }
}
</script>



<style lang="scss">
    @import '../Styles/PoinReward.scss';
    @import '../Styles/Mobile/PoinReward.scss';
</style>
