<template>
    <!-- side bar open start -->
    <div class="container-sideHeader-resize" v-if="isOpen === true">
        <div class="box-icon-sh-1">
            <img src="../../assets/Header/millealogoside.svg" alt="">
            <img src="../../assets/Header/sidenavside.svg" alt="" class="icon-nav" @click="onSideBar()">
        </div>
        
        <!-- HOME -->
        <a href="/" class="box-icon-sh-2">
            <div class="box-separate-icon">
                <img src="../../assets/Header/newhome.svg" alt="" class="icon-sidebar-resize">
                <p>Homepage</p>
            </div>
            <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow">
        </a>
        <!-- home -->
        <!-- poin reward -->
        <a href="/poin-reward" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <div class="box-separate-icon">
                <img src="../../assets/Header/newreward.svg" alt="" class="icon-sidebar-resize">
                <p>Point & Rewards</p>
            </div>
            <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow">
        </a>
        <!-- poin reward -->
        <!-- history -->
        <a href="/history" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <div class="box-separate-icon">
                <img src="../../assets/Header/newhistory.svg" alt="" class="icon-sidebar-resize">
                <p>History</p>
            </div>
            <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow">
        </a>
        <!-- history -->
        <!-- referral page -->
        <a href="/referral" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <div class="box-separate-icon">
                <img src="../../assets/Header/newreferral.svg" alt="" class="icon-sidebar-resize">
                <p>Referral Code</p>
            </div>
            <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow">
        </a >
        <!-- referral page -->
        <img src="../../assets/Header/Separator_panjang.svg" alt="" class="icon-separator"> 
        <!-- HELP -->
        <a  href="/help" class="box-icon-sh-2">
            <div class="box-separate-icon">
                <img src="../../assets/Header/help.svg" alt="" class="icon-sidebar-resize">
                <p>Help</p>
            </div>
            <!-- <img src="../../assets/Header/arrowRight.svg" alt="" class="icon-arrow"> -->
        </a>
        <!-- HELP -->

        <div class="box-icon-sh-2">
            <div class="box-separate-icon" v-if="isLoginGlobal === true" @click="onLogout">
                <img src="../../assets/Header/log-out.svg" alt="" class="icon-sidebar-resize">
                <p>Logout</p>
            </div>
            <a href="/login"  class="box-separate-icon" v-if="isLoginGlobal === false" @click="router.push('/login')">
                <img src="../../assets/Header/log-in-navy.svg" alt="" class="icon-sidebar-resize">
                <p>Login</p>
            </a >
        </div>

    </div>
    <!-- side bar open end -->
    <!-- side bar close start -->
    <div class="container-sideHeader"  v-else >
        <div class="box-icon-sh-1">
            <img src="../../assets/Header/SideNavTogglerLines.svg" alt="" class="icon-nav" @click="onSideBar()">
        </div>
        <!-- home -->
        <a  href="/" class="box-icon-sh-2">
            <img src="../../assets/Header/newhome.svg" alt="" class="icon-sidebar">
        </a>
        <!-- home -->
        <!-- poin reward -->
        <a  href="poin-reward" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <img src="../../assets/Header/newreward.svg " alt="" class="icon-sidebar">
        </a>
        <!-- poin reward -->
        <!-- history -->
        <a href="/history" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <img src="../../assets/Header/newhistory.svg" alt="" class="icon-sidebar">
        </a>
        <!-- history -->
        <!-- referral -->
        <a href="/referral" class="box-icon-sh-2" v-if="isLoginGlobal === true">
            <img src="../../assets/Header/newreferral.svg" alt="" class="icon-sidebar">
        </a>
        <!-- referral -->
        <img src="../../assets/Header/Separator.svg" alt="" class="icon-separator" v-if="isLoginGlobal === true"> 
        <!-- help -->
        <a href="/help" class="box-icon-sh-3">
            <img src="../../assets/Header/help.svg" alt="" class="icon-sidebar">
        </a>
        <!-- help -->
        <!-- logout -->
        <div class="box-icon-sh-3" v-if="isLoginGlobal === true" @click="onLogout" >
            <img src="../../assets/Header/log-out.svg" alt="" class="icon-sidebar">
        </div>
        <!-- logout -->
        <!-- login -->
        <a href="/login" class="box-icon-sh-3" v-if="isLoginGlobal === false">
            <img src="../../assets/Header/log-in-navy.svg" alt="" class="icon-sidebar">
        </a >
        <!-- login -->
    </div>
    <!-- side bar close end -->
</template>


<script>
    
    import {ref,onMounted,} from 'vue'
    import {useStore} from 'vuex'
    import {useRouter} from 'vue-router'
    import {signOut} from 'firebase/auth'
    import {auth} from '../../../firebase-config'
export default {
    name:'sideHeader',
    setup(){
        let isOpen = ref(false)
         // eslint-disable-next-line no-unused-vars
         const router = useRouter()

        const store = useStore()
        let isLoginGlobal = ref(false)

        const onSideBar=()=>{
            isOpen.value = !isOpen.value
        }
        const fetchingLogin=()=>{
            
            isLoginGlobal.value = store.getters.getIsLogin
            // console.log(isLoginGlobal.value,'from top header login')
        
        }
        onMounted(()=>{
            fetchingLogin()
        })

        const onLogout=async()=>{
            // console.log(router)
            store.dispatch('getValueLogout',false)
            // eslint-disable-next-line no-unused-vars
            let result = await signOut(auth)
            signOut(auth).then(()=>{
                
                // console.log(result)
                setTimeout(()=>{
                    window.location.href = '/';
    
                },2000)
            })
            // router.push('/home')


        }
        return {
            onSideBar,
            isOpen,
            isLoginGlobal,
            onLogout
        }
    }
}

</script>

<style lang="scss">
    @import '../Styles/SideHeader.scss';
</style>