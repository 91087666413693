<template>
    <div class="container-home-help">
        <SideHeader/>
        <div class="container-right">
            <div class="main-container-right-help">
                <TopHeaderLogin/>

                <div class="main-help-card">

                    <div class="card-help">
                        <div class="help-left">
                            <p>Contact Information</p>
                            <p>Jl. Kemang Barat No.11 ABC, Bangka, Kec. Mampang Prpt., Kota Jakarta Selatan, DKI Jakarta 12730</p>
                            <div class="box-map">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.0391089123527!2d106.81656611463205!3d-6.258578863016815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fc7137e24749%3A0x834f7ce0af218500!2sShinta%20VR!5e0!3m2!1sen!2sid!4v1649844893644!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                        <div class="help-right">
                            <p>Do you have any question?</p>
                            <p>Contact us</p>

                            <div class="card-box-input-help">
                                <div class="box-input-help">
                                    <div class="input-card-100">
                                        <p>Full Name</p>
                                        <div class="box-input" :class="[(isFirstNameCorrect === true ? 'correct-input' : firstName === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="firstName" @change="onFirstName" autofocus>
                                            <span v-if="isFirstNameCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isFirstNameCorrect === false && firstName !== ''">Minimum 3 Character</p>
                                        </Transition> 
                                    </div>
                                    <!-- <div class="input-card-50">
                                        <p>Last Name</p>
                                        <div class="box-input" :class="[(true === true ? 'correct-input' : lastName === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-50" v-model="lastName" @change="onLastName" autofocus>
                                            <span v-if="true === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="true === false && lastName !== ''">Minimum 3 Character</p>
                                        </Transition> 
                                    </div> -->
                                </div>
                                <div class="box-input-help">
                                    <div class="input-card-100">
                                        <p>Email</p>
                                        <div class="box-input" :class="[(isEmailCorrect === true ? 'correct-input' : email === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-100" v-model="email" @change="onEmail" autofocus>
                                            <span v-if="isEmailCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isEmailCorrect === false && email !== ''">Email salah</p>
                                        </Transition>
                                    </div>
                                </div>
                                <div class="box-input-help">
                                    <div class="input-card-50">
                                        <p>Phone Number</p>
                                        <div class="box-input" :class="[(isPhoneNumberCorrect === true ? 'correct-input' : phoneNumber === '' ? '' :  'wrong-input' )]">
                                            <div class="phone">
                                                <select name="" id="" disabled="disabled">
                                                <option value="+62">+62</option>
                                                </select>
                                                <input type="text" v-model="phoneNumber" class="input-50" onkeyup="this.value=this.value.replace(/[^\d]/,'')" @change="onPhoneNumber" autofocus>
                                            </div>
                                            <span v-if="isPhoneNumberCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isPhoneNumberCorrect === false && phoneNumber !== ''" >Minimum 10 Character</p>
                                        </Transition>
                                    </div>
                                    <div class="input-card-50">
                                        <p>Institution</p>
                                        <div class="box-input" :class="[(isInstitutionCorrect === true ? 'correct-input' : institution === '' ? '' :  'wrong-input' )]">
                                            <input type="text" class="input-50" v-model="institution" @change="onInstitution" autofocus>
                                            <span v-if="isInstitutionCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isInstitutionCorrect === false && institution !== ''">Field must be filled</p>
                                        </Transition> 
                                    </div>
                                </div>
                                <div class="box-input-help">
                                    <div class="input-card-100">
                                        <p>Message</p>
                                        <div class="box-input" :class="[(isMessageCorrect === true ? 'correct-input' : messageHelp === '' ? '' :  'wrong-input' )]">
                                            <textarea type="text" class="input-ta" v-model="messageHelp" @change="onMessageHelp" autofocus></textarea>
                                            <span v-if="isMessageCorrect === true">
                                                <i class="fa-solid fa-circle-check"></i>
                                            </span>
                                        </div>
                                        <Transition>
                                            <p class="error-notification" v-if="isMessageCorrect === false && messageHelp !== ''">Field must be filled</p>
                                        </Transition>
                                    </div>
                                </div>
                                <div class="box-input-help-btn">
                                    <div class="btn-send" @click="sendMessageHelp">
                                        Send
                                    </div>
                                    <!-- <a :href="`https://wa.me/6287785192296/?text=kelas dipindah sebelum kiamat`" @click="()=>window.open(google(event))">Kirim</a> -->
                                    <!-- <a href="http://wa"></a> -->
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideHeader from '@/components/Header/SideHeader.vue'
import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'
import {ref} from 'vue'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'
// import emailjs from '@emailjs/browser';
import Axios from 'axios'

export default {
  name: 'RefferalView',
  components: {
    SideHeader,
    TopHeaderLogin
  },
  setup(){
      let firstName = ref('')
    //   let lastName = ref('')
      let email = ref('')
      let phoneNumber = ref('')
      let institution = ref('')
      let messageHelp = ref('')

      let isEmailCorrect = ref(false)
      let isFirstNameCorrect = ref(false)
      let isPhoneNumberCorrect = ref(false)
      let isInstitutionCorrect = ref(false)
      let isMessageCorrect = ref(false)
    //   let finalresult = ref('kelas dirubah jadi sehari sebelum kiamat')


     function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    const onFirstName=()=>{
      console.log(firstName.value)
      if(firstName.value.length  > 2){
        isFirstNameCorrect.value = true
      }else {
        isFirstNameCorrect.value = false
        //  createToast({
        //     title: 'Error',
        //     description: 'Nama Minimal 3 Character'
        //     },
        //     {
        //     // timeout: 3000,
        //     type: 'danger',
        //     transition: 'bounce',
        //     position:'top-center'
        // })
      }
    }

    // const onLastName=()=>{
    //     console.log(lastName.value)
    // }
     const onEmail=()=>{
      console.log(email.value)
      var checking_email = validateEmail(email.value)
      if(checking_email){
        console.log('correct email')
        isEmailCorrect.value = true
      }else {
        isEmailCorrect.value = false
        // createToast({
        //     title: 'Error',
        //     description: 'ada kesalahan pada Format Email'
        //     },
        //     {
        //     // timeout: 3000,
        //     type: 'danger',
        //     transition: 'bounce',
        //     position:'top-center'
        // })
      }
    }

    // const onPhoneNumber=()=>{
    //   console.log(phoneNumber.value)
    //   let phoneNumbereInString= phoneNumber.value.toString()
    //   if(phoneNumbereInString.length >9){
    //     console.log('number correct')
    //     isPhoneNumberCorrect.value = true

    //   }else {
    //     isPhoneNumberCorrect.value = false
    //   }
    // }
      const onPhoneNumber=()=>{
    //   console.log(phoneNumber.value)
      let phoneNumbereInString= phoneNumber.value.toString()
      if(phoneNumbereInString.length >9 && phoneNumbereInString.length < 15){
        // console.log('number correct')
        isPhoneNumberCorrect.value = true
        let split_number = phoneNumbereInString.split('')
        let number_62 = split_number[0] + split_number[1] + split_number[2]
        let number_plus62 = split_number[0] + split_number[1] + split_number[2]
        let number_628 = split_number[0] + split_number[1] + split_number[2]
        let number_0 = split_number[0]
        if(number_plus62 === '+62'){
          split_number.splice(0,3)
          phoneNumber.value = split_number.join('')
        }else if(number_62 ==='62'){
          split_number.splice(0,2)
          phoneNumber.value = split_number.join('')
        }else if (number_628 ==='628'){
          split_number.splioce(0,2)
          phoneNumber.value = split_number.join('')
        }else if (split_number[0] === ''){
          split_number.splice(0,1)
          phoneNumber.value = split_number.join('')
        }else if(number_0 === '0'){
        split_number.splice(0,1)
          phoneNumber.value = split_number.join('')
        }else {
        //   console.log('masuk ke else')
        }
      }else {
        isPhoneNumberCorrect.value = false
      
        createToast({
            title: 'Error',
            description: 'phone Number minimal 10 digit'
            },
            {
            // timeout: 3000,
            type: 'danger',
            transition: 'bounce',
            position:'top-center'
        })
      }
    }

    const onInstitution=()=>{
        console.log(institution.value)
        if(institution.value.length >0){
            // console.log('number correct')
            isInstitutionCorrect.value = true

        }else {
            isInstitutionCorrect.value = false
        }
    }
    const onMessageHelp=()=>{
        // console.log(messageHelp.value)
        if(messageHelp.value.length >0){
            isMessageCorrect.value = true

        }else {
            isMessageCorrect.value = false
        }
    }

    const sendMessageHelp=()=>{
        // console.log(firstName.value, email.value, phoneNumber.value,institution.value,messageHelp.value)
        if(isEmailCorrect.value && isFirstNameCorrect.value && isPhoneNumberCorrect){
            // alert('data sudah benar')
            let dataToEmail ={
                firstName,
                phoneNumber,
                institution,
                messageHelp,
                email
            }
            Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/sendEmail`,{
                    "first_name":`${firstName.value}`,
                    "email":`${email.value}`,
                    "phone":`${phoneNumber.value}`,
                    "institution":`${institution.value}`,
                    "message":`${messageHelp.value}`
            })
            // <a href={`https://wa.me/6287785192296/?text=${finalResult}`} onClick={()=>window.open(google(event))}>YUK JALAN!</a>


            // emailjs.send("service_5874q8r","individualLicense",{
            //   fullName:`${firstName.value}`,
            //   phone:phoneNumber.value,
            //   company:institution.value,
            //   email:email.value,
            //   message:messageHelp.value
            //   },'user_59hDAVW2zXb7KYDWbzc0L')
            //   .then((result)=>{
            //       console.log(result.text)
            //   }).catch((err)=>{
            //       console.log(err)
            //   })
            
            firstName.value = ''
            phoneNumber.value = ''
            institution.value = ''
            messageHelp.value = ''
            email.value = ''
            isFirstNameCorrect.value = false
            isEmailCorrect.value = false
            isPhoneNumberCorrect.value = false
            isInstitutionCorrect.value = false
            isMessageCorrect.value = false
            console.log(dataToEmail)
              createToast({
            title: 'Success',
            description: 'Terimakasih! Email Telah Diterima'
            },
            {
            // timeout: 3000,
            type: 'success',
            transition: 'bounce',
            position:'top-center'
          })
        }else {
            createToast({
            title: 'Error',
            description: 'Ada pengisian yang salah'
            },
            {
            // timeout: 3000,
            type: 'danger',
            transition: 'bounce',
            position:'top-center'
          })
        }
    }



      return{
          firstName,
          email,
          phoneNumber,
          institution,
          messageHelp,
          onFirstName,

          onEmail,
          onPhoneNumber,
          onInstitution,
          onMessageHelp,
          sendMessageHelp,
          validateEmail,
          isEmailCorrect,
          isPhoneNumberCorrect,
          isFirstNameCorrect,
          isInstitutionCorrect,
          isMessageCorrect,
        //   finalresult
      }
  }
}
</script>

<style lang="scss">
    @import '../Styles/Help.scss';
    @import '../Styles/Mobile/Help.scss';
</style>