<template>
    <div class="container-register">
      <div class="box-picture">
        <!-- <img src="../assets/RegistLogin/Register.svg" alt=""> -->
      </div>
       <div class="box-register">
            <div class="card-register">
                <div class="register-logo">
                  <a href="/"><img src="../assets/RegistLogin/logo.svg" alt=""></a>
                  
                </div>
                <div class="register-header">
                  <h1>Register</h1>
                </div>
                <div class="register-detail-box">
                  <div class="row">
                    <div class="new-column-100">
                      <p>Full Name</p>
                      <div class="box-input-register" :class="[(isFullNameCorrect === true ? 'correct-input' : registFullName === '' ? '' :  'wrong-input' )]">
                          <!-- <input :type="typePassword" v-model="registPassword" @change="onRegistPassword" > -->
                          <input type="text" v-on:keyup.enter="onCreateAcc" class="input-100"  v-model="registFullName" @change="onRegistFirstName">
                          <span v-if="isFullNameCorrect === true">
                            <i class="fa-solid fa-circle-check"></i>
                          </span>
                      </div>
                        <Transition>
                          <p class="error-notification" v-if="isFullNameCorrect === false && registFullName !== ''">Please input name (Min. 3 Character)</p>
                        </Transition>
                    </div>  
                  </div>
                  <div class="row">
                    <div class="new-column">
                      <p>Email</p>
                      <div class="box-input-register" :class="[(isEmailCorrect === true ? 'correct-input' : registEmail === '' ? '' :  'wrong-input' )]">
                          <!-- <input :type="typePassword" v-model="registPassword" @change="onRegistPassword" > -->
                          <input type="email" v-model="registEmail"  @change="onRegistEmail">
                          <span v-if="isEmailCorrect === true">
                            <i class="fa-solid fa-circle-check"></i>
                          </span>
                      </div>
                         <Transition>
                          <p class="error-notification" v-if="isEmailCorrect === false && registEmail !== ''">Contoh:email@millealab.com</p>
                        </Transition>
                    </div>  
                    <div class="column">
                      <p>Phone Number</p>
                      <div class="phone"  :class="[(isPhoneCorrect === true ? 'correct-input' : registPhone === '' ? '' :  'wrong-input' )] ">
                        <select name="" id="" disabled="disabled">
                          <option value="+62">+62</option>
                        </select>
                        <input type="text" v-model="registPhone"  onkeyup="this.value=this.value.replace(/[^\d]/,'')" @change="onRegistPhone">
                         <span v-if="isPhoneCorrect === true">
                            <i class="fa-solid fa-circle-check"></i>
                          </span>
                      </div>
                      <Transition>
                        <p class="error-notification" v-if="isPhoneCorrect === false && registPhone !== ''">Contoh:+6212345678910</p>
                      </Transition>
                    </div>
                  </div>
                  <div class="row">
                    <div class="new-column">
                      <p>Password</p>
                      <div class="box-input-register" :class="[(isPasswordCorrect === true ? 'correct-input' : registPassword === '' ? '' :  'wrong-input' )]">
                          <input :type="typePassword" v-model="registPassword" @change="onRegistPassword" >
                          <span v-if="isSeenPassword === true" v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye icon-eyes"></i>
                          </span>
                          <span v-else v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye-slash icon-eyes"></i>
                          </span>
                      </div>
                        <Transition>
                        <p class="error-notification" v-if="isPasswordCorrect === false && registPassword !== ''">8 character + 1 Capital + 1 Special Character</p>
                      </Transition>
                    </div>   
                    <div class="new-column">
                        <p>Confirm Password</p>
                        <div class="box-input-register" :class="[(isCheckPasswordCorrect === true ? 'correct-input' : registCheckPassword === '' ? '' :  'wrong-input' )]">
                            <input :type="reTypePassword" v-model="registCheckPassword" @change="onRegistPasswordCheck" >
                            
                            <span v-if="isSeenRePassword === true" v-on:click="onChangeReSeen">
                              <i class="fa-solid fa-eye icon-eyes"></i>
                            </span>
                            <span v-else v-on:click="onChangeReSeen">
                              <i class="fa-solid fa-eye-slash icon-eyes"></i>
                            </span>
                        </div>
                          <Transition>
                          <p class="error-notification" v-if="isCheckPasswordCorrect === false && registCheckPassword !== ''">Password Berbeda</p>
                        </Transition>
                    </div>
                  </div>
                </div>
                <div class="register-checklist-box">
                  <div class="checklist">
                    <input class="checkbox" type="checkbox" v-model="checkedPrivacy" @change="onCheckedPrivacy()">
                    <!-- <span class="checkmark"></span> -->
                    <p>I read and agree to  <a href="https://www.millealab.com/tnc" target="_blank">Terms & Condition</a> </p>
                  </div>
                  <div class="checklist">
                    <input class="checkbox" type="checkbox">
                    <p>Yes, I want to receive email promotion from MilleaLab</p>
                  </div>
                </div>
                <div class="btn-register-box" >
                    <div class="btn-register" v-if="allDataIsReady" @click="onCreateAcc()">
                      <p>REGISTER</p>
                    </div>
                    <div class="btn-failed"  v-else >
                      <p>REGISTER</p>
                    </div>
                </div>
                <div class="errorMessage">
                  <!-- <span v-if="isRegister === true">
                    <p></p>
                  </span>
                  <span v-if="isRegister === false">
                    <p>{{errMessage}}</p>
                  </span> -->
                  <!-- <p v-if="!isFirstNameCorrect">Nama salah</p>
                  <p v-if="!isEmailCorrect">Email salah</p>
                  <p v-if="!isPhoneCorrect">Phone salah</p>
                  <p v-if="!isPasswordCorrect">Password salah</p>
                  <p v-if="!isCheckPasswordCorrect">Check password tidak sama</p> -->
                </div>
                <div class="box-register-with">
                  <div class="register-with">
                    <p>Or register with</p>
                  </div>
                </div>
                <!-- <div class="register-to-login"><p>Already have an account? <a href="/login">Login</a></p></div> -->
                <div class="box-other-register">
                  <div class="other-register-box" @click="loginWithGoogle()">
                    <div class="btn-other-register">
                        <img src="../assets/RegistLogin/google.svg" alt="">
                        <p>Continue with google</p>
                    </div>
                    <!-- <div class="btn-other-login"><div class="mid"><img src="../assets/RegistLogin/meta.svg" alt=""></div></div>
                    <div class="btn-other-login"><img src="../assets/RegistLogin/apple.svg" alt=""></div> -->
                  </div>
                <div class="register-to-login"><p>Already have an account? <a href="/login">Login</a></p></div>
              </div>
            </div>
      </div> 
    </div>
</template>

<script>
import {ref} from 'vue'
import { createToast } from 'mosha-vue-toastify';
import { signInWithPopup,signInWithEmailAndPassword,getAuth } from 'firebase/auth';
import {auth,provider} from '../../firebase-config'
import {useStore} from 'vuex'
import {doc,setDoc,Timestamp,getDoc} from 'firebase/firestore'
import {db} from '../../firebase-config'
import Axios from 'axios'
import {useLoading} from 'vue-loading-overlay'
// import googleAuth from '../Services/googleAuth'
export default {
  name: 'RegisterView',
  components: {
  },
  setup(){
    const $loading = useLoading()
    const store = useStore()

    let allDataIsReady = ref(false) 
    let typePassword = ref('password')
    let reTypePassword = ref('password')
    let registFullName = ref('')
    let registFirstName = ref('')
    let registLastName = ref('')
    let registEmail = ref('')
    let registPassword = ref('')
    let registCheckPassword = ref('')
    let registPhone = ref('')
    let registPoB = ref('')
    let registDoB = ref('2012-02-02')
    let registGender = ref('')
    let registCompany = ref('')
    let registJobTitle = ref('')
    let registAddress = ref('')
    let registZipCode = ref('')
    let checkedPrivacy = ref(false)
    let isRegister = ref(true)
    let errMessage = ref('salahErrMessage')

    let isFirstNameCorrect = ref(false)
    let isFullNameCorrect = ref(false)
    let isLastNameCorrect = ref(false)
    let isEmailCorrect = ref(false)
    let isPasswordCorrect = ref(false)
    let isCheckPasswordCorrect = ref(false)
    let isPhoneCorrect = ref(false)
    let isPoBCorrect = ref(false)
    let isDoBCorrect = ref(false)
    let isGenderCorrect = ref(false)
    let isCompanyCorrect = ref(false)
    let isJobTitleCorrect = ref(false)
    let isAddressCorrect = ref(false)
    let isZipCodeCorrect = ref(false)
    let isSeenPassword =ref(false)
    let isSeenRePassword =ref(false)

    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    function validatePassword(password) {
        const re = /^(?=.{7,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/
        return re.test(String(password))
    }
    // const onChangeSeen=(params)=>{
    //     console.log(params)
    //     alert('jalan')
    //     if(params === 'text'){
    //       isSeenPassword.value = true
    //       typePassword.value = 'password'
    //     }else if (params === 'password'){
    //       isSeenPassword.value = false
    //       typePassword.value = 'text'
    //     }
    // }
    const onChangeSeen =()=>{
        isSeenPassword.value = !isSeenPassword.value
        
        if (typePassword.value === 'text'){
          isSeenPassword.value = true
          typePassword.value = 'password'
        }else {
          isSeenPassword.value = false
          typePassword.value = 'text' 
        }        
    }
     const onChangeReSeen =()=>{
        isSeenRePassword.value = !isSeenRePassword.value
        
        if (reTypePassword.value === 'text'){
          isSeenRePassword.value = true
          reTypePassword.value = 'password'
        }else {
          isSeenRePassword.value = false
          reTypePassword.value = 'text' 
        }        
    }


    const checkAllData=()=>{

        if(isFullNameCorrect.value && isEmailCorrect.value && isPasswordCorrect.value && isCheckPasswordCorrect.value && checkedPrivacy.value && isPhoneCorrect.value){
          allDataIsReady.value = true
        }else {
          allDataIsReady.value = false
        }
        // console.log(isFullNameCorrect.value,isEmailCorrect.value,isPasswordCorrect.value,isCheckPasswordCorrect.value,checkedPrivacy.value, isPhoneCorrect.value)
    }
    const onRegistFirstName=()=>{
      if(registFullName.value.length >= 3){
        isFullNameCorrect.value = true
        checkAllData()
      }else {
        isFullNameCorrect.value = false
        // alert("First Name minimal 3 character")
        // createToast({
        //   title: 'Error',
        //   description: 'First Name minimal 3 character'
        //   },
        //   {
        //   // timeout: 3000,
        //   type: 'danger',
        //   transition: 'bounce',
        //   position:'top-center'
        // })
      }
    }
    const onRegistLastName=()=>{
      if(registLastName.value.length >= 3){
        isLastNameCorrect.value = true
      }else {
        isLastNameCorrect.value = false
        // alert("Last Name minimal 3 character")
        // createToast({
        //   title: 'Error',
        //   description: 'Last Name minimal 3 character'
        //   },
        //   {
        //   // timeout: 3000,
        //   type: 'danger',
        //   transition: 'bounce',
        //   position:'top-center'
        // })
      }
    }
    const onRegistEmail=()=>{
      var checking_email = validateEmail(registEmail.value)
      if(checking_email){
        isEmailCorrect.value = true
        checkAllData()
      }else {
        isEmailCorrect.value = false
        // errMessage.value = 'Email Salah'
        // alert("email salah")
        // createToast({
        //   title: 'Error',
        //   description: 'email salah'
        //   },
        //   {
        //   // timeout: 3000,
        //   type: 'danger',
        //   transition: 'bounce',
        //   position:'top-center'
        // })
      }
    }
    const onRegistPassword=()=>{
      var checking_password = validatePassword(registPassword.value)
            if(checking_password){
                // console.log(checking_password)
                isPasswordCorrect.value = true
                checkAllData()
            }else {
                // errMessage.value = 'password Salah'
                isPasswordCorrect.value = false
                // createToast({
                //     title: 'Error',
                //     description: '8 Character + Special Character + Capital'
                //     },
                //     {
                //     // timeout: 3000,
                //     type: 'danger',
                //     transition: 'bounce',
                //     position:'top-center'
                // })
        }
    }
    const onRegistPasswordCheck=()=>{
      // console.log(registCheckPassword,registPassword)
      if(registCheckPassword.value == registPassword.value){
        isCheckPasswordCorrect.value = true
        checkAllData()
      }else {
        // errMessage.value = 'Confirm password salah'
        isCheckPasswordCorrect.value = false
        // alert("Password berbeda")
        // createToast({
        //             title: 'Error',
        //             description: 'Password berbeda'
        //             },
        //             {
        //             // timeout: 3000,
        //             type: 'danger',
        //             transition: 'bounce',
        //             position:'top-center'
        //         })
      }
    }
    const onRegistPhone=()=>{
      if(registPhone.value.length > 0){
        console.log(registPhone.value)
          isPhoneCorrect.value = true
          checkAllData()
          let split_number = registPhone.value.split('')
          let number_62 = split_number[0] + split_number[1]
          let number_plus62 = split_number[0] + split_number[1] + split_number[2]
          let number_628 = split_number[0] + split_number[1] + split_number[2]
          // console.log(split_number)
          // console.log(number_62)
          if(number_plus62 === '+62'){
            split_number.splice(0,3)
            registPhone.value = split_number.join('')
            // console.log('masuk ke else if +62')
          }else if (number_62 === '62'){
            // console.log(split_number)
            // console.log('masuk ke else if 62')
            split_number.splice(0,2)
            // console.log(split_number)
            registPhone.value = split_number.join('')         
          }
          else if(number_628 === '628'){
            split_number.splice(0,2)
            registPhone.value = split_number.join('')
            // console.log('masuk ke else if 628')
          }else if (split_number[0] === '0'){
            split_number.splice(0,1)
            registPhone.value = split_number.join('')
            // console.log('masuk ke else if 0')
          }else {
            // console.log('masuk ke else')
          }

      }else {
        isPhoneCorrect.value = false
        // errMessage - 'Nama Salah'
        // alert("Field Harus diisi")
      
      }
    }
    const onRegistPoB=()=>{
      if(registPoB.value.length > 0){
        isPoBCorrect.value = true
      }else {
        isPoBCorrect.value = false
        // alert("Field wajib diisi!")
        createToast({
          title: 'Error',
          description: 'PoB wajib diisi'
          },
          {
          // timeout: 3000,
          type: 'danger',
          transition: 'bounce',
          position:'top-center'
        })
      }
    }
    const onRegistDoB=()=>{
      if(registDoB.value.length > 0){
        isDoBCorrect.value = true
        // console.log(registDoB.value)
      }else {
        isDoBCorrect.value = false
        // alert("Field wajib diisi!")
        createToast({
          title: 'Error',
          description: 'DoB wajib diisi'
          },
          {
          // timeout: 3000,
          type: 'danger',
          transition: 'bounce',
          position:'top-center'
        })
      }
    }
    const onRegistGender=()=>{
      if(registGender.value.length > 0){
        isGenderCorrect.value = true
      }else {
        isGenderCorrect.value = false
        // alert("Field wajib diisi!")
        createToast({
          title: 'Error',
          description: 'Gender wajib diisi'
          },
          {
          // timeout: 3000,
          type: 'danger',
          transition: 'bounce',
          position:'top-center'
        })
      }
    }
    const onRegistCompany=()=>{
      if(registCompany.value.length > 0){
        isCompanyCorrect.value = true
      }else {
        isCompanyCorrect.value = false
        // alert("Field wajib diisi!")
        createToast({
          title: 'Error',
          description: 'Company wajib diisi'
          },
          {
          // timeout: 3000,
          type: 'danger',
          transition: 'bounce',
          position:'top-center'
        })
      }
    }
    const onRegistJobTitle=()=>{
      if(registJobTitle.value.length > 0){
        isJobTitleCorrect.value = true
      }else {
        isJobTitleCorrect.value = false
        // alert("Field wajib diisi!")
        createToast({
          title: 'Error',
          description: 'Job Title wajib diisi'
          },
          {
          // timeout: 3000,
          type: 'danger',
          transition: 'bounce',
          position:'top-center'
        })
      }
    }
    const onRegistAddress=()=>{
      if(registAddress.value.length > 0){
        isAddressCorrect.value = true
      }else {
        isAddressCorrect.value = false
        // alert("Field wajib diisi!")
        createToast({
          title: 'Error',
          description: 'Address wajib diisi'
          },
          {
          // timeout: 3000,
          type: 'danger',
          transition: 'bounce',
          position:'top-center'
        })
      }
    }
    const onRegistZipCode=()=>{
      if(registZipCode.value.length > 2 && registZipCode.value.length < 6){
        isZipCodeCorrect.value = true
      }else {
        isZipCodeCorrect.value = false
        // alert("Zip Code Salah")
        createToast({
          title: 'Error',
          description: 'Zip Code Salah'
          },
          {
          // timeout: 3000,
          type: 'danger',
          transition: 'bounce',
          position:'top-center'
        })
      }
    }

    const onCheckedPrivacy=()=>{
      // console.log(checkedPrivacy.value)
      checkAllData()

    }

    const onCreateAcc=async()=>{


       if(isFullNameCorrect.value && isEmailCorrect.value && isPasswordCorrect.value && isCheckPasswordCorrect.value && checkedPrivacy.value && isPhoneCorrect.value){
        
        const loader = $loading.show({
              // Optional parameters
        });
        // let number_profile = Math.floor(Math.random() * 5)

        // alert("Register Succeed")
        var data = JSON.stringify({
          "name":registFullName.value,
          "email": registEmail.value,
          "phone": `+62${registPhone.value}`,
          "password": registPassword.value,
          "tnc": true,
        });
        console.log(data)

        var config = {
          method: 'post',
          url: 'https://us-central1-millea-lab.cloudfunctions.net/web/api/createNewUser',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };

        Axios(config)
        .then(async function (response) {
          // console.log(response)
          // console.log(response.data)
         
            // alert('register berhasil')
            if(response.status === 200){

              const auth = getAuth()
              await signInWithEmailAndPassword(auth,registEmail.value,registPassword.value)
              .then(async(result)=>{
                // console.log(result)
                let collectionID = 'users';
                let docRef = doc(db, collectionID, result.user.uid);
                
                setDoc(docRef,{profile_number:2},{ merge: true })
                // console.log(docRef)
                createToast({
                   title: 'Success',
                   description: 'Register Berhasil'
                   },
                   {
                   timeout: 2000,
                   type: 'success',
                   transition: 'bounce',
                   position:'top-center'
               })
               // console.log(response.data.message)
               // console.log(response)
                // let message = response.data.message
                // let uid = message.split(' ')[3]
                // console.log(uid)
                store.dispatch('getValueLoginWithGoogle',result.user.uid)
                JSON.stringify(localStorage.setItem('idStorage',result.user.uid))
                loader.hide()
                window.location.href = '/';
              }).catch((err)=>{
                console.log(err)
                loader.hide()
              })
              // store.dispatch('getValueLogin')
            

            }
          
        })
        .catch(function (error) {
          console.log(error)
          console.log(error.message)
          loader.hide()
             createToast({
                  title: 'Failed',
                  description: 'Email / Phone Number already exists'
                  },
                  {
                  timeout: 2000,
                  type: 'danger',
                  transition: 'bounce',
                  position:'top-center'
              })
          
          console.log(error.message);
          console.log(error)
        });


        }else {
            allDataIsReady.value = false
            checkAllData()
        }
    }

    const loginWithGoogle=async()=>{
        // const googleUser = await  $gAuth.signIn()
        // console.log('google user', googleUser)

       
        const loader = $loading.show({
            // Optional parameters
        });
        try
        {
            // sign in google with pop up
            let result = await signInWithPopup(auth, provider);
            // console.log(result,' sign in with popup')
            let user = result.user;
            // console.log(user.uid)
            // console.log(user)

            // define user data
            let collectionID = 'users';
            let docRef = doc(db, collectionID, user.uid);
            // console.log(docRef)

            // get user data
            let userDocSnap = await getDoc(docRef);

            // if user does not exists in firestore, create default new user
            if(!userDocSnap.exists())
            {
                await setDoc(docRef, {
                    id: user.uid,
                    name: user.displayName,
                    email: user.email,
                    phone: user.phoneNumber,
                    created_at: Timestamp.fromDate(new Date(user.metadata.creationTime)),
                    last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
                    has_creator: false,
                    has_viewer: false,
                    is_admin: false,
                    is_template_creator: 'No',
                    registered_institution_list: [],
                    registered_to_classroom_uid_list: [],
                    registered_users: [],
                    status: 'student',
                    level:'Bronze',
                    point: 0,
                    referral_code: null,
                    referral_history: [],
                    point_history: [],
                    profilePicture:user.photoURL,
                    userDetails: []
                }, { merge: true });
                loader.hide()
                
            }
            // else, update the last signed in data
            else
            {
                await setDoc(docRef, {
                    last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime))
                }, { merge: true });
                loader.hide()
            }
            
            store.dispatch('getValueLoginWithGoogle',user.uid)
            JSON.stringify(localStorage.setItem('idStorage',user.uid))
            window.location.href = '/';
            loader.hide()
        }
        catch(err)
        {
            console.log(err);
            console.log(err.message)
            loader.hide()
        }         
    }
    const registerWithGoogle=async()=>{
      const loader = $loading.show({
                  // Optional parameters
        });

      try{
          let result = await signInWithPopup(auth,provider)
          let user  = result.user
          let collectionID = 'users'
          let docRef = doc(db,collectionID,user.uid)
          let userDocSnap = await getDoc(docRef);
          // console.log(userDocSnap)
          if(!userDocSnap.exists()){
            await setDoc(docRef, {
                id: user.uid,
                name: user.displayName,
                email: user.email,
                phone: user.phoneNumber,
                created_at: Timestamp.fromDate(new Date(user.metadata.creationTime)),
                last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
                has_creator: false,
                has_viewer: false,
                is_admin: false,
                is_template_creator: 'No',
                registered_institution_list: [],
                registered_to_classroom_uid_list: [],
                registered_users: [],
                status: 'student',
                level:'Bronze',
                point: 0,
                referral_code: null,
                referral_history: [],
                point_history: [],
                profilePicture:user.photoURL,
                userDetails: []
            }, { merge: true });
            loader.hide()
          }else {
            await setDoc(docRef, {
                  last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime))
              }, { merge: true });
              loader.hide()
          }


          // let sendToFirebase = {
          //   id: user.uid,
          //   name: user.displayName,
          //   email: user.email,
          //   phone: user.phoneNumber,
          //   created_at: Timestamp.fromDate(new Date(user.metadata.creationTime)),
          //   last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
          //     referralCode:'Taralaper1!',
          //     points:0,
          //     totalReferral:0,
          //     address:null,
          //     zipCode:null,
          //     gender:null,
          //     company:null,
          //     jobTitle:null,
          //     placeOfBirth:null,
          //     dateOfBirth:null,
          //     individualLicense:0,
          //     vrCardboard:0,
          //     level:'Bronze'
          // }
          // console.log(sendToFirebase)
          // await setDoc(docRef,sendToFirebase)
          
          // store.dispatch('getValueLoginWithGoogle',sendToFirebase)
          store.dispatch('getValueLoginWithGoogle',user.uid)
          JSON.stringify(localStorage.setItem('idStorage',user.uid))
              // window.location.href = '/';
          loader.hide()
          
      }
      catch(err){
        console.log(err)
        loader.hide()
      }

              // store.dispatch('getValueLoginWithGoogle',returnUser)
          // }).catch((err)=>{
          //     console.log(err)
          // })
      
    }
    return{
      registFullName,
      registFirstName,
      registLastName,
      registEmail,
      registPassword,
      registCheckPassword,
      registPhone,
      registPoB,
      registDoB,
      registGender,
      registCompany,
      registJobTitle,
      registAddress,
      registZipCode,
      checkedPrivacy,
      isRegister,
      errMessage,
      isFirstNameCorrect,
      isFullNameCorrect,
      isLastNameCorrect,
      isEmailCorrect,
      isPasswordCorrect,
      isCheckPasswordCorrect,
      isPhoneCorrect,
      isPoBCorrect,
      isDoBCorrect,
      isGenderCorrect,
      isCompanyCorrect,
      isJobTitleCorrect,
      isAddressCorrect,
      isZipCodeCorrect,
      onRegistFirstName,
      onRegistLastName,
      validateEmail,
      onRegistEmail,
      validatePassword,
      onRegistPassword,
      onRegistPasswordCheck,
      onRegistPhone,
      onRegistPoB,
      onRegistDoB,
      onRegistGender,
      onRegistCompany,
      onRegistJobTitle,
      onRegistAddress,
      onRegistZipCode,
      onCreateAcc,
      registerWithGoogle,
      loginWithGoogle,
      isSeenPassword,
      onChangeSeen,
      typePassword,
      isSeenRePassword,
      onChangeReSeen,
      reTypePassword,
      allDataIsReady,
      checkAllData,
      onCheckedPrivacy
    }
  }
}
</script>

<style lang="scss">
    @import '../Styles/Register.scss';
    @import '../Styles/Mobile/Register.scss';
</style>