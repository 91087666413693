<template>
    <div class="container-new">
        <div class="box-picture">
            <!-- <img src="../assets/RegistLogin/Login.svg" alt=""> -->
        </div>
        <div class="box-new">
            <div class="new-logo">
                
                <a href="/"><img src="../assets/RegistLogin/logo.svg" alt=""></a>
            </div>
            <div class="new-header">
                <h1>Create new password</h1>
                <p>Your new password should be different <br>from previous used passwords.</p>
            </div>
            <div class="new-detail-box">
                <!-- <div class="new-detail">
                    <p class="bold">Password</p>
                    <input type="text">
                    <p>Must be at least 8 characters.</p>
                </div> -->
                <!-- <div class="new-subhead">
                    <p class="bold">Password</p>
                </div> -->
                <div class="row">
                    <div class="new-column">
                      <p>Password</p>
                      <div class="box-input" :class="[(isPasswordCorrect === true ? 'correct-input' : customerPassword === '' ? '' :  'wrong-input' )]">
                          <input :type="typePassword" v-model="customerPassword" @change="onCustPassword" 
                            >
                          
                          <span v-if="isSeen === true" v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye icon-eyes"></i>
                          </span>
                          <span v-else v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye-slash icon-eyes"></i>
                          </span>
                      </div>
                        <Transition>
                        <p class="error-notification" v-if="isPasswordCorrect === false && customerPassword !== ''">8 character + 1 Capital + 1 Special Character</p>
                      </Transition>
                    </div>   
                    <div class="new-column">
                        <p>Confirm Password</p>
                        <div class="box-input" :class="[(isCustomerCheckPasswordCorrect === true ? 'correct-input' : customerCheckPassword === '' ? '' :  'wrong-input' )]">
                            <input :type="typePassword1" v-model="customerCheckPassword" @change="onCustomerPasswordCheck" >
                            
                            <span v-if="isSeen1 === true" v-on:click="onChangeSeen1">
                              <i class="fa-solid fa-eye icon-eyes"></i>
                            </span>
                            <span v-else v-on:click="onChangeSeen1">
                              <i class="fa-solid fa-eye-slash icon-eyes"></i>
                            </span>
                        </div>
                          <Transition>
                          <p class="error-notification" v-if="isCustomerCheckPasswordCorrect === false && customerCheckPassword !== ''">Password Berbeda</p>
                        </Transition>
                    </div>
                  </div>
                <!-- <div class="new-detail">
                    <input :type="typePassword" v-model="customerPassword" @change="onCustPassword">
                    <span v-if="isSeen === true" v-on:click="onChangeSeen">
                        <img class="icon-eyes" src="../assets/RegistLogin/eye-off.svg" alt="">
                    </span>
                    <span v-else v-on:click="onChangeSeen">
                        <img class="icon-eyes" src="../assets/RegistLogin/eye-off.svg" alt="">
                    </span>
                </div> -->
                <!-- <div class="new-desc">
                    <p>Must be at least 8 characters.</p>
                </div> -->
                <!-- <div class="new-subhead">
                    <p class="bold">Confirm Password</p>
                </div>
                <div class="new-detail">
                    <input  v-on:keyup.enter="onResetPassword" :type="typePassword1" v-model="customerCheckPassword" @change="onCustomerPasswordCheck">
                    <span v-if="isSeen1 === true" v-on:click="onChangeSeen1">
                        <img class="icon-eyes" src="../assets/RegistLogin/eye-off.svg" alt="">
                    </span>
                    <span v-else v-on:click="onChangeSeen1">
                        <img class="icon-eyes" src="../assets/RegistLogin/eye-off.svg" alt="">
                    </span>

                </div> -->
                <!-- <div class="new-desc">
                    <p>Both password must match.</p>
                </div> -->
            </div>
            <!-- <div class="btn-new-box">
                <a @click="onResetPassword" class="btn-new">
                    <p>RESET PASSWORD</p>
                </a>
            </div> -->
                <div class="btn-new-box" >
                    <div class="btn-new" v-if="allDataIsReady" @click="onResetPassword()">
                      <p>RESET PASSWORD</p>
                    </div>
                    <div class="btn-failed"  v-else >
                      <p>RESET PASSWORD</p>
                    </div>
                </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'
// import { createToast } from 'mosha-vue-toastify';

export default {
  name: 'NewPasswordView',
  components: {
  },
  setup(){
    let allDataIsReady = ref(false)
    let isSeen = ref(false)
    let isSeen1 = ref(false)
    let typePassword = ref('password')
    let typePassword1 = ref('password')
    let customerPassword = ref('')
    let isPasswordCorrect = ref(false)
    let customerCheckPassword = ref('')
    let isCustomerCheckPasswordCorrect = ref(false)

    function validatePassword(password) {
        const re = /^(?=.{7,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/
        return re.test(String(password))
    }
    const onChangeSeen =()=>{
      isSeen.value = !isSeen.value

      if (typePassword.value === 'text'){
        isSeen.value = true
        typePassword.value = 'password'
      }else {
        isSeen.value = false
        typePassword.value = 'text' 
      }       
    }
    const onChangeSeen1 =()=>{
      isSeen1.value = !isSeen1.value

      if (typePassword1.value === 'text'){
        isSeen1.value = true
        typePassword1.value = 'password'
      }else {
        isSeen1.value = false
        typePassword1.value = 'text' 
      }        
    }
    const checkAllData =()=>{
        if(isPasswordCorrect.value && isCustomerCheckPasswordCorrect.value){
            allDataIsReady.value = true
        }else{
            allDataIsReady.value = false
        }
    }

    const onCustPassword=()=>{
        var checking_password = validatePassword(customerPassword.value)
            if(checking_password){
                // console.log(checking_password)
                isPasswordCorrect.value = true
                checkAllData()
            }else {
                isPasswordCorrect.value = false
                // alert("Password salah")
                // createToast({
                //     title: 'Error',
                //     description: 'Password salah'
                //     },
                //     {
                //     // timeout: 3000,
                //     type: 'danger',
                //     transition: 'bounce',
                //     position:'top-center'
                // })
        }
    }
    const onCustomerPasswordCheck=()=>{
      if(customerCheckPassword.value == customerPassword.value){
        isCustomerCheckPasswordCorrect.value = true
        checkAllData()
      }else {
        isCustomerCheckPasswordCorrect.value = false
        // alert("Password berbeda")
        // createToast({
        //             title: 'Error',
        //             description: 'Password berbeda'
        //             },
        //             {
        //             // timeout: 3000,
        //             type: 'danger',
        //             transition: 'bounce',
        //             position:'top-center'
        //         })
      }
    }

    const onResetPassword=()=>{
        if(isPasswordCorrect.value && isCustomerCheckPasswordCorrect.value){
            alert('Reset password berhasil')
            window.location.replace("http://localhost:8080/succeed");
        }
        else{
            allDataIsReady.value = false
            checkAllData()
        }
    }

    return{
        allDataIsReady,
        checkAllData,
        isSeen,
        isSeen1,
        typePassword,
        typePassword1,
        customerPassword,
        isPasswordCorrect,
        validatePassword,
        onChangeSeen,
        onChangeSeen1,
        onCustPassword,
        customerCheckPassword,
        isCustomerCheckPasswordCorrect,
        onCustomerPasswordCheck,
        onResetPassword
    }
  }
}
</script>

<style lang="scss">
    @import '../Styles/NewPassword.scss';
     @import '../Styles/Mobile/NewPassword.scss';
</style>