<template>
    <div class="card-faq" :class="{'active-faq' : status === 'General'}" @click="updateDirection('General')">
        <p>General</p>
    </div>
    <div class="card-faq" style="position:relative, top:'-10px" :class="{'active-faq' : status === 'MilleaLabCreator'}" @click="updateDirection('MilleaLabCreator')">
        <p>MilleaLab Creator</p>
    </div>
        <div class="card-faq" :class="{'active-faq' : status === 'MilleaLabViewer'}" @click="updateDirection('MilleaLabViewer')">
        <p>MilleaLab Viewer</p>
    </div>
    <div class="card-faq" :class="{'active-faq' : status === 'Pricing'}" @click="updateDirection('Pricing')">
        <p>Pricing</p>
    </div>
     <div class="card-faq" :class="{'active-faq' : status === 'PoinReward'}" @click="updateDirection('PoinReward')">
        <p>Points & Rewards</p>
    </div>
    
</template>

<script>
import {ref,onMounted} from 'vue'
// active-faq

export default {
    name:'FaqButton',
    props:{
        buttonStatus:String
    },
    setup(_props,{emit}){
        let status = ref(_props.buttonStatus)
        // console.log(status.value)

        const updateDirection=(params)=>{
            // console.log(params)
            status.value =  params
            emit('changeDirection',{status:params})
        }

        onMounted(()=>{
            status.value = _props.buttonStatus
        })
        return {
            status,updateDirection
        }
    }
}
</script>

<style lang="scss">
$card-color:linear-gradient(154.57deg, #FAF1EA 0%, #EDF2F7 100%);
$yellow:#FFCF78;
$navy:#004F98;
$bg-navy:#004F98;
$lightgray:#bcbcbc;
$faq-number : #85AACD;
$gray : #b3b3b3;
$light-gray: #f7f7f7;
 .box-header-faq{
    width:100%;
    height:68px;
    min-height:68px;
    // border:1px solid red;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .card-faq{
        width:19%;
        height:100%;
        border-radius:10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        box-shadow: 4px 4px 30px rgba(109, 141, 173, 0.15);
        border-radius: 5px;
        transition: 1s;
        p{
            color:black;
            transition:1s;
        }    
        &:hover{
            cursor: pointer;
            background-color: $navy;
            p{
                color:white;
            }
        }
    }
    .active-faq{
        background-color: $bg-navy;
        p{
            color:white;
        }
    }
}

</style>
