<template>
    <div class="container-forgot">
      <div class="box-picture">
        <!-- <img src="../assets/RegistLogin/Forgot.svg" alt=""> -->
      </div>
      <div class="box-forgot">
        <div class="forgot-logo">
          <a href="/"><img src="../assets/RegistLogin/logo.svg" alt=""></a>
          
        </div>
        <div class="forgot-header">
          <h1>Forgot Password</h1>
          <p>Please enter your registered email below to <br>received password reset instruction</p>
        </div>
        <div class="forgot-detail-box">
          <!-- <div class="forgot-detail">
            <p>Email</p>
            <input type="email" v-model="forgotEmail"  @change="onForgotEmail" autofocus>
          </div> -->
          <div class="new-column-100">
                    <p>Email</p>
                        <div class="box-input-forgot" :class="[(isForgotEmailCorrect === true ? 'correct-input' : forgotEmail === '' ? '' :  'wrong-input' )]">
                          <!-- <input :type="typePassword" v-model="registPassword" @change="onRegistPassword" > -->
                          <input type="text" v-on:keyup.enter="onSendInstruction" class="input-100"  v-model="forgotEmail" @change="onForgotEmail">
                          <span v-if="isForgotEmailCorrect === true">
                            <i class="fa-solid fa-circle-check"></i>
                          </span>
                        </div>
                        <Transition>
                          <p class="error-notification" v-if="isForgotEmailCorrect === false && forgotEmail !== ''">Contoh:email@millealab.com</p>
                        </Transition>
                </div>
        </div>
        <div class="btn-forgot-box">
          <a @click="onSendInstruction" class="btn-forgot" v-if="allDataIsReady">
            <p>SEND INSTRUCTION</p>
          </a>
          <div class="btn-failed"  v-else>
            <p>SEND INSTRUCTION</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import { createToast } from 'mosha-vue-toastify';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export default {
  name: 'ForgotPassword',
  components: {
  },
  setup(){
    let forgotEmail = ref('')
    let isForgotEmailCorrect = ref(false)
    let allDataIsReady = ref(false)

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const checkAllData=()=>{
        if(isForgotEmailCorrect.value){
          allDataIsReady.value = true
        }else {
          allDataIsReady.value = false
        }
        // console.log(isForgotEmailCorrect.value)
    }

    const onForgotEmail=()=>{
        var checking_email = validateEmail(forgotEmail.value)
            if(checking_email){
                isForgotEmailCorrect.value = true
                checkAllData()
            }else {
                isForgotEmailCorrect.value = false
                // alert("email salah")
                // createToast({
                //   title: 'Error',
                //   description: 'email salah'
                //   },
                //   {
                //   // timeout: 3000,
                //   type: 'danger',
                //   transition: 'bounce',
                //   position:'top-center'
                // })
            }
    }
    const onSendInstruction=async()=>{
        if(isForgotEmailCorrect.value){
            // alert('Reset password berhasil')
            // const auth = getAuth();
        
            const auth = getAuth();
            sendPasswordResetEmail(auth,forgotEmail.value)
        .then(() => {
            // alert('Check your registered email to reset the password!')
            createToast({
                title: 'Success',
                description: 'Check your registered email to reset the password!'
                },
                {
                timeout: 2000,
                type: 'danger',
                transition: 'bounce',
                position:'top-center'
            })
            setTimeout(()=>{
              window.location.href = '/login';
            },2000)
        }).catch((error) => {
          console.log(error.message)
          if(error.message === 'Firebase: Error (auth/user-not-found).'){
            createToast({
                  title: 'Error',
                  description: 'Wrong Email'
                  },
                  {
                  timeout: 2000,
                  type: 'danger',
                  transition: 'bounce',
                  position:'top-center'
              })

          }else {
            checkAllData()
            //  createToast({
            //       title: 'Error',
            //       description: 'Wrong Email'
            //       },
            //       {
            //       timeout: 2000,
            //       type: 'danger',
            //       transition: 'bounce',
            //       position:'top-center'
            //   })
          }
        })
        // await sendPasswordResetEmail(auth, 'darmawanbayu1@gmail.com')
        // .then((res) => {
        //   console.log(res)
        //   console.log(forgotEmail.value)
        //   // Password reset email sent!
        //   // ..
        // })
        // .catch((error) => {
        //   const errorCode = error.code;
        //   const errorMessage = error.message;
        //   console.log(errorCode,' error code')
        //   console.log(errorMessage)
        //   // ..
        // });

            // await sendPasswordResetEmail(forgotEmail.value)
            // .then((res)=>{
            //   console.log(res)
            // }).catch((err)=>{
            //   console.log(err.message)
            // })
            // window.location.replace("http://localhost:8080/checkmail");
        }else {
            onForgotEmail()
        }
    }
    return{
      forgotEmail,
      isForgotEmailCorrect,
      validateEmail,
      onForgotEmail,
      onSendInstruction,
      checkAllData,
      allDataIsReady
    }
  }
}
</script>

<style lang="scss">
    @import '../Styles/Forgot.scss';
    @import '../Styles/Mobile/Forgot.scss';
</style>