<template>
    

    <div class="container-login">
        <div class="box-picture">
            <!-- <img src="../assets/RegistLogin/Login.svg" alt=""> -->
        </div>
        <div class="box-login">
            <div class="login-logo">
                <a href="/"><img src="../assets/RegistLogin/logo.svg" alt=""></a>
                
            </div>
            <div class="login-header">
                <h1>Login</h1>
            </div>
            <div class="login-detail-box">
                <div class="new-column-100">
                    <p>Email</p>
                        <div class="box-input-login" :class="[(isEmailCorrect === true ? 'correct-input' : customerEmail === '' ? '' :  'wrong-input' )]">
                          <!-- <input :type="typePassword" v-model="registPassword" @change="onRegistPassword" > -->
                          <input type="text" v-on:keyup.enter="onLogIn" class="input-100"  v-model="customerEmail" @change="onCustEmail">
                          <span v-if="isEmailCorrect === true">
                            <i class="fa-solid fa-circle-check"></i>
                          </span>
                        </div>
                        <Transition>
                          <p class="error-notification" v-if="isEmailCorrect === false && customerEmail !== ''">Contoh:email@millealab.com</p>
                        </Transition>
                </div>
                <div class="new-column">
                    <p>Password</p>
                      <div class="box-input-login" :class="[(isPasswordCorrect === true ? 'correct-input' : customerPassword === '' ? '' :  'wrong-input' )]">
                          <input :type="typePassword" v-model="customerPassword" @change="onCustPassword">                        
                          <span v-if="isSeen === true" v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye icon-eyes"></i>
                          </span>
                          <span v-else v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye-slash icon-eyes"></i>
                          </span>
                      </div>
                    <Transition>
                        <p class="error-notification" v-if="isPasswordCorrect === false && customerPassword !== ''">8 character + 1 Capital + 1 Special Character</p>
                    </Transition>
                </div>

            <!-- <div class="new-column">
                    <p>Password</p>
                    <div class="box-input-register" :class="[(isPasswordCorrect === true ? 'correct-input' : registPassword === '' ? '' :  'wrong-input' )]">
                        <input :type="typePassword" v-model="registPassword" @change="onRegistPassword">
                        <span v-if="isSeenPassword === true" v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye icon-eyes"></i>
                        </span>
                        <span v-else v-on:click="onChangeSeen">
                            <i class="fa-solid fa-eye-slash icon-eyes"></i>
                        </span>
                    </div>
                    <Transition>
                        <p class="error-notification" v-if="isPasswordCorrect === false && registPassword !== ''">8 character + 1 Capital + 1 Special Character</p>
                    </Transition>
                    </div>         -->
                <!-- <div class="login-subhead">
                    <p>Email</p>
                </div>
                <div class="login-detail">
                    <input type="email" v-model="customerEmail"  @change="onCustEmail" autofocus>
                </div> -->
                <!-- <div class="login-subhead">
                    <p>Password</p>
                </div>
                <div class="login-detail">
                    <input :type="typePassword" v-on:keyup.enter="onLogIn" v-model="customerPassword" >
                    <span v-if="isSeen === true" v-on:click="onChangeSeen">
                        <img class="icon-eyes" src="../assets/RegistLogin/eye-off.svg" alt="">
                    </span>
                    <span v-else v-on:click="onChangeSeen">
                        <img class="icon-eyes" src="../assets/RegistLogin/eye-off.svg" alt="">
                    </span>
                </div> -->
                <div class="login-to-forgot">
                    <a href="/forgot">
                        <p>Forgot password?</p>
                    </a>
                </div>
            </div>
            <div class="btn-login-box">
                <div class="btn-login" v-if="allDataIsReady" @click="onLogIn">
                    <p>LOGIN</p>
                </div>
                <div class="btn-failed"  v-else >
                    <p>LOGIN</p>
                </div>
            </div>
            <div class="box-login-with">
                <div class="login-with">
                    <p>Or login with</p>
                </div>
            </div>
            <div class="box-other-login">
                <div class="other-login-box">
                    <div class="btn-other-login" @click="loginWithGoogle">
                        <img src="../assets/RegistLogin/google.svg" alt="">
                        <p>Sign in with google</p>
                    </div>
                    <!-- <div class="btn-other-login"><div class="mid"><img src="../assets/RegistLogin/meta.svg" alt=""></div></div>
                    <div class="btn-other-login"><img src="../assets/RegistLogin/apple.svg" alt=""></div> -->
                </div>
                <div class="login-to-register"><p>Don't have an account yet? <a href="/register">Register</a></p></div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted} from 'vue'
import { createToast } from 'mosha-vue-toastify';
import {useStore} from 'vuex'
// eslint-disable-next-line no-unused-vars
import {signInWithPopup,signInWithEmailAndPassword ,getAuth} from 'firebase/auth';
import {auth,provider} from '../../firebase-config'
import {db} from '../../firebase-config'
import AuthDataService from '../Services/auth.services'
import {doc,setDoc,getDoc,Timestamp} from 'firebase/firestore'
import {useLoading} from 'vue-loading-overlay'
import Axios from 'axios'
// import { getAuth } from "firebase/auth";

// import firebase from 'firebase/app'
// console.log(db)
export default {
  name: 'LoginView',
  components: {
  },
  setup(){
    const store = useStore()
    const $loading = useLoading()
    let isSeen = ref(false)
    let customerEmail = ref('')
    let isEmailCorrect = ref(false)
    let typePassword = ref('password')
    let customerPassword = ref('')
    let isPasswordCorrect = ref(false)
    let isLoadingFetching=ref(false)
    let allDataIsReady = ref(false)
    let dataCustomer = ref()

    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    function validatePassword(password) {
        const re = /^(?=.{7,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$/
        return re.test(String(password))
    }

    const onChangeSeen =()=>{
      isSeen.value = !isSeen.value

      if (typePassword.value === 'text'){
        isSeen.value = true
        typePassword.value = 'password'
      }else {
        isSeen.value = false
        typePassword.value = 'text' 
      }        
    }

    const checkAllData=()=>{

        if(isEmailCorrect.value && isPasswordCorrect.value){
          allDataIsReady.value = true
        }else {
          allDataIsReady.value = false
        }
        console.log(isEmailCorrect.value,isPasswordCorrect.value)
    }

    const onCustEmail=()=>{
        var checking_email = validateEmail(customerEmail.value)
            if(checking_email){
                isEmailCorrect.value = true
                checkAllData()
            }else {
                isEmailCorrect.value = false
                // alert("email salah")
                // createToast({
                //     title: 'Error',
                //     description: 'email salah'
                //     },
                //     {
                //     // timeout: 3000,
                //     type: 'danger',
                //     transition: 'bounce',
                //     position:'top-center'
                // })
            }
    }

    const onCustPassword=()=>{
        var checking_password = validatePassword(customerPassword.value)
            if(checking_password){
                console.log(checking_password)
                isPasswordCorrect.value = true
                checkAllData()
            }else {
                isPasswordCorrect.value = false
                // createToast({
                //     title: 'Error',
                //     description: 'Password salah'
                //     },
                //     {
                //     // timeout: 3000,
                //     type: 'danger',
                //     transition: 'bounce',
                //     position:'top-center'
                // })
        }
    }

    const onLogIn=async()=>{
        // console.log(customerEmail.value,customerPassword.value)
        if(isEmailCorrect.value){
            const loader = $loading.show({
                // Optional parameters
            });
                const auth = getAuth();
                 await signInWithEmailAndPassword(auth,customerEmail.value,customerPassword.value)
                .then(async(result)=>{
                    // let collectionID = 'users';
                    // let docRef = doc(db, collectionID, result.user.uid);
                    // console.log(docRef)

                    // get user data
                    // let userDocSnap = await getDoc(docRef);
                    // var number_profile = 0;

                    // if(userDocSnap.data().profile_number === undefined){
                    //     console.log('profile number undefined')
                    //     number_profile = Math.floor(Math.random() * 5)
                    // }else {
                    //     number_profile = userDocSnap.data().profile_number
                    // }

                    // update profile_number pp
                    // setDoc(docRef, {
                    // profile_number: number_profile
                    // }, { merge: true });
                    
                    store.dispatch('getValueLoginWithGoogle',result.user.uid)
                    JSON.stringify(localStorage.setItem('idStorage',result.user.uid))
                    await tracklogin()
                    setTimeout(function(){
                        window.location.href = '/';
                    }, 200);
                    loader.hide()
                }).catch((err)=>{
                    // console.log(err.message)
                    // console.log(err)
                    // console.log(err.code)
                    let split_cust_email = customerEmail.value.split('@')
                    // console.log(split_cust_email)
                    if(split_cust_email[1] === 'gmail.com'){ // makesure email is gmail
                    
                        if(err.message === 'Firebase: Error (auth/user-not-found).'){
                            // console.log('masuk ke if 173')
                             createToast({
                                title: 'Error',
                                description: 'Email unregistered, Login with google'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }else if (err.message === 'Firebase: Error (auth/wrong-password).'){
                            // console.log('masuk ke if 185')
                             createToast({
                                title: 'Error',
                                description: 'Wrong Password,Login with google'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }else if (err.message === 'EMAIL_NOT_FOUND'){
                            createToast({
                                title: 'Error',
                                description: 'Email Not Found, please Register'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }
                    }else if(split_cust_email[1] !== 'gmail.com') { // email isnt gmail
                        if(err.message === 'Firebase: Error (auth/wrong-password).'){
                            // console.log('masuk ke if 199')
                            createToast({
                                title: 'Error',
                                description: 'Wrong Password,Check your password'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }
                    }else if (err.message === 'EMAIL_NOT_FOUND'){
                            createToast({
                                title: 'Error',
                                description: 'Email Not Found, please Register'
                                },
                                {
                                // timeout: 3000,
                                type: 'danger',
                                transition: 'bounce',
                                position:'top-center'
                            })
                        }
                    else {
                        createToast({
                            title: 'Error',
                            description: 'Something\'s Wrong,Please Contact Our Team'
                            },
                            {
                            // timeout: 3000,
                            type: 'danger',
                            transition: 'bounce',
                            position:'top-center'
                        })
                    }
                    loader.hide()
                })
        }
        else {
            // alert('Password : minimal 8 char + 1 angka + 1 special + 1 Huruf Kapital, isi semua data data ')
            // createToast({
            //         title: 'Error',
            //         description: 'Password : minimal 8 char + 1 angka + 1 special + 1 Huruf Kapital, isi semua data data'
            //         },
            //         {
            //         // timeout: 3000,
            //         type: 'danger',
            //         transition: 'bounce',
            //         position:'top-center'
            //     })
            checkAllData()
            console.log(customerEmail.value, customerPassword.value)
        }
    }


    const loginWithGoogle=async()=>{
        // const googleUser = await  $gAuth.signIn()
        // console.log('google user', googleUser)

       
        const loader = $loading.show({
            // Optional parameters
        });
        try
        {
            // sign in google with pop up
            let result = await signInWithPopup(auth, provider);
            // console.log(result,' sign in with popup')
            let user = result.user;
            // console.log(user.uid)
            // console.log(user)

            // define user data
            let collectionID = 'users';
            let docRef = doc(db, collectionID, user.uid);
            // console.log(docRef)

            // get user data
            let userDocSnap = await getDoc(docRef);
            // console.log(userDocSnap)
            // console.log(userDocSnap.data())
            // if user does not exists in firestore, create default new user
            store.dispatch('getValueLoginWithGoogle',user.uid)
            JSON.stringify(localStorage.setItem('idStorage',user.uid))
            await tracklogin()

            if(!userDocSnap.exists())
            {
                await setDoc(docRef, {
                    id: user.uid,
                    name: user.displayName,
                    email: user.email,
                    phone: user.phoneNumber,
                    created_at: Timestamp.fromDate(new Date(user.metadata.creationTime)),
                    last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
                    has_creator: false,
                    has_viewer: false,
                    is_admin: false,
                    is_template_creator: 'No',
                    registered_institution_list: [],
                    registered_to_classroom_uid_list: [],
                    registered_users: [],
                    status: 'student',
                    level:'Bronze',
                    point: 0,
                    referral_code: null,
                    referral_history: [],
                    point_history: [],
                    profile_picture:user.photoURL,
                    userDetails: []
                }, { merge: true });
                loader.hide()
                
            }
            // else, update the last signed in data, user already registered. just update timestamp on firestore
            else
            {
                let userDetails = []
                // let profile_number = 0
                if(userDocSnap.data().userDetails){
                    userDetails = userDocSnap.data().userDetails
                }
                // if(userDocSnap.data().profileNumber === undefined){
                //     profile_number = 2
                // }else {
                //     profile_number = userDocSnap.data().profile_number
                // }
                await setDoc(docRef, {
                    last_signed_in: Timestamp.fromDate(new Date(user.metadata.lastSignInTime)),
                    userDetails:userDetails,
                    // profile_number : profile_number
                }, { merge: true });
                loader.hide()
            }
            
            window.location.href = '/';
            loader.hide()  
        }
        catch(err)
        {
            console.log(err);
            console.log(err.message)
            loader.hide()
        }      
    }
    const tracklogin=async()=>{
        console.log('masuk trackLogin')
        let idStorage  = localStorage.getItem('idStorage')
        console.log(idStorage,'id storage 452')
        await Axios.post(`https://us-central1-millea-lab.cloudfunctions.net/web/api/getUserData`,{
            uid:idStorage
        }).then ((res)=>{
            console.log(res.data[0])
            dataCustomer.value = res.data[0]
            console.log(dataCustomer.value.name)
            var data = JSON.stringify({
                "name":dataCustomer.value.name,
                "email": dataCustomer.value.email,
                "user_uid":idStorage,
            });
            console.log(data)
            var config = {
            method: 'post',
            url: 'https://us-central1-millea-lab.cloudfunctions.net/web/api/trackIndividualLicenseWebsiteLogin',
                headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };

            Axios(config)
            console.log(config)
            return config
        })
            // let dataCust = await store.getters.getCustomerDetail
            // console.log(dataCust.name)
            // dataCustomer.value = dataCust
    }
    const fetchingLogin=async()=>{
        // let dataCust = await store.getters.getCustomerDetail
        // console.log(dataCust.name)
        // dataCustomer.value = dataCust
        // console.log(dataCustomer.value)
    }
    onMounted(()=>{
          fetchingLogin()
      })
    return{
        isSeen,
        onChangeSeen,
        customerEmail,
        validateEmail,
        onCustEmail,
        isEmailCorrect,
        isPasswordCorrect,
        typePassword,
        customerPassword,
        validatePassword,
        onCustPassword,
        onLogIn,
        loginWithGoogle,
        signInWithPopup,
        AuthDataService,
        isLoadingFetching,
        checkAllData,
        allDataIsReady,
        dataCustomer,
        tracklogin
      }
  }
}
</script>

<style lang="scss">
    @import '../Styles/Login.scss';
    @import '../Styles/Mobile/Login.scss';
</style>