<template>
    <div class="container-succeed">
        <div class="box-picture">
            <!-- <img src="../assets/RegistLogin/Succeed.svg" alt=""> -->
        </div>
        <div class="box-succeed">
            <div class="succeed-logo">
                <img src="../assets/RegistLogin/logo.svg" alt="">
            </div>
            <div class="succeed-picture">
                <img src="../assets/RegistLogin/check.svg" alt="">
            </div>
            <div class="succeed-header">
                <h1>Succeed!</h1>
                <p>You have successfully confirm your new password.</p>
                <p>Please use your new password when loggin in.</p>
            </div>
            <a class="btn-succeed-to-login" href="/login">
                <div class="btn-succeed">
                    <p>LOGIN</p>
                </div>
            </a>
        </div>
        
    </div>
</template>

<script>

export default {
  name: 'SucceedView',
  components: {
  }
}
</script>

<style lang="scss">
    @import '../Styles/Succeed.scss';
    @import '../Styles/Mobile/Succeed.scss';
</style>