<template>
    <div class="container-home-success">
        <SideHeader/>
        <div class="container-right-success">
            <div class="main-container-right-success">
                <!-- <TopHeaderLogin/> -->
                <div class="payment-success-page">
                    <img src="../assets/successful.png" alt="">
                    <p>Order Confirmed</p>
                    <p>Your Order is confirmed. You will Receive an order confirmation Email shortly with the expected delivery date for your items</p>
                    <div class="box-btn-success">
                        <a href="/" class="btn-back-home">
                            Home
                        </a>
                        <a href="/history" class="btn-history">
                            View Order
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideHeader from '@/components/Header/SideHeader.vue'
import {ref,onMounted} from 'vue'
import {useRouter} from 'vue-router'
import Axios from 'axios'
// import TopHeaderLogin from '@/components/Header/TopHeaderLogin.vue'

export default {
    name:'SuccessPaymentView',
    components:{
        SideHeader,
        // TopHeaderLogin
    },
    setup(){
        const route = useRouter()
        let params = ref('')

        const fetchingData=async    ()=>{
            let findParams = route.currentRoute.value.params.invoice
            params.value = findParams
            console.log(params.value, 'ini params page')
            var data = JSON.stringify({
            "invoice_id": findParams
            });
            var config = {
            method: 'post',
            url: 'https://us-central1-millea-lab.cloudfunctions.net/web/api/getInvoice',
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
            };
        //    Axios(config)
        // .then(function (response) {
        //     console.log(response)
        // console.log(JSON.stringify(response.data));
        // })
        // .catch(function (error) {
        // console.log(error);
        // });

         Axios(config).then((res)=>{
            console.log(res.data)
            if(res.data.status === 'PAID' || res.data.status === 'SETTLED'){
                // console.log('payment success')
            }else {
                console.log(res.data.status)
                // console.log('payment gagal')
                route.push('/')
            }
            
        }).catch((err)=>{
            console.log(err)
            if(err){
                // router.push('/')
            
                // console.log('payment gagal')
                route.push('/')
            }
        })

        }
        onMounted(()=>{
            fetchingData()
        })

        return {
           params
        }
    }
}
</script>


<style lang="scss">
    @import '../Styles/success.scss';
    @import '../Styles/Mobile/Success.scss';
</style>
